import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useFormik } from 'formik'
import { MenuItem, Classes, NumericInput } from "@blueprintjs/core";
import { Suggest, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import articles from '../../../redux/articles'
import { Vendor, PermissionRole } from '../../../types'
import { highlightTextForQuery } from '../../../helper/highlight-text'
import VendorSuggest from '../../components/molecules/VendorSuggest'
import FileDropzone from '../../components/molecules/FileDropzone'
import { hasRoleOrMore, requiredRoleForFields } from '../../../lib/auth/permission'

interface Props {
  formik: any,
  vendors: Vendor[],
  updateExisting?: boolean,
  disableSubmit?: boolean,
  role: PermissionRole,
}

interface States {
}

class Step1 extends PureComponent<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      formik, vendors, updateExisting, disableSubmit, role
    } = this.props;

    const {
    } = this.state;

    return (
      <>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Article Code</label>
          <div className="col-sm-8">
            <input
              type="text"
              className={Classes.INPUT}
              name="articleCode"
              onChange={formik.handleChange}
              value={formik.values.articleCode}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Packaging</label>
          <div className="col-sm-8">
            <input
              type="text"
              className={Classes.INPUT}
              name="packaging"
              onChange={formik.handleChange}
              value={formik.values.packaging}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Vendor</label>
          <div className="col-sm-8">
            <VendorSuggest
              items={vendors}
              onItemSelect={(v: Vendor) => formik.setFieldValue('vendor', v)}
              selectedItem={formik.values.vendor}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Vendor Code</label>
          <div className="col-sm-8">
            <input
              type="text"
              className={Classes.INPUT}
              name="vendorCode"
              onChange={formik.handleChange}
              value={formik.values.vendorCode}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Barcode number</label>
          <div className="col-sm-8">
            <input
              type="text"
              className={Classes.INPUT}
              name="barcodeNumber"
              onChange={formik.handleChange}
              value={formik.values.barcodeNumber}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Own Formula</label>
          <div className="col-sm-8">
            <input
              type="checkbox"
              name="ownFormula"
              onChange={formik.handleChange}
              value="on"
              checked={formik.values.ownFormula && formik.values.ownFormula[0] === "on"}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Label</label>
          <div className="col-sm-8">
            <FileDropzone
              onFileUploaded={(id, name) => {
                formik.setFieldValue('labelFileName', name);
                formik.setFieldValue('labelFileId', id);
              }}
              currentFile={formik.values.labelFileName}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Image (PNG or JPEG)</label>
          <div className="col-sm-8">
            <FileDropzone
              accept="image/png, image/jpeg"
              onFileUploaded={(id, name) => {
                formik.setFieldValue('imageFileName', name);
                formik.setFieldValue('imageFileId', id);
              }}
              currentFile={formik.values.imageFileName}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Article Remark</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              name="articleRemark"
              onChange={formik.handleChange}
              value={formik.values.articleRemark}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Trial Product</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              name="articleTrialProduct"
              onChange={formik.handleChange}
              value={formik.values.articleTrialProduct}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Internal only</label>
          <div className="col-sm-8">
            <input
              type="checkbox"
              name="internalOnly"
              onChange={formik.handleChange}
              value="on"
              checked={formik.values.internalOnly && formik.values.internalOnly[0] === "on"}
            />
          </div>
        </div>
        {hasRoleOrMore(role, requiredRoleForFields['article.cost']) && (
          <>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Cost</label>
              <div className="col-sm-8">
                <NumericInput
                    className="bp3-fill"
                    allowNumericCharactersOnly={true}
                    max={100}
                    min={0}
                    value={formik.values.articleCost || ''}
                    onValueChange={(v: any) => {
                      formik.setFieldValue('articleCost', v);
                      const date = new Date();
                      const [month, day, year] = [
                        date.getMonth(),
                        date.getDate(),
                        date.getFullYear(),
                      ];
                      formik.setFieldValue('articleCostUpdatedDate', `${day}.${month}.${year}`); // automatically update cost changed date
                    }}
                  />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Cost Unit</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  name="articleCostUnit"
                  onChange={formik.handleChange}
                  value={formik.values.articleCostUnit}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Cost Updated Date</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  name="articleCostUpdatedDate"
                  onChange={formik.handleChange}
                  value={formik.values.articleCostUpdatedDate}
                />
              </div>
            </div>
          </>
        )}
        <button 
          type="submit" 
          className="btn btn-primary"
          disabled={disableSubmit}>
          {updateExisting ? 'Update article' : 'Next step'}
        </button>
      </>
    )
  }
}

const mapStateToProps = ({ articles, user }: any) => ({
  vendors: articles.vendors,
  role: user.activeRole,
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch)

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step1)

export default withRouter(pageRedux as any)
