import React, { PureComponent } from 'react'
import { indexOf } from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import { AdinoProduct, ApplicationCategory, ProductGroup } from '../../../types'
import AdinoProductSelect from '../../components/molecules/AdinoProductSelect'
import ApplicationCategorySelect from '../../components/molecules/ApplicationCategorySelect'
import ProductGroupSelect from '../../components/molecules/ProductGroupSelect'


interface Props {
  formik: any
  onBack: () => {}
  adinoproducts: AdinoProduct[]
  productgroups: ProductGroup[]
  allApplicationcategories: ApplicationCategory[]
  creatingArticle: boolean
  updateExisting?: boolean,
  disableSubmit?: boolean,
}

interface States {
}

class Step2 extends PureComponent<Props, States> {
  constructor(props: Props) {
    super(props)
    this.state = {
    }
  }

  toggleCreateNewProduct() {
    const createNewProduct = this.props.formik.values.createNewProduct;

    if (!createNewProduct) {
      // clear selection
      this.props.formik.setFieldValue('adinoProduct', undefined);
    }

    this.props.formik.setFieldValue('createNewProduct', !createNewProduct);
  }

  toggleApplicationCategory(toggleP: ApplicationCategory) {
    let newSelection = this.props.formik.values.applicationCategories as ApplicationCategory[] || [];

    if (indexOf(newSelection, toggleP) > -1) {
      // remove it
      newSelection = newSelection.filter(p => p.id !== toggleP.id)
    } else {
      // add it
      newSelection = [...newSelection, toggleP]
    }
    this.props.formik.setFieldValue('applicationCategories', newSelection);
  }

  render() {
    const {
      formik,
      onBack,
      adinoproducts,
      productgroups,
      allApplicationcategories,
      creatingArticle,
      updateExisting,
      disableSubmit,
    } = this.props

    const { 
      createNewProduct, 
      adinoProduct,
      productName,
      productDescription,
      productRemark,
      productGroup,
      productTxtApplications,
      productTxtCharacteristics,
      applicationCategories,
      ownFormula,
    } = formik.values;

    return (
      <>
        {!updateExisting && (
          <div className="mb-4" style={{ textAlign: 'center' }}>
            <button
              type="button"
              className={
                'btn btn-sm mr-2 ' +
                (createNewProduct ? 'btn-secondary' : 'btn-outline-secondary')
              }
              onClick={() => this.toggleCreateNewProduct()}>
              New Product
            </button>
            or select existing product:
            <div className="dropdown ml-2" style={{ display: 'inline-block' }}>
              <AdinoProductSelect
                items={adinoproducts}
                selectedItem={adinoProduct}
                onItemSelect={p => {
                  formik.setFieldValue('adinoProduct', p);
                  formik.setFieldValue('createNewProduct', false);
                }}
              />
            </div>
          </div>
        )}
        {createNewProduct && (
          <>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Name</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  name="productName"
                  onChange={formik.handleChange}
                  value={productName}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Description</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  name="productDescription"
                  onChange={formik.handleChange}
                  value={productDescription}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Product Remark</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  name="productRemark"
                  onChange={formik.handleChange}
                  value={productRemark}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Product Group</label>
              <div className="col-sm-8">
                <ProductGroupSelect
                  items={productgroups}
                  selectedItem={productGroup}
                  onItemSelect={p => formik.setFieldValue('productGroup', p)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Application Categories
              </label>
              <div className="col-sm-8">
                <ApplicationCategorySelect
                  items={allApplicationcategories}
                  selectedItems={applicationCategories}
                  onItemSelect={p => this.toggleApplicationCategory(p)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Applications (one per line)</label>
              <div className="col-sm-8">
                <textarea
                  rows={5}
                  className="form-control"
                  name="productTxtApplications"
                  onChange={formik.handleChange}
                  value={productTxtApplications}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Characteristics (one per line)</label>
              <div className="col-sm-8">
                <textarea
                  rows={5}
                  className="form-control"
                  name="productTxtCharacteristics"
                  onChange={formik.handleChange}
                  value={productTxtCharacteristics}
                />
              </div>
            </div>
          </>
        )}

        {updateExisting && (
          <button 
            type="submit" 
            className="btn btn-primary"
            disabled={disableSubmit}>
           Update product
          </button>
        ) || (
          <>
            <button className="btn btn-outline-secondary" onClick={() => onBack()}>
              Back
            </button>
            <button type="submit" className="btn btn-primary" disabled={creatingArticle}>
              {(!createNewProduct && !(ownFormula && ownFormula[0] === "on")) && (
                <>Create article</>
              ) || (
                <>Next step</>
              )}
            </button>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = ({ articles, articleCreator }: any) => ({
  adinoproducts: articles.adinoproducts,
  productgroups: articles.productgroups,
  allApplicationcategories: articles.applicationcategories,
  creatingArticle: articleCreator.creatingArticle,
})

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch)

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2)

export default withRouter(pageRedux as any)
