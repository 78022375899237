import React, { PureComponent } from 'react'

import { Button, MenuItem, Dialog, Classes, NumericInput } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { SimpleSelectionOption, Formulation } from '../../../types';
import moment from 'moment';

export interface Props {
  isOpen: boolean,
  formulations: Formulation[],
  selectText?: string
  onDismiss: () => {},
  onSave: (f: Formulation, s: number, code: string) => void
}

interface States {
  formulation?: Formulation
  batchSize: string
  code: string
}

class CreateProductionProtocolDialog extends PureComponent<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      batchSize: '',
      code: '',
    };
  }

  validate() {
    const {
      formulation,
      batchSize,
      code,
    } = this.state;

    if (!formulation || !batchSize || !code) {
      alert('Value missing');
      return;
    }
    const batchSizeNo = parseFloat(batchSize);
    if (Number.isNaN(batchSizeNo)) {
      alert('Invalid number');
      return;
    }

    this.props.onSave(formulation, batchSizeNo, code);
  }

  render() {
    const {
      isOpen,
      formulations,
      onDismiss,
    } = this.props;

    const {
      formulation,
      batchSize,
      code,
    } = this.state;
    
    const filterItem = (query: any, entry: Formulation) => {
      return entry.name.indexOf(query.toLowerCase()) >= 0
    }

    const renderItem = (entry: Formulation, { handleClick, modifiers }: any) => {
      if (!modifiers.matchesPredicate) {
        return null
      }
      return (
        <MenuItem
          active={modifiers.active}
          key={`${entry.id}`}
          onClick={handleClick}
          text={entry.name}
        />
      )
    }

    return (
      <Dialog isOpen={isOpen}>
        <div className={Classes.DIALOG_BODY}>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Code</label>
            <div className="col-sm-8">
              <input
                type="text"
                className={Classes.INPUT}
                autoComplete="off"
                onChange={e => {
                  const val = e.target.value;
                  this.setState({code: val});
                }}
                value={code}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Select formulation</label>
            <div className="col-sm-8">
              <Select
                className="bp3-fill"
                items={formulations}
                itemPredicate={filterItem}
                itemRenderer={renderItem}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={(e: Formulation) => this.setState({formulation: e})}>
                <Button className="bp3-fill" text={formulation ? formulation.name : '(nothing selected)'} rightIcon="double-caret-vertical" />
              </Select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Batch size (kg)</label>
            <div className="col-sm-8">
              <input
                type="text"
                className={Classes.INPUT}
                autoComplete="off"
                onChange={e => {
                  const val = e.target.value;
                  this.setState({batchSize: val});
                }}
                value={batchSize}
              />
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => onDismiss()}>Close</Button>
            <Button className="bp3-intent-primary" onClick={() => this.validate()}>Save</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default CreateProductionProtocolDialog;
