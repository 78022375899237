import { Classes } from '@blueprintjs/core';
import { sortBy, find } from "lodash";
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { initNewProductionProtocol, updateEditingProductionProtocol, createProductionProtocol, loadProductionProtocols } from '../../../redux/productionProtocols';
import { Formulation, FormulationQualitySpecification, ProductionProtocol, RawMaterialOption } from '../../../types';
import RawMaterialSelect from '../../components/molecules/RawMaterialSelect';
import { loadDocuments } from '../../../redux/articles';


interface Props {
  editingProductionProtocol: ProductionProtocol
  formulation: Formulation
  rawMaterialOptions: RawMaterialOption[]
  history: any
  update: (key: string, value: any) => void
  createProductionProtocol: (pp: ProductionProtocol) => void
  loadDocuments: () => {}
  loadProductionProtocols: () => {}
}
interface States {
  sending: boolean
}

class EditProductionProtocol extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sending: false,
    };
  }

  async create() {
    const {
      createProductionProtocol,
      loadDocuments,
      loadProductionProtocols,
      editingProductionProtocol,
      history,
    } = this.props;
    this.setState({sending: true});
    await createProductionProtocol(editingProductionProtocol);
    await Promise.all([
      loadDocuments(),
      loadProductionProtocols(),
    ]);
    this.setState({sending: false});
    history.replace(`/production-protocols`);
  }

  addMoreLines(e: Event) {
    e.preventDefault();
    this.props.update('__add_lines', null);
  }

  render() {
    const {
      editingProductionProtocol : pp,
      formulation,
      update,
      rawMaterialOptions,
    } = this.props;
    const {
      sending,
    } = this.state;

    const { data } = pp;
    if (!data) {
      throw new Error('invalid data');
    }

    return (
      <>
        <div className="container mt-2">
          <h4>
            Production protocol for {formulation.name}
          </h4>

          <div className="production-protocol-sheet">
            <table className="table table-bordered tbl-prod">
              <colgroup>
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
              </colgroup>
              <tbody>
                <tr>
                  <td rowSpan={2}>
                    <img src="/adino-logo.png" width="200" />
                  </td>
                  <td colSpan={3}><h4>DW KLEBSTOFFE (THAILAND) Co.Ltd.</h4></td>
                </tr>
                <tr>
                  <td colSpan={3}>TITLE: PRODUCTION PROTOCOL SOLVENT BASED FORM</td>
                </tr>
                <tr>
                  <td>DOC.NO.: {data.header.docNo}</td>
                  <td colSpan={2}>DOC.DATE.: {data.header.docDate}</td>
                  <td>REV.NO.: {data.header.revNo}</td>
                </tr>
                <tr>
                  <td>RECORD NO.:</td>
                  <td>{pp.formulation.recordNo}</td>
                  <td>PRODUCTION DATE.:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>FORMULATION REV NO.:</td>
                  <td>{pp.formulation.name}</td>
                  <td>BATCH NO.:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>DATE OF FORMULATION REV NO.:</td>
                  <td>{moment(pp.formulation.revisionDate).format('YYYY-MM-DD')}</td>
                  <td>MACHINE NO.:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>PRODUCTION AREA/FACILITY:</td>
                  <td>
                    <input
                      value={data.header.productionArea}
                      autoComplete="off"
                      onChange={e => {
                        update('data.header.productionArea', e.target.value);
                      }}
                      type="text"
                      className={Classes.INPUT}
                    />
                  </td>
                  <td>BATCH SIZE.:</td>
                  <td>{data.batchSize} + ______ = ___________ kg</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-bordered tbl-prod tbl-prod-steps">
              <colgroup>
                <col width="40" />
                <col width="110" />
                <col width="200" />
                <col width="90" />
                <col />
                <col width="90" />
                <col width="90" />
              </colgroup>
              <thead>
                <tr>
                  <th>NO.</th>
                  <th>RM CODE.</th>
                  <th>BATCH NO.</th>
                  <th>Quantity (kg)</th>
                  <th>STEP OF PROCESS</th>
                  <th>Quantity (kg)</th>
                  <th>TIME (24 hrs)</th>
                </tr>
              </thead>
              <tbody>
                {data.steps.map((s, i) => (
                  <tr key={`step-${i}`}>
                    <td className="text-center">{s.no}</td>
                    <td>
                      <RawMaterialSelect
                        items={rawMaterialOptions}
                        selectedItem={find(rawMaterialOptions, (r: RawMaterialOption) => r.code === s.rmCode)}
                        onItemSelect={(e: RawMaterialOption) => update(`data.steps[${i}].rmCode`, e.code)}
                        allowNone={true}
                        defaultText="-"
                      />
                    </td>
                    <td></td>
                    <td>
                      <input
                        value={s.quantity}
                        autoComplete="off"
                        onChange={e => {
                          update(`data.steps[${i}].quantity`, e.target.value);
                        }}
                        type="text"
                        className={Classes.INPUT + ' input-number'}
                      />
                    </td>
                    <td>
                      <input
                        value={s.stepOfProcess}
                        autoComplete="off"
                        onChange={e => {
                          update(`data.steps[${i}].stepOfProcess`, e.target.value);
                        }}
                        type="text"
                        className={Classes.INPUT}
                      />
                    </td>
                    <td></td>
                    <td>
                      <input
                        value={s.time}
                        autoComplete="off"
                        onChange={e => {
                          update(`data.steps[${i}].time`, e.target.value);
                        }}
                        type="text"
                        className={Classes.INPUT}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={7}>
                    <a href="#" onClick={e => this.addMoreLines(e as any)}>
                      <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>add_circle</i>
                      Add more lines
                    </a>
                  </td>
                </tr>
                <tr>
                  <th colSpan={2}>TOTAL QUANTITY</th>
                  <td colSpan={2}>{data.batchSize} + ______ = ___________ kg</td>
                  <th>TOTAL CHARGING QUANTITY</th>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={4} style={{visibility: 'hidden'}}></td>
                  <th>TOTL QUANTITY EXCL. "REWORK"</th>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={4} style={{visibility: 'hidden'}}></td>
                  <th colSpan={2}>TOTAL TIME OF PROCESS</th>
                  <td></td>
                </tr>
              </tbody>
            </table>   

            <table className="table table-bordered tbl-prod">
              <thead>
                <tr>
                  <th colSpan={9}>IN-PROCESS INSPECTION</th>
                </tr>
                <tr>
                  <th rowSpan={2}>NO.</th>
                  <th rowSpan={2}>MEASUREMENT</th>
                  <th rowSpan={2}>EQUIPMENT/PARAMETER</th>
                  <th rowSpan={2}>MIN</th>
                  <th rowSpan={2}>MAX</th>
                  <th colSpan={3} className="tbl-prod-result">RESULT</th>
                  <th rowSpan={2}>UNIT</th>
                </tr>
                <tr>
                  <th className="tbl-prod-result">1st trial</th>
                  <th className="tbl-prod-result">2st trial</th>
                  <th className="tbl-prod-result">3st trial</th>
                </tr>
              </thead>
              <tbody>
                {pp.formulation.inprocessspecs.map((s: FormulationQualitySpecification, i) => (
                  <tr key={`inprocess-${i}`}>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{s.method}</td>
                    <td className="text-center">{s.parameter}</td>
                    <td className="text-center">{s.valueMin}</td>
                    <td className="text-center">{s.valueMax}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-center">{s.valueUnit}</td>
                  </tr>
                ))}
              </tbody>
              <thead>
                <tr>
                  <th colSpan={9}>COA STANDARD PARAMETER AT 30°C</th>
                </tr>
                <tr>
                  <th rowSpan={2}>NO.</th>
                  <th rowSpan={2}>MEASUREMENT</th>
                  <th rowSpan={2}>EQUIPMENT/PARAMETER</th>
                  <th rowSpan={2}>MIN</th>
                  <th rowSpan={2}>MAX</th>
                  <th colSpan={3}>RESULT</th>
                  <th rowSpan={2}>UNIT</th>
                </tr>
              </thead>
              <tbody>
                {pp.formulation.formulationqualityspecifications.map((s: FormulationQualitySpecification, i) => (
                  <tr key={`quspec-${i}`}>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{s.method}</td>
                    <td className="text-center">{s.parameter}</td>
                    <td className="text-center">{s.valueMin}</td>
                    <td className="text-center">{s.valueMax}</td>
                    <td colSpan={3}></td>
                    <td className="text-center">{s.valueUnit}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table className="table table-bordered tbl-prod">
              <colgroup>
                <col width="5%" />
                <col />
                <col width="20%" />
                <col />
                <col />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={8}>PACKING</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">1</td>
                  <td>TOTAL FILLED</td>
                  <td></td>
                  <td className="text-center">kg</td>
                  <td>TOTAL DRUMS</td>
                  <td>
                    <input
                      value={data.packing.totalDrums}
                      autoComplete="off"
                      onChange={e => {
                        update(`data.packing.totalDrums`, e.target.value);
                      }}
                      type="text"
                      className={Classes.INPUT}
                    />
                  </td>
                  <td>1 DRUM</td>
                  <td>
                    <input
                      value={data.packing.oneDrum}
                      autoComplete="off"
                      onChange={e => {
                        update(`data.packing.oneDrum`, e.target.value);
                      }}
                      type="text"
                      className={Classes.INPUT + ' space-right'}
                    />
                    kg
                  </td>
                </tr>
                <tr>
                  <td className="text-center">2</td>
                  <td>TOTAL LOST</td>
                  <td></td>
                  <td className="text-center">kg</td>
                  <td>TOTAL PAILS</td>
                  <td>
                    <input
                      value={data.packing.totalPails}
                      autoComplete="off"
                      onChange={e => {
                        update(`data.packing.totalPails`, e.target.value);
                      }}
                      type="text"
                      className={Classes.INPUT}
                    />
                  </td>
                  <td>1 PAIL</td>
                  <td>
                    <input
                      value={data.packing.onePail}
                      autoComplete="off"
                      onChange={e => {
                        update(`data.packing.onePail`, e.target.value);
                      }}
                      type="text"
                      className={Classes.INPUT + ' space-right'}
                    />
                    kg
                  </td>
                </tr>
                <tr>
                  <td className="text-center">3</td>
                  <td>TOTAL KG LEFT IN (DRUM/PAIL)</td>
                  <td></td>
                  <td className="text-center">kg</td>
                  <td>REMARK</td>
                  <td colSpan={3}>
                    <input
                      value={data.packing.remark}
                      autoComplete="off"
                      onChange={e => {
                        update(`data.packing.remark`, e.target.value);
                      }}
                      type="text"
                      className={Classes.INPUT}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table table-bordered tbl-prod">
              <colgroup>
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
                <col width="40%" />
              </colgroup>
              <tbody>
                <tr>
                  <td className="text-center">QC CHECK</td>
                  <td className="text-center">[ ] PASS</td>
                  <td className="text-center">[ ] REJECT</td>
                  <td>REMARK:</td>
                </tr>
              </tbody>
            </table>

            <div className="mb-4">
              <small>{pp.code}</small>
            </div>
          </div>
          <button
            onClick={() => this.create()} 
            className="btn btn-primary"
            disabled={sending}>Generate</button>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ productionProtocols } : any, ownProps: any) => {
  const formulation: Formulation = productionProtocols.editingProductionProtocol.formulation;
  const rawMaterialOptions: RawMaterialOption[] = formulation.rawmaterialentries.map(e => {
    if (e.rawmaterial) {
      return {
        id: e.rawmaterial.id!,
        code: e.rawmaterial.code,
        group: e.rawmaterial.group,
        type: 'rawmaterial',
      };
    } else if (e.rawmaterialmix) {
      return {
        id: e.rawmaterialmix.id,
        code: e.rawmaterialmix.code,
        group: e.rawmaterialmix.group,
        type: 'rawmaterialmix',
      };
    }
    throw new Error();
  });
  rawMaterialOptions.push({
    id: formulation.id!,
    code: formulation.name,
    group: formulation.name,
    type: 'rawmaterial',
  });

  return {
    formulation,
    editingProductionProtocol: productionProtocols.editingProductionProtocol,
    rawMaterialOptions: sortBy(rawMaterialOptions, 'code'),
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      initNewProductionProtocol, 
      update: updateEditingProductionProtocol, 
      createProductionProtocol,
      loadProductionProtocols,
      loadDocuments,
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProductionProtocol as any)

export default withRouter(pageRedux);
