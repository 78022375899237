import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { initNewArticle, createArticle, initNewFormulation } from '../../../redux/articleCreator';
import { loadArticlesData, loadDocuments } from '../../../redux/articles';
import showFormErrors from '../../../helper/show-form-errors';

import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'
import { validateFormulation, validateArticle, validateProduct } from '../../../lib/validate'
import { setAjaxLoading } from '../../../redux/user'

class ArticleCreatorPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
      documents: [],
    }

    this.formikCfg = {
      initialValues: {
        articleCode: '',
        packaging: '',
        vendor: '',
        vendorCode: '',
        ownFormula: [],
        createNewProduct: false,
        applicationCategories: [],
      },
      onSubmit: values => {
        // validate
        const formErrors = [];

        if (this.state.currentStep === 0) {
          formErrors.push(...validateArticle(values));
        } else if (this.state.currentStep === 1) {
          formErrors.push(...validateProduct(values));
        } else if (this.state.currentStep === 2) {
          // re-activate later
          // at least tds and msds must exist
          // if (!this.props.documents[0].revisionDate) {
          //   formErrors.push({
          //     field: 'TDS document',
          //     message: 'is missing'
          //   });
          // }
          // if (!this.props.documents[1].revisionDate) {
          //   formErrors.push({
          //     field: 'MSDS document',
          //     message: 'is missing'
          //   });
          // }
        } else if (this.state.currentStep === 3) {
          formErrors.push(...validateFormulation(this.props.formulation));
        }

        let nextStep = this.state.currentStep + 1;
        if (nextStep === 2 && !values.createNewProduct) {
          nextStep++;
        }
        if (nextStep === 3 && !(values.ownFormula && values.ownFormula[0] === "on")) {
          nextStep++;
        }

        if (formErrors.length > 0) {
          showFormErrors(formErrors);
        } else if (nextStep === 4) {
          // finished
          const {
            createArticle, documents, formulation, loadArticlesData, history, loadDocuments, setAjaxLoading
          } = this.props;

          setAjaxLoading(true);

          createArticle(values, documents, formulation)
            .then(() => {
              history.push('/');
              Promise.all([
                loadArticlesData(this.props.role),
                loadDocuments()
              ])
              .then(() => setAjaxLoading(false))
              .catch(e => {
                setAjaxLoading(false);
                console.error(e);
              });
            })
            .catch(error => {
              setAjaxLoading(false);
              console.error(error);
              alert("Something went wrong. Error = " + JSON.stringify(error));
            });
        } else {
          this.setState({
            currentStep: nextStep
          });
        }
      }
    }
  }

  componentDidMount() {
    this.props.initNewArticle();
    this.props.initNewFormulation(this.props.user, {});
  }

  goBack(createNewProduct) {
    let newStep = this.state.currentStep - 1;
    if (newStep === 2 && !createNewProduct) {
      newStep--;
    }
    this.setState({
      currentStep: newStep
    });
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <Link to="/">
            <small className="">&lt;&lt; back</small>
          </Link>
        </div>
        <div className="container mt-2 mb-4">
          <div className="col">
            <Formik
              initialValues={this.formikCfg.initialValues}
              onSubmit={this.formikCfg.onSubmit}>
              {formik => (
                <form onSubmit={formik.handleSubmit}>
                  <h5>Create new article</h5>
                  <ul className="nav justify-content-center mb-4">
                    <li className="nav-item">
                      <span className="nav-link">
                        {(this.state.currentStep === 0 && (
                          <strong>Article</strong>
                        )) || <>Article</>}
                      </span>
                    </li>
                    <li className="nav-item">
                      <span className="nav-link">
                        {(this.state.currentStep === 1 && (
                          <strong>Product</strong>
                        )) || <>Product</>}
                      </span>
                    </li>
                    {(formik.values.createNewProduct) && (
                    <li className="nav-item">
                      <span className="nav-link">
                        {(this.state.currentStep === 2 && (
                          <strong>Documents</strong>
                        )) || <>Documents</>}
                      </span>
                    </li>
                    )}
                    {(formik.values.ownFormula && formik.values.ownFormula[0] === "on") && (
                      <li className="nav-item">
                        <span className="nav-link">
                          {(this.state.currentStep === 3 && (
                            <strong>Materials</strong>
                          )) || <>Materials</>}
                        </span>
                      </li>
                    )}
                  </ul>
                  {(this.state.currentStep === 0 && (
                    <Step1 formik={formik} />
                  )) ||
                    (this.state.currentStep === 1 && (
                      <Step2 formik={formik} onBack={() => this.goBack()} />
                    )) ||
                    (this.state.currentStep === 2 && (
                      <Step3 formik={formik} onBack={() => this.goBack()} />
                    )) ||
                    (this.state.currentStep === 3 && (
                      <Step4 formik={formik} onBack={() => this.goBack(formik.values.createNewProduct)} />
                    ))}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </>
    )
  }
}

ArticleCreatorPage.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  formulation: PropTypes.object,
  createArticle: PropTypes.func.isRequired,
  loadArticlesData: PropTypes.func.isRequired,
  loadDocuments: PropTypes.func.isRequired,
}

const mapStateToProps = ({ articleCreator, user }) => ({
  documents: articleCreator.documents,
  formulation: articleCreator.formulation,
  user: user.user,
  role: user.activeRole,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  initNewArticle, createArticle, loadArticlesData, initNewFormulation, loadDocuments,
  setAjaxLoading
}, dispatch)

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleCreatorPage)

export default withRouter(pageRedux)
