import React, { PureComponent } from 'react'

import { Button, MenuItem, Dialog, Classes, NumericInput } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { capitalize } from 'lodash';

import { RawMaterialOption, RawMaterialEntry, Document, AdinoProduct } from '../../../types';
import { DocumentTypes } from '../../../lib/content/statics';
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';
import $ from 'jquery';
import FileDropzone from '../molecules/FileDropzone';
import showFormErrors from '../../../helper/show-form-errors';
import AdinoProductSelect from '../molecules/AdinoProductSelect';

export interface Props {
  isOpen: boolean,
  onDismiss: () => {},
  onSave: (entry: Document) => Promise<void>,
  editingEntry?: Partial<Document>,
  adinoproducts?: AdinoProduct[],
}

interface States {
  mandatory: boolean,
  type: string,
  language?: string,
  requiredRole: string,
  revisionDate?: Date,
  revisionVersion?: number,
  fileName: string | null,
  fileId: number | null,
  adinoProduct?: {
    id: number
    name: string
  },
  loading: boolean
}

const emptyState: States = {
  mandatory: false,
  type: '',
  language: 'english',
  requiredRole: '',
  revisionDate: new Date(),
  revisionVersion: 1,
  fileName: null,
  fileId: null,
  adinoProduct: undefined,
  loading: false,
};

const renderSelectItems = (entry: {key: string, value: string}, { handleClick, modifiers }: any) => {
  return (
    <MenuItem
      active={modifiers.active}
      key={entry.key}
      onClick={handleClick}
      text={entry.value}
    />
  )
}

class DocumentDialog extends PureComponent<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...emptyState
    };
  }

  save() {
    // validate fields
    const formErrors: { field: string, message: string }[] = [];

    if (!this.state.type) {
      formErrors.push({
        field: 'Type',
        message: 'is missing'
      });
    }
    if (!this.state.language) {
      formErrors.push({
        field: 'Language',
        message: 'is missing'
      });
    }
    if (!this.state.requiredRole) {
      formErrors.push({
        field: 'Visible to',
        message: 'is missing'
      });
    }
    if (!this.state.revisionDate) {
      formErrors.push({
        field: 'Revision date',
        message: 'is missing'
      });
    }
    if (!this.state.revisionVersion) {
      formErrors.push({
        field: 'Revision version',
        message: 'is missing'
      });
    }
    if (!this.state.fileName) {
      formErrors.push({
        field: 'File',
        message: 'is missing'
      });
    }

    if (formErrors.length > 0) {
      showFormErrors(formErrors);
    } else {
      let prod = undefined;
      if (this.state.adinoProduct && this.state.adinoProduct.id !== -1) {
        prod = {
          ...this.state.adinoProduct
        };
      }
      this.setState({
        loading: true,
      });
      this.props.onSave({
        id: this.props.editingEntry && this.props.editingEntry.id,
        type: this.state.type,
        language: this.state.language,
        requiredRole: this.state.requiredRole,
        revisionDate: this.state.revisionDate,
        revisionVersion: this.state.revisionVersion,
        file: {
          name: this.state.fileName!,
          id: this.state.fileId!,
        },
        adinoproduct: prod,
      }).then(() => {
        this.setState({
          ...emptyState
        });
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { editingEntry } = this.props;
    if ((prevProps.editingEntry !== editingEntry) || (prevProps.isOpen !== this.props.isOpen)) {
      if (editingEntry) {
        this.setState({
          mandatory: editingEntry.mandatory || false,
          type: editingEntry.type || '',
          language: editingEntry.language,
          requiredRole: editingEntry.requiredRole || '',
          revisionDate: editingEntry.revisionDate,
          revisionVersion: editingEntry.revisionVersion,
          fileName: editingEntry.file && editingEntry.file.name || null,
          fileId: editingEntry.file && editingEntry.file.id || null,
          adinoProduct: editingEntry.adinoproduct
        });
      } else {
        this.setState({
          ...emptyState
        });
      }
    }
  }

  render() {
    const {
      isOpen,
      onDismiss,
      adinoproducts,
    } = this.props;

    const {
      mandatory,
      type,
      language,
      requiredRole,
      revisionDate,
      revisionVersion,
      fileName,
      adinoProduct,
      loading,
    } = this.state;
    
    const disabledVisibleTo = type === 'tds' || type === 'msds';
    
    return (
      <Dialog isOpen={isOpen}>
        <div className={Classes.DIALOG_BODY}>
          {adinoproducts && (
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Assign to Product</label>
              <div className="col-sm-8">
                <AdinoProductSelect
                  allowGlobal={true}
                  items={adinoproducts}
                  selectedItem={adinoProduct as AdinoProduct}
                  onItemSelect={(p: AdinoProduct) => {
                    this.setState({
                      adinoProduct: {
                        id: p.id!,
                        name: p.name
                      }
                    });
                  }} />
              </div>
            </div>
          )}
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Type</label>
            <div className="col-sm-8">
              <Select
                disabled={mandatory}
                filterable={false}
                itemRenderer={renderSelectItems}
                className="bp3-fill"
                items={Object.keys(DocumentTypes).map(t => ({key: t, value: DocumentTypes[t]}))}
                onItemSelect={(e: any) => {
                  const ch: any = {
                    type: e.key
                  };
                  if (e.key === 'tds' || e.key === 'msds') {
                    ch.requiredRole = 'customer';
                  }
                  this.setState(ch);
                }}>
                <Button disabled={mandatory} className="bp3-fill" text={type ? DocumentTypes[type] : '(nothing selected)'} rightIcon={!mandatory && "double-caret-vertical"} />
              </Select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Language</label>
            <div className="col-sm-8">
              <Select
                disabled={mandatory}
                filterable={false}
                itemRenderer={renderSelectItems}
                className="bp3-fill"
                items={['english', 'german', 'thai'].map(t => ({key: t, value: capitalize(t)}))}
                onItemSelect={(e: any) => this.setState({language: e.key})}>
                <Button disabled={mandatory} className="bp3-fill" text={language ? capitalize(language) : '(nothing selected)'} rightIcon={!mandatory && "double-caret-vertical"} />
              </Select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Visible to</label>
            <div className="col-sm-8">
              <Select
                disabled={disabledVisibleTo}
                filterable={false}
                itemRenderer={renderSelectItems}
                className="bp3-fill"
                items={['customer', 'sales', 'administration', 'management', 'director'].map(t => ({key: t, value: capitalize(t)}))}
                onItemSelect={(e: any) => this.setState({requiredRole: e.key})}>
                <Button disabled={disabledVisibleTo} className="bp3-fill" text={requiredRole ? capitalize(requiredRole) : '(nothing selected)'} rightIcon={!disabledVisibleTo && "double-caret-vertical"} />
              </Select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Revision date</label>
            <div className="col-sm-8">
              <DateInput
                formatDate={(date: Date) => moment(date).format('DD.MM.YYYY')}
                parseDate={(str: string) => moment(str, 'DD.MM.YYYY').toDate()}
                placeholder={"DD.MM.YYYY"}
                onChange={d => this.setState({ revisionDate: d })}
                value={revisionDate}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Revision version</label>
            <div className="col-sm-8">
              <input
                  type="text"
                  className={Classes.INPUT}
                  name="revisionVersion"
                  autoComplete="off"
                  onChange={e => {
                    const val = e.target.value;
                    const num = parseInt(val as string, 10);
                    if (!Number.isNaN(num)) {
                      this.setState({ revisionVersion: num })
                    } else {
                      this.setState({ revisionVersion: 1 })
                    }
                  }}
                  value={revisionVersion}
                />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Upload document</label>
            <div className="col-sm-8">
              <FileDropzone
                onFileUploaded={(id, name) => {
                  this.setState({
                    fileId: id,
                    fileName: name
                  })
                }}
                currentFile={fileName}
              />
            </div>
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => onDismiss()} disabled={loading}>Close</Button>
            <Button className="bp3-intent-primary" onClick={() => this.save()} disabled={loading}>Save</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default DocumentDialog;
