import gql from 'graphql-tag';

import client from '../helper/apollo-client';

import { LOAD_ARTICLES_DATA_SUCCESS } from './articles';
import { Formulation, RawMaterialEntry, RawMaterial, RawMaterialMix } from '../types';
import moment from 'moment';
import { createRawMaterialEntry } from './articleCreator';

export const REMOVE_RAW_MATERIAL_ENTRY = 'formulations/REMOVE_RAW_MATERIAL_ENTRY';
export const ADD_OR_UPDATE_RAW_MATERIAL_ENTRY = 'formulations/ADD_OR_UPDATE_RAW_MATERIAL_ENTRY';
export const UPDATE_EDITING_RAWMATMIX = 'formulations/UPDATE_EDITING_RAWMATMIX';
export const INIT_NEW_RAWMATMIX = 'formulations/INIT_NEW_RAWMATMIX';

type Props = {
  formulations: Formulation[]
  rawmaterialentries: RawMaterialEntry[]
  rawmatmixes: RawMaterialMix[]
  editingRawmatmix: RawMaterialMix
}

const initialState : Props = {
  formulations: [],
  rawmaterialentries: [],
  rawmatmixes: [],
  editingRawmatmix: {
    id: -1,
    code: '',
    group: '',
    rawmaterialentries: []
  },
}

export default (state = initialState, action: any) => {
  switch (action.type) {

    case LOAD_ARTICLES_DATA_SUCCESS:
      const newState = {...state};
      if (action.payload.formulas) {
        newState.formulations = action.payload.formulas.map((f: Formulation) => {
          return {
            ...f,
            revisionDate: moment(f.revisionDate).toDate(),
            rawmaterialentries: lookupRawMaterials(
              f.rawmaterialentries as {id: number}[],
              action.payload.rawmatents,
              action.payload.rawmats,
              action.payload.rawmatmixes,
            )
          } as Formulation
        });
      }
      if (action.payload.rawmatents) {
        newState.rawmaterialentries = action.payload.rawmatents;
      }
      if (action.payload.rawmatmixes) {
        newState.rawmatmixes = action.payload.rawmatmixes.map((f: RawMaterialMix) => {
          return {
            ...f,
            rawmaterialentries: lookupRawMaterials(
              f.rawmaterialentries as {id: number}[],
              action.payload.rawmatents,
              action.payload.rawmats,
              action.payload.rawmatmixes,
            )
          };
        })
      }
      return newState;

      case INIT_NEW_RAWMATMIX: {
        const editingRawmatmix: RawMaterialMix = {
          id: -1,
          code: '',
          description: '',
          group: '',
          rawmaterialentries: [],
          ...action.payload
        };
        return {
          ...state,
          editingRawmatmix
        }
      }

      case REMOVE_RAW_MATERIAL_ENTRY: {
        if (state.editingRawmatmix == null) throw new Error();

        const entry = action.payload;
        const newRawMaterials = state.editingRawmatmix.rawmaterialentries.filter((e: RawMaterialEntry) => e !== entry);
  
        return {
          ...state,
          editingRawmatmix: {
            ...state.editingRawmatmix,
            rawmaterialentries: newRawMaterials
          }
        };
      }
  
      case ADD_OR_UPDATE_RAW_MATERIAL_ENTRY: {
        if (state.editingRawmatmix == null) throw new Error();

        const entry = action.payload;
        let entries;
        if (!entry) {
          // in case of update, we just want to redraw
          // because we already changed the object directly
          entries = state.editingRawmatmix.rawmaterialentries;
        } else {
          entries = state.editingRawmatmix.rawmaterialentries.concat(entry);
        }
        return {
          ...state,
          editingRawmatmix: {
            ...state.editingRawmatmix,
            rawmaterialentries: entries
          },
        };
      }

      case UPDATE_EDITING_RAWMATMIX: {
        return {
          ...state,
          editingRawmatmix: {
            ...state.editingRawmatmix,
            ...action.payload,
          }
        }
      }

    default:
      return state
  }
}

function lookupRawMaterials(onlyIds: {id: number}[], rawmatents: RawMaterialEntry[], rawmats: RawMaterial[], rawmatmixes: RawMaterialMix[]) {
  return onlyIds.map((onlyId) => {
      // lookup raw material entry + raw material + raw material mix
      const rme: RawMaterialEntry = rawmatents.filter((a: RawMaterialEntry) => a.id === onlyId.id)[0];
      let rawMaterial: RawMaterial | undefined = undefined;
      if (rme.rawmaterial) {
        rawMaterial = rawmats.filter((a: RawMaterial) => a.id === rme.rawmaterial!.id)[0];
      }

      let rawMaterialMix: RawMaterialMix | undefined = undefined;
      if (rme.rawmaterialmix) {
        rawMaterialMix = rawmatmixes.filter((a: RawMaterialMix) => a.id === rme.rawmaterialmix!.id)[0];
      }

      return {
        ...rme,
        rawmaterial: rawMaterial,
        rawmaterialmix: rawMaterialMix,
      } as RawMaterialEntry;
    })
}

export const initNewRawmatmix = function(existingRawmatmix?: RawMaterialMix) {
  return {
    type: INIT_NEW_RAWMATMIX,
    payload: existingRawmatmix,
  }
}

export const removeRawMaterialEntryEditing = function(entry: RawMaterialEntry) {
  return (dispatch: any) => {
    dispatch({
      type: REMOVE_RAW_MATERIAL_ENTRY,
      payload: entry
    })
  }
}

export const addOrUpdateRawMaterialEntry = function(entry: RawMaterialEntry | null) {
  return (dispatch: any) => {
    dispatch({
      type: ADD_OR_UPDATE_RAW_MATERIAL_ENTRY,
      payload: entry
    })
  }
}

export const updateEditingRawmatmix = function(field: string, value: any) {
  return {
    type: UPDATE_EDITING_RAWMATMIX,
    payload: {[field]: value}
  };
}

/**
 * with all dependencies
 */
export const createOrUpdateRawmatmixComplete = function(rawmatmix: RawMaterialMix) {
  return async function(dispatch: any) {
    // raw material entries
    await Promise.all(rawmatmix.rawmaterialentries.map(async function(entry, idx) {
      entry.ordering = idx;
      const { data } = await dispatch(createRawMaterialEntry(entry));
      entry.id = data.createRawmatent.rawmatent.id;
    }));

    if (rawmatmix.id !== -1) {
      // update existing
      await dispatch(updateRawmatmix(rawmatmix));
      return rawmatmix.id;
    } else {
        const { data } = await dispatch(createRawmatmix(rawmatmix));
        const id = data.createRawmatmix.rawmatmix.id;
        return id;
    }
  }
}

export const updateRawmatmix = function(rawmatmix: RawMaterialMix) {
  const data = {
    ...rawmatmix,
    rawmaterialentries: rawmatmix.rawmaterialentries.map(e => e.id),
    id: undefined,
    __typename: undefined,
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: updateRawmatmixInput!) {
          updateRawmatmix(input : $input) {
            rawmatmix {id}
          }
        }`,
        variables: {
          input: {
            where: {id: rawmatmix.id},
            data
          }
        }
    });
  }
}

export const createRawmatmix = function(rawmatmix: RawMaterialMix) {
  const data = {
    ...rawmatmix,
    rawmaterialentries: rawmatmix.rawmaterialentries.map(e => e.id),
    id: undefined,
    __typename: undefined,
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: createRawmatmixInput!) {
          createRawmatmix(input : $input) {
            rawmatmix {id}
          }
        }`,
        variables: {
          input: {
            data
          }
        }
    });
  }
}

export const createOrUpdateRawmat = function(rawmat: RawMaterial) {
  return async function(dispatch: any) {
    if (rawmat.id && rawmat.id !== -1) {
      // update existing
      await dispatch(updateRawmat(rawmat));
      return rawmat.id;
    } else {
        const { data } = await dispatch(createRawmat(rawmat));
        const id = data.createRawmat.rawmat.id;
        return id;
    }
  }
}

export const createRawmat = function(rawmat: RawMaterial) {
  const data = {
    ...rawmat,
    id: undefined,
    __typename: undefined,
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: createRawmatInput!) {
          createRawmat(input : $input) {
            rawmat {id}
          }
        }`,
        variables: {
          input: {
            data
          }
        }
    });
  }
}

export const updateRawmat = function(rawmat: RawMaterial) {
  const data = {
    ...rawmat,
    id: undefined,
    __typename: undefined,
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: updateRawmatInput!) {
          updateRawmat(input : $input) {
            rawmat {id}
          }
        }`,
        variables: {
          input: {
            where: {id: rawmat.id},
            data
          }
        }
    });
  }
}

export const deleteRawmat = function(rawmat: RawMaterial) {
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: deleteRawmatInput!) {
          deleteRawmat(input : $input) {
            rawmat {id}
          }
        }`,
        variables: {
          input: {
            where: {id: rawmat.id},
          }
        }
    });
  }
}

/**
 * with all dependencies
 */
export const deleteRawmatmixComplete = function(rawmatmix: RawMaterialMix) {
  return async function(dispatch: any) {
    // raw material entries
    await Promise.all(rawmatmix.rawmaterialentries.map(async function(entry) {
      await dispatch(deleteRawMaterialEntry(entry));
    }));

    await dispatch(deleteRawmatmix(rawmatmix));
  }
}

export const deleteRawMaterialEntry = function(rawmatent: RawMaterialEntry) {
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: deleteRawmatentInput!) {
          deleteRawmatent(input : $input) {
            rawmatent {id}
          }
        }`,
        variables: {
          input: {
            where: {id: rawmatent.id},
          }
        }
    });
  }
}

export const deleteRawmatmix = function(rawmatmix: RawMaterialMix) {
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: deleteRawmatmixInput!) {
          deleteRawmatmix(input : $input) {
            rawmatmix {id}
          }
        }`,
        variables: {
          input: {
            where: {id: rawmatmix.id},
          }
        }
    });
  }
}

/**
 * with all dependencies
 */
export const deleteFormulaComplete = function(formula: Formulation) {
  return async function(dispatch: any) {
    // raw material entries
    await Promise.all(formula.rawmaterialentries.map(async function(entry) {
      await dispatch(deleteRawMaterialEntry(entry));
    }));

    await dispatch(deleteFormula(formula));
  }
}

export const deleteFormula = function(formula: Formulation) {
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: deleteFormulaInput!) {
          deleteFormula(input : $input) {
            formula {id}
          }
        }`,
        variables: {
          input: {
            where: {id: formula.id},
          }
        }
    });
  }
}
