import React from 'react'

import $ from 'jquery';

export default function SendDocumentDialog({id, documentName, onSubmit}) {
  return (
    <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Send Document</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form action="#" onSubmit={(e) => {
            e.preventDefault();
            onSubmit($(`#${id}-name`).val(), $(`#${id}-mail`).val());
            $(`#${id}-submit`).prop('disabled', true);
            return false;
          }}>
            <div className="modal-body">
              <p>
                Send download link for <ins>{documentName}</ins>.
              </p>
              <div className="input-group mb-3">
                <input id={`${id}-name`} type="text" className="form-control" placeholder="Full name" />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">@</span>
                </div>
                <input id={`${id}-mail`} type="text" className="form-control" placeholder="E-Mail" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button id={`${id}-submit`} type="submit" className="btn btn-primary">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}