import { Classes } from "@blueprintjs/core";
import { DateInput } from '@blueprintjs/datetime';
import { concat, filter, indexOf } from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';

import { FormulationQualitySpecification, RawMaterial, RawMaterialEntry, RawMaterialMix, RawMaterialOption, Formulation } from '../../../types';
import FormulationQualitySpecificationTable from '../molecules/FormulationQualitySpecificationTable';
import FormulationEntryDialog from './FormulationEntryDialog';
import { removeRawMaterialEntryEditing, addOrUpdateRawMaterialEntry, addNewQualitySpec, removeQualitySpecs, updateQualitySpec, updateEditingFormulation } from '../../../redux/articleCreator';


export interface Props {
  rawMaterialOptions: RawMaterialOption[],
  readOnly?: boolean,
  formulation: Formulation,
  removeRawMaterialEntryEditing: (entry: RawMaterialEntry) => {},
  addOrUpdateRawMaterialEntry: (entry: RawMaterialEntry | null) => {},
  addNewQualitySpec: (inProcess: boolean) => {},
  removeQualitySpecs: (inProcess: boolean, indexes: number[]) => {},
  updateQualitySpec: (inProcess: boolean, idx: number, data: FormulationQualitySpecification) => {},
  updateEditingFormulation: (f: string, v: any) => {}
}

interface States {
  dialogEntryIsOpen: boolean,
  editingEntry?: RawMaterialEntry,
}

class FormulationDataSheet extends PureComponent<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dialogEntryIsOpen: false,
    };
  }

  editEntry = (entry: RawMaterialEntry) => {
    this.setState({
      dialogEntryIsOpen: true,
      editingEntry: entry,
    });
  }

  openNewEntry = () => {
    this.setState({
      dialogEntryIsOpen: true,
      editingEntry: undefined,
    });
  }

  addOrUpdateEntry = (entry: RawMaterialEntry) => {
    if (this.state.editingEntry) {
      this.state.editingEntry.ppm = entry.ppm;
      this.state.editingEntry.rawmaterial = entry.rawmaterial;
      this.state.editingEntry.rawmaterialmix = entry.rawmaterialmix;
      this.state.editingEntry.note = entry.note;
      this.props.addOrUpdateRawMaterialEntry(null);
    } else {
      this.props.addOrUpdateRawMaterialEntry(entry);
    }
    
    this.setState({
      dialogEntryIsOpen: false,
      editingEntry: undefined,
    });
  }

  ppmToPercentage = (ppm: number) => ppm / 10000

  showTotalQuantity = () =>
    this.ppmToPercentage(this.props.formulation.rawmaterialentries.reduce((prev, curr) => prev + curr.ppm, 0));


  render() {
    const {
      dialogEntryIsOpen,
      editingEntry,
    } = this.state;

    const {
      rawMaterialOptions,
      readOnly,
      formulation,
      updateEditingFormulation,
    } = this.props;

    return (
      <>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Record no.</label>
          <div className="col-sm-8">
            <input
                type="text"
                className={Classes.INPUT}
                name="recordNo"
                disabled={readOnly}
                autoComplete="off"
                onChange={e => {
                  updateEditingFormulation('recordNo', e.target.value);
                }}
                value={formulation.recordNo}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Name</label>
          <div className="col-sm-8">
            <input
                type="text"
                className={Classes.INPUT}
                name="name"
                disabled={readOnly}
                autoComplete="off"
                onChange={e => {
                  updateEditingFormulation('name', e.target.value);
                }}
                value={formulation.name}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Revision Date</label>
          <div className="col-sm-8">
            <DateInput
              value={formulation.revisionDate}
              onChange={d => updateEditingFormulation('revisionDate', d)}
              disabled={readOnly}
              formatDate={(date: Date) => moment(date).format('DD.MM.YYYY')}
              parseDate={(str: string) => moment(str, 'DD.MM.YYYY').toDate()}
              placeholder={"DD.MM.YYYY"}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Revision Version</label>
          <div className="col-sm-8">
            <input
              value={formulation.revisionVersion}
              autoComplete="off"
              onChange={e => {
                let num = parseInt(e.target.value, 10);
                if (Number.isNaN(num)) {
                  num = 1;
                }
                updateEditingFormulation('revisionVersion', num);
              }}
              disabled={readOnly}
              type="text"
              className={Classes.INPUT}
              name="revisionVersion"
            />
          </div>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>No.</th>
              <th>RM Code</th>
              <th>Description</th>
              <th>Quantity (%)</th>
              <th>Note</th>
              {!readOnly && (
                <th></th>
              )}
            </tr>
          </thead>
          <tbody>
            {formulation.rawmaterialentries.map((entry, idx) => (
              <tr key={`tbl-${idx}`}>
                <td>{idx + 1}</td>
                {entry.rawmaterial && (
                  <>
                    <td>{entry.rawmaterial.code}</td>
                    <td>{entry.rawmaterial.group}</td>
                  </>
                ) || entry.rawmaterialmix && (
                  <>
                    <td>{entry.rawmaterialmix.code}</td>
                    <td>{entry.rawmaterialmix.group}</td>
                  </>
                )}
                <td align="right">{this.ppmToPercentage(entry.ppm)}</td>
                <td>
                  {entry.note}
                </td>
                {!readOnly && (
                  <td align="right">
                    <a href="#">
                      <i className="material-icons" onClick={() => this.editEntry(entry)}>edit</i>
                    </a>
                    <a href="#">
                      <i className="material-icons" onClick={() => this.props.removeRawMaterialEntryEditing(entry)}>delete</i>
                    </a>
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td colSpan={3}>
                <i>Total quantity</i>
              </td>
              <td align="right">
                <i>{this.showTotalQuantity()}</i>
              </td>
              <td colSpan={readOnly ? 1 : 2}></td>
            </tr>
            {!readOnly && (
              <tr>
                <td colSpan={6}>
                  <a href="#" onClick={() => this.openNewEntry()}>
                    <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>add_circle</i>
                    Add entry
                  </a>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <h6>In-process inspection</h6>
        <FormulationQualitySpecificationTable 
          onAddEntry={() => this.props.addNewQualitySpec(true)}
          onRemoveEntry={(indexes) => this.props.removeQualitySpecs(true, indexes)}
          onUpdateEntry={(idx: number, data: FormulationQualitySpecification) => this.props.updateQualitySpec(true, idx, data)}
          entries={formulation.inprocessspecs}
          readOnly={readOnly}
        />

        <h6 className="mt-2">COA standard parameter</h6>
        <FormulationQualitySpecificationTable 
          onAddEntry={() => this.props.addNewQualitySpec(false)}
          onRemoveEntry={(indexes) => this.props.removeQualitySpecs(false, indexes)}
          onUpdateEntry={(idx: number, data: FormulationQualitySpecification) => this.props.updateQualitySpec(false, idx, data)}
          entries={formulation.formulationqualityspecifications}
          readOnly={readOnly}
        />

        <FormulationEntryDialog 
          isOpen={dialogEntryIsOpen} 
          rawMaterialOptions={rawMaterialOptions}
          onDismiss={() => this.setState({dialogEntryIsOpen: false}) as any}
          onSave={e => this.addOrUpdateEntry(e) as any}
          editingEntry={editingEntry}
        />
      </>
    );
  }
}

const mapStateToProps = ({ articles }: any) => ({
  // merge raw materials and mixes
  rawMaterialOptions: concat(
    articles.rawmaterials.map((m: RawMaterial) => ({
      id: m.id,
      code: m.code,
      group: m.group,
      type: 'rawmaterial'
    } as RawMaterialOption)),
    articles.rawmaterialmixes.map((m: RawMaterialMix) => ({
      id: m.id,
      code: m.code,
      group: m.group,
      type: 'rawmaterialmix'
    } as RawMaterialOption))
  ).sort((a, b) => a.code.localeCompare(b.code)),
})

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      removeRawMaterialEntryEditing, addOrUpdateRawMaterialEntry, addNewQualitySpec, removeQualitySpecs, updateQualitySpec, updateEditingFormulation
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormulationDataSheet)

export default withRouter(pageRedux as any);
