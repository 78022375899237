import React, {useCallback} from 'react'
import Dropzone, { IFileWithMeta, IUploadParams } from 'react-dropzone-uploader'
import { REMOTE_API_HOST, authHeader } from '../../../lib/rpc';

interface Props {
  onFileUploaded: (fileId: number | null, fileName: string | null) => void;
  currentFile?: string | null;
  accept?: string;
}

export default function FileDropzone({ onFileUploaded, currentFile, accept }: Props) {
  const getUploadParams = ({file}: IFileWithMeta) => {
    const body = new FormData()
    body.append('files', file)
  
    return { 
      url:  REMOTE_API_HOST + '/upload',
      headers: authHeader(),
      method: 'POST',
      body
    } as IUploadParams;
  }

  const handleChangeStatus = (info: any, status: any) => {
    if (status === 'done') {
      const resp = JSON.parse(info.xhr.response)
      const { name } = info.meta;
      const fileId = resp[0].id;
      onFileUploaded(fileId, name);
    } else if (status === 'aborted') {
    }
  }

  if (currentFile) {
    return (
      <span>
        <a href="#" onClick={() => onFileUploaded(null, null)}>
          <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>delete</i>
        </a>
        {currentFile}
      </span>
    );
  } else {
    return (
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        accept={accept}
        inputContent="Drop a file"
        styles={{
          dropzone: { width: 300, height: 120 },
          dropzoneActive: { borderColor: 'green' },
        }}
      />
    )
  }
}
