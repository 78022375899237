import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { AgGridReact, AgGridColumn, AgGridColumnProps } from 'ag-grid-react';

import { FormulationQualitySpecification } from '../../../types';

interface Props {
  onAddEntry: () => void;
  entries: FormulationQualitySpecification[];
  onRemoveEntry: (indexes: number[]) => void;
  onUpdateEntry: (idx: number, data: FormulationQualitySpecification) => void;
  readOnly?: boolean;
}

interface States {
}

class FormulationQualitySpecificationTable extends PureComponent<Props, States> {
  private grid: any = null;

  render() {
    const props = this.props;

    const columns: AgGridColumnProps[] = [{
      headerName: "Parameter", 
      field: "parameter", 
      resizable: true,
      editable: !props.readOnly,
      checkboxSelection: !props.readOnly,
    }, {
      headerName: "Equipment/Condition",
      field: "method",
      resizable: true,
      editable: !props.readOnly,
    }, {
      headerName: "Min", 
      field: "valueMin", 
      width: 100,
      resizable: true,    
      editable: !props.readOnly,
    }, {
      headerName: "Max", 
      field: "valueMax", 
      width: 100,
      resizable: true,    
      editable: !props.readOnly,
    }, {
      headerName: "Unit",
      field: "valueUnit",
      width: 80,
      resizable: true,    
      editable: !props.readOnly,
    }, {
      headerName: "Note",
      field: "Note",
      resizable: true,    
      editable: !props.readOnly,
    }];

    return (
      <>
        <div 
          className="ag-theme-balham"
        >
          <AgGridReact
            rowSelection={props.readOnly ? "none" : "multiple"}
            domLayout="autoHeight"
            columnDefs={columns}
            rowData={props.entries}
            onGridReady={({api}) => this.grid = api}
            onCellValueChanged={({node}) => props.onUpdateEntry(node.childIndex, node.data)}
          />
        </div>
        {!props.readOnly && (
          <div className="grid-toolbox mb-2">
            <a href="#" onClick={e => {
              e.preventDefault();
              props.onAddEntry();
            }}>
              <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>add_circle</i>
              Add entry
            </a>
            <span className="ml-2 mr-2">|</span>
            <a href="#" onClick={e => {
              e.preventDefault();
              props.onRemoveEntry(this.grid.getSelectedNodes().map((n: any) => n.childIndex));
            }}>
              <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>remove_circle</i>
              Remove selected
            </a>
          </div>
        )}
      </>
    );
  }
}

export default FormulationQualitySpecificationTable;
