import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { hasPermission } from '../../../lib/auth/permission';
import { Formulation } from '../../../types';
import FormulationsGrid from '../../components/molecules/FormulationsGrid';

interface Props {
  formulations: Formulation[]
  createNewFormulas: boolean
  history: any
}
interface States {
}

class FormulationsPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      formulations,
      createNewFormulas,
    } = this.props;
    const {
    } = this.state;

    return (
      <>
        <div className="container-fluid">
          <h4 className="mt-2">
            Formulations
            {createNewFormulas && (
              <small className="ml-2">
                <Link to={`/formulation-creator`}>
                  <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>add_circle</i>
                  Create formulation
                </Link>
              </small>
            )}
          </h4>
          <FormulationsGrid
            entries={formulations}
            onClick={data => {
              this.props.history.push('/formulation/' + data.id);
            }}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({user, formulations} : any, ownProps: any) => {
  return {
    formulations: formulations.formulations.filter((f: Formulation) => f.historyFor === 0),
    createNewFormulas: hasPermission(user.activeRole, 'create_new_formulas'),
  };
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormulationsPage)

export default withRouter(pageRedux);
