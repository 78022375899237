import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";

import { hasPermission } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Document, AdinoProduct, User, Activity, RawMaterialMix } from '../../../types';
import { loadActivities } from '../../../redux/activities';
import UsersGrid from '../../components/molecules/UsersGrid';
import ActivitiesGrid from '../../components/molecules/ActivitiesGrid';
import RawmatmixesGrid from '../../components/molecules/RawmatmixesGrid';

interface Props {
  rawmatmixes: RawMaterialMix[]
  createNewFormulas: boolean
  history: any
}
interface States {
}

class RawmatmixesPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    const {
      rawmatmixes,
      createNewFormulas,
      history,
    } = this.props;
    const {
    } = this.state;

    return (
      <>
        <div className="container-fluid">
          <h4 className="mt-2">
            Raw material mixes
            {createNewFormulas && (
              <small className="ml-2">
                <Link to={`/rawmatmix-creator`}>
                  <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>add_circle</i>
                  Add mix
                </Link>
              </small>
            )}
          </h4>
          <RawmatmixesGrid
            entries={rawmatmixes}
            onClick={(e: RawMaterialMix) => history.push(`/rawmatmixes/${e.id}`)}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({articles, user} : any, ownProps: any) => {
  return {
    rawmatmixes: articles.rawmaterialmixes,
    createNewFormulas: hasPermission(user.activeRole, 'create_new_formulas'),
  };
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(RawmatmixesPage)

export default withRouter(pageRedux);
