import React, { Component } from 'react'
import Header from '../components/molecules/Header'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Route, withRouter } from 'react-router-dom'
import Home from '../pages/home'
import About from '../pages/about'
import Login from '../pages/login'
import Article from '../pages/article'
import EditArticle from '../pages/article/edit'
import EditProduct from '../pages/product/edit'
import ArticleCreator from '../pages/article-creator'
import UserCreator from '../pages/user-creator'
import Users from '../pages/users'
import UserDetails from '../pages/users/details'
import ChangePw from '../pages/users/changepw'
import Documents from '../pages/documents'
import DocumentDetails from '../pages/documents/details'
import Formulations from '../pages/formulation'
import Formulation from '../pages/formulation/view'
import EditFormulation from '../pages/formulation/edit'
import QrCode from '../pages/qrcode'
import Activities from '../pages/activities'
import Rawmatmixes from '../pages/raw-materials/rawmatmixes'
import EditRawmatmix from '../pages/raw-materials/edit-rawmatmix'
import Rawmats from '../pages/raw-materials/rawmats'
import EditRawmat from '../pages/raw-materials/edit-rawmat'
import ProductionProtocols from '../pages/production-protocols'
import EditProductionProtocol from '../pages/production-protocols/edit'
import { loadArticlesData, loadDocuments } from '../../redux/articles'
import { loadUserData, logout, setAjaxLoading } from '../../redux/user'
import { authHeader } from '../../lib/rpc'
import ReduxToastr, { toastr } from 'react-redux-toastr'
import { isInactive } from '../../redux/autoLogoutMiddleware'
import LoadingOverlay from '../components/molecules/LoadingOverlay'

const EditFormulationUpdate = (props) => (
  <EditFormulation
    updateExisting={true}
    {...props} />
);

const ViewRawmatmix = (props) => (
  <EditRawmatmix
    readOnly={true}
    {...props} />
);

const EditRawmatmixUpdate = (props) => (
  <EditRawmatmix
    updateExisting={true}
    {...props} />
);

const ViewRawmat = (props) => (
  <EditRawmat
    readOnly={true}
    {...props} />
);

const EditRawmatUpdate = (props) => (
  <EditRawmat
    updateExisting={true}
    {...props} />
);

class App extends Component {
  componentDidMount() {    
    if (authHeader().Authorization) {
      const now = new Date().getTime();
      if (isInactive(now)) {
        this.props.logout();
        setTimeout(() => {
          toastr.warning('Auto logout', 'You have been logged out due to inactivity');
          this.props.history.push("/login");  
        }, 80);
      } else {
        this.props.loadUserData()
        .catch(e => {
          console.error(e);
          if (e.message) {
            if (e.message.indexOf('Forbidden') > -1 || e.message.indexOf("Invalid token") > -1) {
              this.props.logout();
              this.props.history.push("/login");  
            }
          }
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loggedIn && this.props.loggedIn) {
      this.props.setAjaxLoading(true);

      Promise.all([
        this.props.loadArticlesData(this.props.role),
        this.props.loadDocuments()
      ])
      .then(() => this.props.setAjaxLoading(false))
      .catch(e => {
        this.props.setAjaxLoading(false);
        console.error(e);
      });
    }
    if (!prevProps.isInactive && this.props.isInactive) {
      setTimeout(() => {
        toastr.warning('Auto logout', 'You have been logged out due to inactivity');
        this.props.logout();
        this.props.history.push("/login");
      }, 80);
    }
  }

  render() {
    return (
      <>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          getState={(state) => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          closeOnToastrClick/>
        {this.props.ajaxLoading && <LoadingOverlay />}
        {this.props.loggedIn && <Header />}
        <main>
          <Route exact path="/" component={Home} />
          <Route exact path="/qr/:code" component={QrCode} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/docs" component={Documents} />
          <Route exact path="/docs/:id" component={DocumentDetails} />
          <Route exact path="/about-us" component={About} />
          <Route exact path="/article-creator" component={ArticleCreator} />
          <Route exact path="/user/changePw" component={ChangePw} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/users/:id" component={UserDetails} />
          <Route exact path="/user-creator" component={UserCreator} />
          <Route exact path="/article/:id" component={Article} />
          <Route exact path="/article/:id/update" component={EditArticle} />
          <Route exact path="/product/:id/update" component={EditProduct} />
          <Route exact path="/formulation/:id" component={Formulation} />
          <Route exact path="/formulation-creator/:articleId?" component={EditFormulation} />
          <Route exact path="/formulation/:id/update" component={EditFormulationUpdate}  />
          <Route exact path="/formulations" component={Formulations}  />
          <Route exact path="/activities" component={Activities} />
          <Route exact path="/rawmatmixes" component={Rawmatmixes} />
          <Route exact path="/rawmatmix-creator" component={EditRawmatmix} />
          <Route exact path="/rawmatmixes/:id" component={ViewRawmatmix} />
          <Route exact path="/rawmatmixes/:id/update" component={EditRawmatmixUpdate} />
          <Route exact path="/rawmats" component={Rawmats} />
          <Route exact path="/rawmat-creator" component={EditRawmat} />
          <Route exact path="/rawmats/:id" component={ViewRawmat} />
          <Route exact path="/rawmats/:id/update" component={EditRawmatUpdate} />
          <Route exact path="/production-protocols" component={ProductionProtocols} />
          <Route exact path="/production-protocol-creator" component={EditProductionProtocol} />
        </main>
      </>
    )
  }
}

const mapStateToProps = ({user}) => ({
  loggedIn: user.user !== null,
  isInactive: user.isInactive,
  role: user.activeRole,
  ajaxLoading: user.ajaxLoading,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadArticlesData,
      loadDocuments,
      loadUserData,
      logout,
      setAjaxLoading,
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App))
