import React from 'react';
import { Select, ItemRenderer, ItemPredicate } from "@blueprintjs/select";

import { ProductGroup } from '../../../types'
import { MenuItem, Button } from '@blueprintjs/core';
import { highlightTextForQuery } from '../../../helper/highlight-text';

interface Props {
  items: ProductGroup[], 
  selectedItem?: ProductGroup,
  onItemSelect: (v: ProductGroup) => void,
}

const TProductGroupSelect = Select.ofType<ProductGroup>();

const renderProductGroup: ItemRenderer<ProductGroup> = (ProductGroup, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
      return null;
  }
  const text = `${ProductGroup.name}`;
  return (
      <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={ProductGroup.id}
          onClick={handleClick}
          text={highlightTextForQuery(text, query)}
      />
  );
};

const filterProductGroup: ItemPredicate<ProductGroup> = (query, ProductGroup, _index, exactMatch) => {
  const normalizedTitle = ProductGroup.name.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
      return normalizedTitle === normalizedQuery;
  } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
  }
};

export default function ProductGroupSelect({items, selectedItem, onItemSelect}: Props) {
  const selectedTxt = selectedItem ? selectedItem.name : '(nothing selected)';
  return (
    <TProductGroupSelect
      className="bp3-fill"
      itemRenderer={renderProductGroup}
      itemPredicate={filterProductGroup}
      items={items}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={onItemSelect}
    >
      <Button className="bp3-fill" text={selectedTxt} rightIcon="double-caret-vertical" />
    </TProductGroupSelect>
  );
}
