import { indexOf } from 'lodash';

import { PermissionRole, Permission } from '../../types';

const PERMS_MANAGEMENT : Permission[] = [
  'read_article_details', 
  'create_new_articles',
  'change_role',
  'edit_article',
  'edit_product',
  'create_new_formulas',
  'read_production_protocols',
  'create_production_protocols',
  'create_rawmats',
];

const PERMS_ADMINISTRATION : Permission[] = [
  'add_product_documents',
  'delete_documents',
  'show_visible_to',
];

const permMatrix : { [key: string]: Permission[]; } = {
  'director': [
    'create_new_users',
    'read_activities',
    'delete_article',
    ...PERMS_MANAGEMENT,
    ...PERMS_ADMINISTRATION
  ],
  'management': [
    ...PERMS_MANAGEMENT,
    ...PERMS_ADMINISTRATION
  ],
  'administration': [
    ...PERMS_ADMINISTRATION
  ],
  'sales': [],
  'customer': [],
};

export const requiredRoleForFields = {
  'article.code' : 'customer' as PermissionRole,
  'article.trialProduct': 'administration' as PermissionRole,
  'article.remark': 'administration' as PermissionRole,
  'article.vendor': 'administration' as PermissionRole,
  'article.vendorCode': 'administration' as PermissionRole,
  'article.packaging': 'customer' as PermissionRole,
  'article.ownFormulation': 'administration' as PermissionRole,
  'article.requiredRole': 'administration' as PermissionRole,
  'article.barcodeNumber': 'administration' as PermissionRole,
  'article.label': 'administration' as PermissionRole,
  'article.image': 'administration' as PermissionRole,
  'article.cost': 'director' as PermissionRole,
  'adinoproduct.name' : 'customer' as PermissionRole,
  'adinoproduct.description': 'customer' as PermissionRole,
  'adinoproduct.applicationcategories': 'customer' as PermissionRole,
  'adinoproduct.productgroup': 'administration' as PermissionRole,
  'adinoproduct.remark': 'administration' as PermissionRole,
  'adinoproduct.txtApplications': 'customer' as PermissionRole,
  'adinoproduct.txtCharacteristics': 'customer' as PermissionRole,
};

export function hasPermission(role: PermissionRole, permission: Permission) {
  return indexOf(permMatrix[role], permission) > -1;
}

export function hasRoleOrMore(userRole: PermissionRole, targetRole: PermissionRole) {
  const order = ['customer', 'sales', 'administration', 'management', 'director'];
  return indexOf(order, userRole) >= indexOf(order, targetRole);
}
