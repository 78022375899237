import React from 'react';
import { Select, ItemRenderer, ItemPredicate } from "@blueprintjs/select";

import { AdinoProduct } from '../../../types'
import { MenuItem, Button } from '@blueprintjs/core';
import { highlightTextForQuery } from '../../../helper/highlight-text';

interface Props {
  items: AdinoProduct[], 
  selectedItem?: AdinoProduct,
  onItemSelect: (v: AdinoProduct) => void,
  allowGlobal?: boolean
}

const TAdinoProductSelect = Select.ofType<AdinoProduct>();

const renderAdinoProduct: ItemRenderer<AdinoProduct> = (AdinoProduct, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
      return null;
  }
  const text = `${AdinoProduct.name}`;
  return (
      <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={AdinoProduct.id}
          onClick={handleClick}
          text={highlightTextForQuery(text, query)}
      />
  );
};

const filterAdinoProduct: ItemPredicate<AdinoProduct> = (query, AdinoProduct, _index, exactMatch) => {
  const normalizedTitle = AdinoProduct.name.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
      return normalizedTitle === normalizedQuery;
  } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
  }
};

export default function AdinoProductSelect({items, selectedItem, onItemSelect, allowGlobal}: Props) {
  let allItems = [...items];
  if (allowGlobal) {
    allItems = [{
      id: -1,
      name: '(Global document)',
      applicationcategories: [],
      description: '',
      productdocuments: [],
      txtApplications: '',
      txtCharacteristics: '',
      productgroup: {
        id: 0,
        name: ''
      },
      remark: '',
    }, ...items];
  }
  const selectedTxt = selectedItem ? selectedItem.name : '(nothing selected)';
  return (
    <TAdinoProductSelect
      className="bp3-fill"
      itemRenderer={renderAdinoProduct}
      itemPredicate={filterAdinoProduct}
      items={allItems}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={onItemSelect}
    >
      <Button className="bp3-fill" text={selectedTxt} rightIcon="double-caret-vertical" />
    </TAdinoProductSelect>
  );
}
