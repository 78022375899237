import { PermissionRole, User, Activity } from '../types';
import client from '../helper/apollo-client';
import gql from 'graphql-tag';
import $ from 'jquery';
import Cookies from 'js-cookie';

import { REMOTE_API_HOST, authHeader } from '../lib/rpc';

export const LOAD_ACTIVITIES_SUCCESS = 'activities/LOAD_ACTIVITIES_SUCCESS';

const initialState : {
  activities: Activity[],
} = {
  activities: [],
}

export default (state = initialState, action: any) => {
  switch (action.type) {

    case LOAD_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: action.payload.activities
      }

    default:
      return state
  }
}

export const loadActivities = function() {
  return (dispatch: any) => {
    return client.get().query({
      query: gql`
        query IndexQuery {
          activities(sort: "actionDate:desc") {
            action
            user {
              id
              username
              email
            }
            actionDate
            entityType
            entityId
            params
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
      .then(({data}: any) => {
        dispatch({
          type: LOAD_ACTIVITIES_SUCCESS,
          payload: data
        })
      });
  }
}
