import React, { PureComponent } from 'react'

import { Button, MenuItem, Dialog, Classes, NumericInput } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { HistoryOption } from '../../../types';
import moment from 'moment';

export interface Props {
  isOpen: boolean,
  historyOptions: HistoryOption[],
  onDismiss: () => {},
  onSelect: (e: HistoryOption) => {},
}

interface States {
}

class HistorySelectionDialog extends PureComponent<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      isOpen,
      historyOptions,
      onDismiss,
      onSelect,
    } = this.props;

    const {
    } = this.state;
    
    const filterHistory = (query: any, entry: HistoryOption) => {
      return moment(entry.revisionDate).format('YYYY-MM-DD').indexOf(query.toLowerCase()) >= 0 ||
              ('' + entry.revisionVersion).indexOf(query.toLowerCase()) >= 0
    }

    const renderHistory = (entry: HistoryOption, { handleClick, modifiers }: any) => {
      if (!modifiers.matchesPredicate) {
        return null
      }
      return (
        <MenuItem
          active={modifiers.active}
          key={`${entry.id}`}
          onClick={handleClick}
          text={`${moment(entry.revisionDate).format('YYYY-MM-DD')} (${entry.revisionVersion})`}
        />
      )
    }

    return (
      <Dialog isOpen={isOpen}>
        <div className={Classes.DIALOG_BODY}>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Select revision</label>
            <div className="col-sm-8">
              <Select
                className="bp3-fill"
                items={historyOptions}
                itemPredicate={filterHistory}
                itemRenderer={renderHistory}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={(e: HistoryOption) => onSelect(e)}>
                <Button className="bp3-fill" text={'(nothing selected)'} rightIcon="double-caret-vertical" />
              </Select>
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => onDismiss()}>Close</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default HistorySelectionDialog;
