import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";
import { Formik } from 'formik'

import { hasPermission } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import FormulationDataSheet from '../../components/organisms/FormulationDataSheet';
import { Formulation } from '../../../types';
import { login, forgotPassword } from '../../../redux/user';

interface Props {
  login: (u: string, p: string) => Promise<void>;
  forgotPassword: (m: string) => Promise<void>;
  history: any;
}
interface States {
}

class LoginPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  onSubmit(values: any) {
    const {
      login, history
    } = this.props;
    login(values.email, values.pass)
      .then(() => {
        // redirect /home
        history.push('/');
      })
      .catch((e) => {
        alert('Login failed.');
      });
  }

  forgotPassword(e: React.MouseEvent) {
    e.preventDefault();
    const mail = prompt('Do you want to reset your password? Please enter your e-mail address.');
    if (mail) {
      this.props.forgotPassword(mail);
      alert('You will receive a mail with a link to reset your password.');
    }
  }

  render() {
    const {
    } = this.props;

    return (
      <>
        <div className="landing-background">
          <div className="row">
            <div className="d-lg-none">
              <img src="/adino-bogen.png" className="img-fluid" />
            </div>
            <div className="col-md-12 d-lg-block qr-logo-large-container" style={{display:'none'}}>
              <img src="/adino-bogen.png" className="qr-logo-large" />
            </div>
          </div>
          <div className="row justify-content-sm-center">
            <div className="login-container col-sm-6 col-md-4 mb-2">
                <Formik
                  initialValues={{
                    email: '',
                    pass: ''
                  }}
                  onSubmit={v => this.onSubmit(v)}>
                  {formik => (
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group">
                        <input 
                          type="email" 
                          className="form-control" 
                          placeholder="E-Mail" 
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />
                      </div>
                      <div className="form-group">
                        <input 
                          type="password" 
                          className="form-control" 
                          placeholder="Password" 
                          name="pass"
                          onChange={formik.handleChange}
                          value={formik.values.pass}
                        />
                      </div>
                      <p>
                        <a href="#" onClick={(e) => this.forgotPassword(e)}>Forgot your password?</a>
                      </p>
                      <button type="submit" className="btn btn-dark btn-block">Login</button>
                      <div className="text-right mt-4">
                        <a href="http://www.adinoklebstoffe.de/" style={{color:'black'}}>EXIT</a>
                      </div>
                    </form>
                  )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({} : any, ownProps: any) => ({
})

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      login, forgotPassword
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage as any)

export default withRouter(pageRedux);
