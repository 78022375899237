import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";

import { hasPermission } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import FormulationDataSheet from '../../components/organisms/FormulationDataSheet';
import { Formulation, User, RawMaterialMix, RawMaterialEntry, PermissionRole } from '../../../types';
import { validateRawmatmix } from '../../../lib/validate';
import showFormErrors from '../../../helper/show-form-errors';
import { loadArticlesData } from '../../../redux/articles';
import RawmatmixDataSheet from '../../components/organisms/RawmatmixDataSheet';
import { initNewRawmatmix, createOrUpdateRawmatmixComplete, deleteRawmatmixComplete } from '../../../redux/formulations';

interface Props {
  editingRawmatmix: RawMaterialMix
  existingRawmatmix?: RawMaterialMix
  article: any
  user: User
  role: PermissionRole
  readOnly?: boolean
  usedBy: {
    usedMixes: RawMaterialMix[],
    usedFormulas: Formulation[],
  },
  deleteRawmatmixComplete: (f: RawMaterialMix) => void
  initNewRawmatmix: (f?: RawMaterialMix) => void
  createOrUpdateRawmatmixComplete: (f: RawMaterialMix) => Promise<number>
  loadArticlesData: (role: PermissionRole, othersQueries?: string[]) => Promise<void>
  history: any
}
interface States {
  sending: boolean
}

class EditRawmatmix extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sending: false,
    };
  }

  componentDidMount() {
    this.props.initNewRawmatmix(this.props.existingRawmatmix && {...this.props.existingRawmatmix});
  }

  async create() {
    const {
      history, editingRawmatmix, createOrUpdateRawmatmixComplete, loadArticlesData, role
    } = this.props;

    const formErrors = validateRawmatmix(editingRawmatmix);
    if (formErrors.length > 0) {
      showFormErrors(formErrors);
      return;
    }

    this.setState({
      sending: true,
    });
    try {
      await createOrUpdateRawmatmixComplete(editingRawmatmix);
      await loadArticlesData(role);
      this.setState({
        sending: false,
      }, () => {
        history.push(`/rawmatmixes`);
      });
    } catch (err) {
      console.error(err);
      alert('Error: '+JSON.stringify(err));
    }
  }

  async edit(e: Event) {
    e.preventDefault();

    const {
      usedBy,
      history,
      editingRawmatmix,
    } = this.props;

    if (usedBy.usedFormulas.length > 0) {
      if (!confirm(`This mix is used by ${usedBy.usedFormulas.length} formulations. Continue?`)) {
        return;
      }
    }
    if (usedBy.usedMixes.length > 0) {
      if (!confirm(`This mix is used by ${usedBy.usedMixes.length} other mixes. Continue?`)) {
        return;
      }
    }
    history.push(`/rawmatmixes/${editingRawmatmix.id}/update`);
  }

  async remove(e: Event) {
    e.preventDefault();

    const {
      usedBy,
      history,
      editingRawmatmix,
      deleteRawmatmixComplete,
      loadArticlesData,
      role,
    } = this.props;

    if (usedBy.usedFormulas.length > 0) {
      alert(`This mix is used by ${usedBy.usedFormulas.length} formulations and so it can not be removed.`);
      return;
    }
    if (usedBy.usedMixes.length > 0) {
      alert(`This mix is used by ${usedBy.usedMixes.length} other mixes and so it can not be removed.`);
      return;
    }
    if (confirm('Are you sure to remove this mix?')) {
      await deleteRawmatmixComplete(editingRawmatmix);
      await loadArticlesData(role);
      history.push(`/rawmatmixes`);
    }
  }

  render() {
    const {
      readOnly, existingRawmatmix
    } = this.props;
    const {
      sending,
    } = this.state;

    const RawmatmixDataSheetAny = RawmatmixDataSheet as any;

    return (
      <>
        <div className="container mt-2">
          <h4>
            Raw material mix
            {readOnly && (
              <>
                <small className="ml-2">
                  <a href="#" onClick={(e) => this.edit(e as any)}>[ Edit ]</a>
                </small>
                <small className="ml-2">
                  <a href="#" onClick={(e) => this.remove(e as any)}>[ Remove ]</a>
                </small>
              </>
            )}
          </h4>
          <RawmatmixDataSheetAny 
            readOnly={readOnly}
           />
          {!readOnly && (
            <>
              {existingRawmatmix && (
                <button 
                  className="btn btn-primary mt-2" 
                  disabled={sending}
                  onClick={() => this.create()}>Update raw material mix</button>
              ) || (
                <button 
                  className="btn btn-primary mt-2" 
                  disabled={sending}
                  onClick={() => this.create()}>Create raw material mix</button>
              )}
            </>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ articleCreator, articles, user, formulations } : any, ownProps: any) => {
  let existingRawmatmix: RawMaterialMix | null = null;
  const usedBy : {
    usedMixes: RawMaterialMix[],
    usedFormulas: Formulation[],
  } = {
    usedMixes: [],
    usedFormulas: [],
  };
  if (ownProps.match.params.id) {
    existingRawmatmix = formulations.rawmatmixes.filter((f: RawMaterialMix) => f.id.toString() === ownProps.match.params.id)[0];
    const usedMixes = formulations.rawmatmixes.filter((e: RawMaterialMix) => {
      return e.rawmaterialentries.filter((e2: RawMaterialEntry) => {
        if (e2.rawmaterialmix) {
          return e2.rawmaterialmix.id === existingRawmatmix!.id;
        }
        return false;
      }).length > 0
    });
    const usedFormulas = formulations.formulations.filter((e: Formulation) => {
      return e.rawmaterialentries.filter((e2: RawMaterialEntry) => {
        if (e2.rawmaterialmix) {
          return e2.rawmaterialmix.id === existingRawmatmix!.id;
        }
        return false;
      }).length > 0
    });
    usedBy.usedMixes = usedMixes;
    usedBy.usedFormulas = usedFormulas;
  }
  return {
    editingRawmatmix: formulations.editingRawmatmix,
    existingRawmatmix,
    role: user.activeRole,
    usedBy,
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      initNewRawmatmix, createOrUpdateRawmatmixComplete, loadArticlesData, deleteRawmatmixComplete
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRawmatmix as any)

export default withRouter(pageRedux);
