import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { hasPermission } from '../../../lib/auth/permission';
import { loadArticlesData, loadDocuments } from "../../../redux/articles";
import { createDocument } from '../../../redux/documents';
import { initNewProductionProtocol, loadProductionProtocols } from '../../../redux/productionProtocols';
import { Formulation, ProductionProtocol } from '../../../types';
import SimpleSelectionDialog from '../../components/molecules/SimpleSelectionDialog';
import CreateProductionProtocolDialog from '../../components/molecules/CreateProductionProtocolDialog';
import ProductionProtocolsGrid from '../../components/molecules/ProductionProtocolsGrid';
import { REMOTE_API_HOST } from '../../../lib/rpc';


interface Props {
  canCreate: boolean
  formulations: Formulation[]
  productionProtocols: ProductionProtocol[]
  history: any
  initNewProductionProtocol: (formulation: Formulation, batchSize: number, code: string) => void
  loadProductionProtocols: () => void;
}

interface States {
  dialogSelectIsOpen: boolean
}

class ProductionProtocolsPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dialogSelectIsOpen: false,
    };
  }

  componentDidMount() {
    this.props.loadProductionProtocols();
  }

  openNewEntry = () => {
    this.setState({
      dialogSelectIsOpen: true,
    });
  }

  createNew = (formulation: Formulation, batchSize: number, code: string) => {
    this.props.initNewProductionProtocol(formulation, batchSize, code);
    this.props.history.push(`/production-protocol-creator`);
  }

  render() {
    const {
      canCreate,
      formulations,
      history,
      productionProtocols,
    } = this.props;
    const {
      dialogSelectIsOpen,
    } = this.state;

    return (
      <>
        <div className="container-fluid">
          <Link to={`/`}>
            <small className="">&lt;&lt; back</small>
          </Link>
          <h4 className="mt-2">
            Production protocols
            {canCreate && (
              <small className="ml-2">
                <a href="#" onClick={() => this.openNewEntry()}>
                  <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>add_circle</i>
                  Create new production protocol
                </a>
              </small>
            )}
          </h4>
          <ProductionProtocolsGrid
            entries={productionProtocols}
            onClick={(e: ProductionProtocol) => window.open(`${REMOTE_API_HOST}/docs/${e.doc!.id}/download`)}
          />
          <CreateProductionProtocolDialog
            isOpen={dialogSelectIsOpen} 
            onDismiss={() => this.setState({dialogSelectIsOpen: false}) as any}
            onSave={(f: Formulation, size: number, code: string) => this.createNew(f, size, code)}
            formulations={formulations}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({user, articles, formulations, productionProtocols} : any, ownProps: any) => {
  return {
    canCreate: user.user && hasPermission(user.activeRole, 'create_production_protocols'),
    formulations: formulations.formulations.filter((f: Formulation) => f.historyFor === 0),
    productionProtocols: productionProtocols.productionProtocols,
  };
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      createDocument, loadArticlesData, loadDocuments, initNewProductionProtocol, loadProductionProtocols
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionProtocolsPage)

export default withRouter(pageRedux);
