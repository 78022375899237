import { combineReducers } from 'redux'
import counter from './counter'
import articles from './articles'
import articleDetails from './articleDetails'
import articleCreator from './articleCreator'
import user from './user'
import formulations from './formulations'
import documents from './documents'
import activities from './activities'
import productionProtocols from './productionProtocols'
import {reducer as toastrReducer} from 'react-redux-toastr'

export default combineReducers({
  counter, articles, articleDetails, user, articleCreator, formulations, documents, activities, productionProtocols, toastr: toastrReducer
})
