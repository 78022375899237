import { Formulation, RawMaterialMix, RawMaterial } from "../../types";

export function validateFormulation(formulation: Formulation) : {
  field: string
  message: string
}[] {
  const formErrors: {
    field: string
    message: string
  }[] = [];

  if (!formulation.name) {
    formErrors.push({
      field: 'Name',
      message: 'is missing'
    });
  }
  if (!formulation.revisionDate) {
    formErrors.push({
      field: 'Revision date',
      message: 'is missing'
    });
  }
  if (!formulation.revisionVersion) {
    formErrors.push({
      field: 'Revision version',
      message: 'is missing'
    });
  }
  
  // minimum one quality spec
  if (formulation.formulationqualityspecifications.length === 0) {
    formErrors.push({
      field: 'Quality specifications',
      message: 'is missing'
    });
  }
  if (formulation.inprocessspecs.length === 0) {
    formErrors.push({
      field: 'Quality specifications',
      message: 'is missing'
    });
  }

  return formErrors;
}

export function validateArticle(values: any) : {
  field: string
  message: string
}[] {
  const formErrors: {
    field: string
    message: string
  }[] = [];

  if (!values.articleCode) {
    formErrors.push({
      field: 'Article code',
      message: 'is missing'
    });
  } else if (values.articleCode.indexOf(' ') > -1) {
    formErrors.push({
      field: 'Article code',
      message: 'must not contain spaces'
    });
  }
  if (!values.packaging) {
    formErrors.push({
      field: 'Packaging',
      message: 'is missing'
    });
  }
  if (!values.vendor) {
    formErrors.push({
      field: 'Vendor',
      message: 'is missing'
    });
  }
  if (!values.vendorCode) {
    formErrors.push({
      field: 'Vendor code',
      message: 'is missing'
    });
  }
  // TODO re-activate later
  // if (!values.labelFileName) {
  //   formErrors.push({
  //     field: 'Label file',
  //     message: 'is missing'
  //   });
  // }

  return formErrors;
}

export function validateProduct(values: any) : {
  field: string
  message: string
}[] {
  const formErrors: {
    field: string
    message: string
  }[] = [];

  if (values.createNewProduct) {
    if (!values.productName) {
      formErrors.push({
        field: 'Name',
        message: 'is missing'
      });
    }
    if (!values.productDescription) {
      formErrors.push({
        field: 'Description',
        message: 'is missing'
      });
    }
    if (!values.productGroup) {
      formErrors.push({
        field: 'Product group',
        message: 'is missing'
      });
    }
    if (!values.applicationCategories || values.applicationCategories.length === 0) {
      formErrors.push({
        field: 'Application categories',
        message: 'is missing'
      });
    }
  } else {
    if (!values.adinoProduct) {
      formErrors.push({
        field: 'Product',
        message: 'is missing'
      });
    }
  }

  return formErrors;
}

export function validateRawmatmix(rawmatmix: RawMaterialMix) : {
  field: string
  message: string
}[] {
  const formErrors: {
    field: string
    message: string
  }[] = [];

  if (!rawmatmix.code) {
    formErrors.push({
      field: 'Code',
      message: 'is missing'
    });
  }

  if (!rawmatmix.group) {
    formErrors.push({
      field: 'Group',
      message: 'is missing'
    });
  }

  if (rawmatmix.rawmaterialentries.filter(e => e.rawmaterialmix && e.rawmaterialmix.id === rawmatmix.id).length > 0) {
    formErrors.push({
      field: 'Raw Material Mix',
      message: 'can not contain itself. This produces a recursion.'
    });
  }

  return formErrors;
}

export function validateRawmat(values: RawMaterial) : {
  field: string
  message: string
}[] {
  const formErrors: {
    field: string
    message: string
  }[] = [];

  if (!values.code) {
    formErrors.push({
      field: 'Code',
      message: 'is missing'
    });
  }

  if (!values.group) {
    formErrors.push({
      field: 'Group',
      message: 'is missing'
    });
  }

  return formErrors;
}
