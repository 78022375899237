import { Classes } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import React, { PureComponent } from 'react';
import $ from 'jquery';
import { capitalize } from 'lodash';

import { Document, AdinoProduct, PermissionRole } from '../../../types';
import { DocumentTypes } from "../../../lib/content/statics";
import DocumentDialog from "./DocumentDialog";
import { REMOTE_API_HOST } from "../../../lib/rpc";
import SendDocumentDialog from "../molecules/SendDocumentDialog";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { shareDocument, createDocument, updateDocument } from '../../../redux/documents';
import { loadArticlesData, loadDocuments } from "../../../redux/articles";
import utils from '../../../lib/content/utils';
import { setAjaxLoading } from "../../../redux/user";

export interface Props {
  id: string,
  entries: Document[],
  editModeAddEntry?: (entry: Document) => {},
  editModeUpdateEntry?: (entry: Document, newEntry: Document) => {},
  editModeRemoveEntry?: (entry: Document) => {},
  editMode: boolean,
  showRevision: boolean,
  canAddDocuments: boolean,
  showVisibleTo: boolean,
  adinoProduct?: {
    id: number
    name: string
  }
  role: PermissionRole

  shareDocument: (documentId: number, toName: string, to: string, onSuccess: Function) => {}
  updateDocument: (doc: Document) => {}
  createDocument: (doc: Document) => {}
  loadArticlesData: (role: PermissionRole, othersQueries: string[]) => {}
  loadDocuments: () => {}
  canDeleteDocuments: boolean,
  onDeleteDocument: (e: Event, entry: Document) => void
  setAjaxLoading: (p: boolean) => void
}

interface States {
  dialogEntryIsOpen: boolean,
  editingEntry?: Partial<Document>,
}

class DocumentsTable extends PureComponent<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dialogEntryIsOpen: false,
    };
  }

  editEntry = (entry: Document) => {
    this.setState({
      dialogEntryIsOpen: true,
      editingEntry: entry,
    });
  }

  addOrUpdateEntry = (entry: Document) => {
    if (!this.props.editModeUpdateEntry || !this.props.editModeAddEntry) {
      throw new Error();
    }

    if (this.state.editingEntry && this.state.editingEntry.id) {
      this.props.editModeUpdateEntry(this.state.editingEntry as Document, entry);
    } else {
      // generate ID
      entry.id = new Date().getTime() * -1;
      this.props.editModeAddEntry(entry);
    }
    this.setState({
      dialogEntryIsOpen: false,
      editingEntry: undefined,
    });
  }

  async createOrUpdateDocument(doc: Document) {
    this.props.setAjaxLoading(true);

    if (doc.id) {
      await this.props.updateDocument(doc);
    } else {
      await this.props.createDocument(doc);
    }

    // reload all data
    await Promise.all([
      this.props.loadArticlesData(this.props.role, []),
      this.props.loadDocuments()
    ]);
    this.props.setAjaxLoading(false);

    this.setState({
      dialogEntryIsOpen: false,
    });
  }

  openNewEntry = () => {
    this.setState({
      dialogEntryIsOpen: true,
      editingEntry: {
        adinoproduct: this.props.adinoProduct
      },
    });
  }

  openSendDocumentDialog(e: Event, ds: Document) {
    e.preventDefault();

    this.setState({
      editingEntry: ds
    });
    $('#dialog-send-document-submit').prop('disabled', false);
    ($('#dialog-send-document') as any).modal();
  }

  onSubmitShareDocument(name: string, mail: string) {
    if (!name || !mail) {
      alert('Information missing.');
      return;
    }

    this.props.shareDocument(
      this.state.editingEntry!.id!,
      name,
      mail,
      () => {
        alert('Mail with link was sent.');
        ($('#dialog-send-document') as any).modal('hide');
      }
    );
  }

  openUpdateDocument(e: Event, ds: Document) {
    e.preventDefault();
    this.setState({
      editingEntry: {
        ...ds,
        file: undefined,
        mandatory: true,
        requiredRole: ds.requiredRole,
        revisionVersion: ds.revisionVersion! + 1,
        revisionDate: new Date(),
      },
      dialogEntryIsOpen: true
    });
  }

  renderEntry(entry: Document) {
    const {
      editModeRemoveEntry,
      editMode,
      canAddDocuments,
      canDeleteDocuments,
      onDeleteDocument,
      showRevision,
      showVisibleTo,
    } = this.props;

    return (
      <div className="row" key={`${entry.id}-${entry.type}`}>
                <div className="col-sm-4">
                  <h6>
                    {editMode && (
                      DocumentTypes[entry.type]
                    ) || (
                      <a target="_blank" href={`${REMOTE_API_HOST}/docs/${entry.id}/download`}>{DocumentTypes[entry.type]}</a>
                    )}
                    &nbsp;<span className="badge badge-secondary">{capitalize(entry.language)}</span></h6>
                    {(entry.type !== 'msds' && entry.type !== 'tds') && (
                      <small>{entry.name}</small>
                    )}
                </div>
                <div className="col-sm-8">
                  {editMode && (
                    <>
                      <a href="#">
                        <i className="material-icons" onClick={() => this.editEntry(entry)}>edit</i>
                      </a>
                      {!entry.mandatory && (
                        <a href="#">
                          <i className="material-icons" onClick={() => editModeRemoveEntry!(entry)}>delete</i>
                        </a>
                      )}
                    </>
                  ) || (
                    <>
                      <a
                        href="#"
                        onClick={(e: any) => this.openSendDocumentDialog(e, entry)}>
                        <i className="material-icons" style={{verticalAlign: 'middle', marginRight: 4}}>email</i>
                        Send link
                      </a>
                      {canAddDocuments && (
                        <>
                          <span className="ml-2 mr-2">|</span>
                          <a
                            href="#"
                            onClick={(e: any) => this.openUpdateDocument(e, entry)}>
                            <i className="material-icons" style={{verticalAlign: 'middle', marginRight: 4}}>cloud_upload</i>
                            Upload new revision
                          </a>
                        </>
                      )}
                      {canDeleteDocuments && (
                        <>
                          <span className="ml-2 mr-2">|</span>
                          <a
                            href="#"
                            onClick={(e: any) => onDeleteDocument(e, entry)}
                            className="text-danger">
                            <i className="material-icons" style={{verticalAlign: 'middle', marginRight: 4}}>remove_circle</i>
                            Delete document
                          </a>
                        </>
                      )}
                    </>
                  )}
                  <div>
                    {showVisibleTo && (
                      <div>
                        <span className="text-muted mr-2">Visible to:</span>
                        {capitalize(entry.requiredRole)}
                      </div>
                    )}
                    {showRevision && (
                      <div>
                        <span className="text-muted mr-2">Revision:</span>
                        {entry.revisionDate && moment(entry.revisionDate).format('YYYY-MM-DD')} (
                        {entry.revisionVersion})
                      </div>
                    )}
                  </div>
                </div>
              </div>
    );
  }

  render() {
    const {
      id,
      entries,
      editModeRemoveEntry,
      editMode,
      canAddDocuments,
      showRevision,
      showVisibleTo,
    } = this.props;
    const {
      editingEntry,
      dialogEntryIsOpen,
    } = this.state;

    let numCols = 2;
    if (showVisibleTo) numCols++;
    if (showRevision) numCols++;

    if (editMode && !editModeRemoveEntry) {
      throw new Error();
    }

    const entriesDataSheets = entries.filter(e => e.type === 'tds' || e.type === 'msds');
    const entriesOther = entries.filter(e => e.type !== 'tds' && e.type !== 'msds');

    return (
      <>
        <div className="accordion accordion-docs mb-2" id={`accordion-docs-${id}`}>
          <div className="card">
            <div className="card-header" id={`accordion-docs-${id}-heading1`}>
              <h2 className="mb-0">
                <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target={`#accordion-docs-${id}-collapse1`} aria-expanded="false" aria-controls={`accordion-docs-${id}-collapse1`}>
                  Data sheets
                  <span className="material-icons float-right icon-expand" aria-hidden="true">add</span>
                  <span className="material-icons float-right icon-collapse" aria-hidden="true">remove</span>
                </button>
              </h2>
            </div>
            <div id={`accordion-docs-${id}-collapse1`} className="collapse" aria-labelledby={`accordion-docs-${id}-heading1`} data-parent={`#accordion-docs-${id}`}>
              <div className="card-body">
                {entriesDataSheets.map(entry => this.renderEntry(entry))}
                {entriesDataSheets.length === 0 && !editMode && (
                  <div className="text-center">
                    <i>(no documents available yet)</i>
                  </div>
                )}
                {canAddDocuments && (
                  <a href="#" onClick={() => this.openNewEntry()}>
                    <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>add_circle</i>
                    Add document
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id={`accordion-docs-${id}-heading2`}>
              <h2 className="mb-0">
                <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target={`#accordion-docs-${id}-collapse2`} aria-expanded="false" aria-controls={`accordion-docs-${id}-collapse2`}>
                  Other documents
                  <span className="material-icons float-right icon-expand" aria-hidden="true">add</span>
                  <span className="material-icons float-right icon-collapse" aria-hidden="true">remove</span>
                </button>
              </h2>
            </div>
            <div id={`accordion-docs-${id}-collapse2`} className="collapse" aria-labelledby={`accordion-docs-${id}-heading2`} data-parent={`#accordion-docs-${id}`}>
              <div className="card-body">
                {entriesOther.map(entry => this.renderEntry(entry))}
                {entriesOther.length === 0 && !editMode && (
                  <div className="text-center">
                    <i>(no documents available yet)</i>
                  </div>
                )}
                {canAddDocuments && (
                  <a href="#" onClick={() => this.openNewEntry()}>
                    <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>add_circle</i>
                    Add document
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        {!editMode && (
          <SendDocumentDialog 
            id="dialog-send-document" 
            documentName={utils.docs.getName(editingEntry as Document)} 
            onSubmit={(n: string, m: string) => this.onSubmitShareDocument(n, m)}
          />
        )}
        <DocumentDialog
          isOpen={dialogEntryIsOpen} 
          onDismiss={() => this.setState({dialogEntryIsOpen: false}) as any}
          onSave={async (e) => {
            if (editMode) {
              this.addOrUpdateEntry(e);
            } else {
              await this.createOrUpdateDocument(e);
            }
          }}
          editingEntry={editingEntry}
        />
      </>
    );
  }
}


const mapStateToProps = ({ user }: any) => ({
  role: user.activeRole,
})

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      updateDocument, createDocument, shareDocument, loadArticlesData, loadDocuments,
      setAjaxLoading
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsTable)

export default (pageRedux);
