import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";

import { hasPermission } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Document, AdinoProduct, User } from '../../../types';
import DocumentsGrid from '../../components/molecules/DocumentsGrid';
import DocumentDialog from '../../components/organisms/DocumentDialog';
import { createDocument } from '../../../redux/documents';
import { loadArticlesData, loadDocuments } from "../../../redux/articles";
import { loadUsers } from '../../../redux/user';
import UsersGrid from '../../components/molecules/UsersGrid';

interface Props {
  users: User[]
  canAddUsers: boolean
  history: any
  loadUsers: () => void
}
interface States {
}

class UsersPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.props.loadUsers();
  }

  render() {
    const {
      users,
      canAddUsers,
    } = this.props;
    const {
    } = this.state;

    return (
      <>
        <div className="container-fluid">
          <h4 className="mt-2">
            Users
            {canAddUsers && (
              <small className="ml-2">
                <Link to={`/user-creator`}>
                  <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>add_circle</i>
                  Add user
                </Link>
              </small>
            )}
          </h4>
          <UsersGrid
            entries={users}
            onClick={data => {
              this.props.history.push('/users/' + data.id);
            }}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({user, articles} : any, ownProps: any) => {
  return {
    users: user.users,
    canAddUsers: user.user && hasPermission(user.activeRole, 'create_new_users'),
  };
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadUsers
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersPage)

export default withRouter(pageRedux);
