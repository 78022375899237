import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from 'lodash'

import { hasPermission } from '../../../lib/auth/permission'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import FormulationDataSheet from '../../components/organisms/FormulationDataSheet'
import {
  Formulation,
  User,
  RawMaterialMix,
  RawMaterialEntry,
  PermissionRole,
  RawMaterial
} from '../../../types'
import { validateRawmatmix, validateRawmat } from '../../../lib/validate'
import showFormErrors from '../../../helper/show-form-errors'
import { loadArticlesData } from '../../../redux/articles'
import RawmatmixDataSheet from '../../components/organisms/RawmatmixDataSheet'
import {
  initNewRawmatmix,
  createOrUpdateRawmat,
  deleteRawmat
} from '../../../redux/formulations'
import { Formik } from 'formik'

interface Props {
  existingRawmat?: RawMaterial
  user: User
  role: PermissionRole
  readOnly?: boolean
  usedBy: {
    usedMixes: RawMaterialMix[]
    usedFormulas: Formulation[]
  }
  deleteRawmat: (f: RawMaterial) => void
  createOrUpdateRawmat: (f: RawMaterial) => Promise<number>
  loadArticlesData: (
    role: PermissionRole,
    othersQueries?: string[]
  ) => Promise<void>
  history: any
}
interface States {
  sending: boolean
}

class EditRawmat extends Component<Props, States> {
  private formik: any

  constructor(props: Props) {
    super(props)
    this.state = {
      sending: false
    }
  }

  async create(values: RawMaterial) {
    const { history, createOrUpdateRawmat, loadArticlesData, role } = this.props

    const formErrors = validateRawmat(values)
    if (formErrors.length > 0) {
      showFormErrors(formErrors)
      return
    }

    this.setState({
      sending: true
    })
    try {
      await createOrUpdateRawmat(values)
      await loadArticlesData(role)
      this.setState(
        {
          sending: false
        },
        () => {
          history.push(`/rawmats`)
        }
      )
    } catch (err) {
      console.error(err)
      alert('Error: ' + JSON.stringify(err))
    }
  }

  async edit(e: Event) {
    e.preventDefault()

    const { usedBy, history, existingRawmat } = this.props

    if (usedBy.usedFormulas.length > 0) {
      if (
        !confirm(
          `This raw material is used by ${usedBy.usedFormulas.length} formulations. Continue?`
        )
      ) {
        return
      }
    }
    if (usedBy.usedMixes.length > 0) {
      if (
        !confirm(
          `This raw material is used by ${usedBy.usedMixes.length} other mixes. Continue?`
        )
      ) {
        return
      }
    }
    history.push(`/rawmats/${existingRawmat!.id}/update`)
  }

  async remove(e: Event) {
    e.preventDefault()

    const {
      usedBy,
      history,
      existingRawmat,
      deleteRawmat,
      loadArticlesData,
      role
    } = this.props

    if (usedBy.usedFormulas.length > 0) {
      alert(
        `This raw material is used by ${usedBy.usedFormulas.length} formulations and so it can not be removed.`
      )
      return
    }
    if (usedBy.usedMixes.length > 0) {
      alert(
        `This raw material is used by ${usedBy.usedMixes.length} other mixes and so it can not be removed.`
      )
      return
    }
    if (confirm('Are you sure to remove this raw material?')) {
      await deleteRawmat(existingRawmat!)
      await loadArticlesData(role)
      history.push(`/rawmats`)
    }
  }

  render() {
    const { readOnly, existingRawmat } = this.props
    const { sending } = this.state

    return (
      <>
        <div className="container mt-2">
          <h4>
            Raw material
            {readOnly && (
              <>
                <small className="ml-2">
                  <a href="#" onClick={e => this.edit(e as any)}>
                    [ Edit ]
                  </a>
                </small>
                <small className="ml-2">
                  <a href="#" onClick={e => this.remove(e as any)}>
                    [ Remove ]
                  </a>
                </small>
              </>
            )}
          </h4>

          <Formik
            initialValues={{
              id: existingRawmat && existingRawmat.id,
              code: existingRawmat ? existingRawmat.code : '',
              description: existingRawmat ? existingRawmat.description : '',
              group: existingRawmat ? existingRawmat.group : '',
              company: existingRawmat ? existingRawmat.company : '',
              distributor: existingRawmat ? existingRawmat.distributor : '',
            }}
            onSubmit={v => this.create(v)}>
            {formik => {
              this.formik = formik
              return (
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Group
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Group"
                        name="group"
                        disabled={readOnly}
                        onChange={formik.handleChange}
                        value={formik.values.group}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Code
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Code"
                        name="code"
                        disabled={readOnly}
                        onChange={formik.handleChange}
                        value={formik.values.code}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Description
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        name="description"
                        disabled={readOnly}
                        onChange={formik.handleChange}
                        value={formik.values.description}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Company
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company"
                        name="company"
                        disabled={readOnly}
                        onChange={formik.handleChange}
                        value={formik.values.company}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Distributor
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Distributor"
                        name="distributor"
                        disabled={readOnly}
                        onChange={formik.handleChange}
                        value={formik.values.distributor}
                      />
                    </div>
                  </div>

                  {!readOnly && (
                    <button
                      className="btn btn-primary mt-2"
                      disabled={sending}
                      type="submit">
                      {existingRawmat
                        ? 'Update raw material'
                        : 'Create raw material'}
                    </button>
                  )}
                </form>
              )
            }}
          </Formik>
        </div>
      </>
    )
  }
}

const mapStateToProps = (
  { articleCreator, articles, user, formulations }: any,
  ownProps: any
) => {
  let existingRawmat: RawMaterial | null = null
  const usedBy: {
    usedMixes: RawMaterialMix[]
    usedFormulas: Formulation[]
  } = {
    usedMixes: [],
    usedFormulas: []
  }
  if (ownProps.match.params.id) {
    existingRawmat = articles.rawmaterials.filter(
      (f: RawMaterial) => f.id!.toString() === ownProps.match.params.id
    )[0];
    if (existingRawmat) {
      const usedMixes = formulations.rawmatmixes.filter((e: RawMaterialMix) => {
        return (
          e.rawmaterialentries.filter((e2: RawMaterialEntry) => {
            if (e2.rawmaterial) {
              return e2.rawmaterial.id === existingRawmat!.id
            }
            return false
          }).length > 0
        )
      })
      const usedFormulas = formulations.formulations.filter((e: Formulation) => {
        return (
          e.rawmaterialentries.filter((e2: RawMaterialEntry) => {
            if (e2.rawmaterial) {
              return e2.rawmaterial.id === existingRawmat!.id
            }
            return false
          }).length > 0
        )
      })
      usedBy.usedMixes = usedMixes
      usedBy.usedFormulas = usedFormulas
    }
  }
  return {
    existingRawmat,
    role: user.activeRole,
    usedBy
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      initNewRawmatmix,
      createOrUpdateRawmat,
      loadArticlesData,
      deleteRawmat
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRawmat as any)

export default withRouter(pageRedux)
