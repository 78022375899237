import React from 'react';
import { Suggest, ItemRenderer, ItemPredicate } from "@blueprintjs/select";

import { Vendor } from '../../../types'
import { MenuItem } from '@blueprintjs/core';
import { highlightTextForQuery } from '../../../helper/highlight-text';

interface Props {
  items: Vendor[], 
  selectedItem?: Vendor,
  onItemSelect: (v: Vendor) => void,
}

const TVendorSuggest = Suggest.ofType<Vendor>();

const renderVendor: ItemRenderer<Vendor> = (vendor, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
      return null;
  }
  const text = `${vendor.name}`;
  return (
      <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={vendor.id}
          onClick={handleClick}
          text={highlightTextForQuery(text, query)}
      />
  );
};

const renderCreateVendorOption = (
  query: string,
  active: boolean,
  handleClick: React.MouseEventHandler<HTMLElement>,
) => (
  <MenuItem
      icon="add"
      text={`Create "${query.charAt(0).toUpperCase() + query.slice(1)}"`}
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
  />
);

const filterVendor: ItemPredicate<Vendor> = (query, vendor, _index, exactMatch) => {
  const normalizedTitle = vendor.name.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
      return normalizedTitle === normalizedQuery;
  } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
  }
};

export default function VendorSuggest({items, selectedItem, onItemSelect}: Props) {
  return (
    <TVendorSuggest
      fill={true}
      itemRenderer={renderVendor}
      itemPredicate={filterVendor}
      createNewItemRenderer={renderCreateVendorOption}
      createNewItemFromQuery={(query: string) => ({id: -1, name: query.charAt(0).toUpperCase() + query.slice(1)})}
      inputValueRenderer={(v: Vendor) => v.name}
      items={items}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={onItemSelect}
      selectedItem={selectedItem}
    />
  );
}
