import { PermissionRole, User } from '../types';
import client from '../helper/apollo-client';
import gql from 'graphql-tag';
import $ from 'jquery';
import Cookies from 'js-cookie';

import { REMOTE_API_HOST, authHeader } from '../lib/rpc';

export const SET_ROLE = 'user/SET_ROLE';
export const LOAD_USERS_SUCCESS = 'user/LOAD_USERS_SUCCESS';
export const LOAD_USER_DATA_SUCCESS = 'user/LOAD_USER_DATA_SUCCESS';
export const SET_INACTIVE = 'user/SET_INACTIVE';
export const UPDATE_USER_SUCCESS = 'user/UPDATE_USER_SUCCESS';
export const GRANT_FORMULATION_ACCESS = 'user/GRANT_FORMULATION_ACCESS';
export const SET_AJAX_LOADING = 'user/SET_AJAX_LOADING';

const initialState : {
  user: User | null,
  activeRole: PermissionRole,
  users: User[],
  hasFormulationAccess: boolean,
  isInactive: boolean,
  ajaxLoading: boolean,
} = {
  user: null,
  /** 
   * needs to simulate different roles
   */
  activeRole: 'customer',
  users: [],
  hasFormulationAccess: false,
  isInactive: false,
  ajaxLoading: false,
}

export default (state = initialState, action: any) => {
  switch (action.type) {

    case SET_ROLE:
      return {
        ...state,
        activeRole: action.payload,
      }

    case LOAD_USER_DATA_SUCCESS:
      return {
        ...state,
        user: action.payload.me,
        activeRole: action.payload.me ? action.payload.me.role.name : 'customer',
        isInactive: false,
      }

    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users
      }

    case GRANT_FORMULATION_ACCESS:
      return {
        ...state,
        hasFormulationAccess: true,
      }

    case SET_INACTIVE:
      return {
        ...state,
        isInactive: true,
      }

    case SET_AJAX_LOADING:
      return {
        ...state,
        ajaxLoading: action.payload,
      }

    default:
      return state
  }
}

export const setRole = function(role: PermissionRole) {
  return (dispatch: any) => {
    dispatch({
      type: SET_ROLE,
      payload: role
    });
  }
}

export const setAjaxLoading = function(p: boolean) {
  return {
    type: SET_AJAX_LOADING,
    payload: p
  }
}


export const loadUsers = function() {
  return (dispatch: any) => {
    return client.get().query({
      query: gql`
        query IndexQuery {
          users {
            username
            id
            email
            blocked
            role {name}
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
      .then(({data}: any) => {
        dispatch({
          type: LOAD_USERS_SUCCESS,
          payload: data
        })
      });
  }
}

export const loadUserData = function() {
  return (dispatch: any) => {
    return client.get().query({
      query: gql`
        query IndexQuery {
          me {
            username
            id
            email
            role {name}
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
      .then(({data}: any) => {
        dispatch({
          type: LOAD_USER_DATA_SUCCESS,
          payload: data
        })
      });
  }
}

export const login = function(email: string, pass: string) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: `${REMOTE_API_HOST}/auth/local`,
        data: JSON.stringify({
          identifier: email,
          password: pass
        }),
        dataType: 'json',
        contentType: 'application/json',
        success: (data) => {
          localStorage.setItem('jwt', data.jwt);
          Cookies.set('jwt', data.jwt, { expires: 7, path: '' });
          client.clear();
          localStorage.setItem('lastActivity', "" + new Date().getTime());

          dispatch({
            type: LOAD_USER_DATA_SUCCESS,
            payload: { me: data.user }
          });
          resolve();
        },
        error: (e) => {
          localStorage.removeItem('jwt');
          Cookies.remove('jwt');
          reject(e);
        }
      })
    });
  }
}

export const setInactive = function() {
  return {
    type: SET_INACTIVE,
    payload: {}
  }
}

export const logout = function() {
  localStorage.removeItem('jwt');
  Cookies.remove('jwt');
  client.clear();

  return {
    type: LOAD_USER_DATA_SUCCESS,
    payload: { me: null }
  };
};

export const forgotPassword = function(email: string) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: `${REMOTE_API_HOST}/auth/forgot-password`,
        data: JSON.stringify({
          email,
        }),
        dataType: 'json',
        contentType: 'application/json',
        success: () => {
          resolve();
        },
        error: (e) => {
          reject(e);
        }
      })
    });
  }
}

export const registerUser = function(name: string, mail: string, role: PermissionRole) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: `${REMOTE_API_HOST}/adinousers`,
        headers: authHeader(),
        data: JSON.stringify({
          "username": name,
          "email": mail,
          "provider": "local",
          "confirmed": true,
          "blocked": false,
          "role": role,
          "formulations": []
        }),
        dataType: 'json',
        contentType: 'application/json',
        success: () => {
          resolve();
        },
        error: (e) => {
          reject(e);
        }
      })
    });
  }
}

export const updateUser = function(userId: number, data: Partial<User>) {
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: updateUserInput!) {
          updateUser(input : $input) {
            user {id}
          }
        }`,
        variables: {
          input: {
            where: {id: userId},
            data
          }
        }
    })
      .then(({data}: any) => {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: data
        })
      });
  }
}

export const adminResetPassword = function(userId: number) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: `${REMOTE_API_HOST}/adinousers/adminResetPw`,
        data: JSON.stringify({
          userId,
        }),
        dataType: 'json',
        contentType: 'application/json',
        success: () => {
          resolve();
        },
        error: (e) => {
          reject(e);
        }
      });
    });
  }
}

export const changeMyPassword = function(pass: string) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: `${REMOTE_API_HOST}/adinousers/changeMyPassword`,
        data: JSON.stringify({
          pass,
        }),
        dataType: 'json',
        contentType: 'application/json',
        success: () => {
          resolve();
        },
        error: (e) => {
          reject(e);
        }
      });
    });
  }
}

export const grantFormulationAccess = function() {
  return {
    type: GRANT_FORMULATION_ACCESS,
  };
};
