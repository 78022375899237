import PropTypes from "prop-types"
import React from "react"
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './header.css'

import { setRole, logout } from '../../../redux/user';
import { hasPermission } from '../../../lib/auth/permission';
import { doGlobalSearch, setFilter } from "../../../redux/articles"

const Header = ({
  role, 
  setRole, 
  createNewUsers, 
  createNewArticles, 
  logout, 
  changeRole, 
  username, 
  readActivities, 
  createNewFormulas, 
  doGlobalSearch,
  setFilter,
}) => (
  <header
  >
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link
        to="/"
        className="navbar-brand"
        onClick={() => {
          doGlobalSearch('', 'customer');
          setFilter(undefined, false);
        }}
      >
        <img src="/adino-logo.png" style={{height: '3rem'}} />
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <Link className="nav-item nav-link active" to="/">Search <span className="sr-only">(current)</span></Link>
          <Link className="nav-item nav-link" to="/docs">Documents</Link>
          {createNewUsers && (
            <Link className="nav-item nav-link" to="/users">Users</Link>
          )}
          {createNewArticles && (
            <Link className="nav-item nav-link" to="/article-creator">Create new article</Link>
          )}
          {createNewFormulas && (
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenu1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Formulations
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenu1">
              <Link className="dropdown-item" to="/formulations">Formulations</Link>
              <Link className="dropdown-item" to="/rawmats">Raw Materials</Link>
              <Link className="dropdown-item" to="/rawmatmixes">Raw Material Mixes</Link>
              <Link className="dropdown-item" to="/production-protocols">Production protocols</Link>
            </div>
          </li>
          )}
          {readActivities && (
            <Link className="nav-item nav-link" to="/activities">Activity log</Link>
          )}
          {changeRole && (
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenu2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Role: {role}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenu2">
                <a className="dropdown-item" href="#" onClick={() => setRole('director')}>Director</a>
                <a className="dropdown-item" href="#" onClick={() => setRole('management')}>Management</a>
                <a className="dropdown-item" href="#" onClick={() => setRole('administration')}>Administration</a>
                <a className="dropdown-item" href="#" onClick={() => setRole('sales')}>Sales</a>
                <a className="dropdown-item" href="#" onClick={() => setRole('customer')}>Customer</a>
              </div>
            </li>
          )}
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenu3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {username}
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenu3">
              <Link className="dropdown-item" to="/user/changePw">Change password</Link>
            </div>
          </li>
          <Link className="nav-item nav-link" to="/login" onClick={() => logout()}>Logout</Link>
        </div>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
}

Header.defaultProps = {
}


const mapStateToProps = ({ user }) => ({
  role: user.activeRole,
  username: user && user.user.username,
  createNewUsers: hasPermission(user.activeRole, 'create_new_users'),
  createNewArticles: hasPermission(user.activeRole, 'create_new_articles'),
  readActivities: hasPermission(user.activeRole, 'read_activities'),
  changeRole: hasPermission(user.user.role.name, 'change_role'),
  createNewFormulas: hasPermission(user.activeRole, 'create_new_formulas'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setRole, logout, doGlobalSearch, setFilter
    },
    dispatch
  )

const headerRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)

export default headerRedux
