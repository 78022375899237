import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";

import { hasPermission } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import FormulationDataSheet from '../../components/organisms/FormulationDataSheet';
import { Formulation, User, PermissionRole } from '../../../types';
import { initNewFormulation, createOrUpdateFormulationComplete, updateArticle } from '../../../redux/articleCreator';
import { validateFormulation } from '../../../lib/validate';
import showFormErrors from '../../../helper/show-form-errors';
import { loadArticlesData } from '../../../redux/articles';

interface Props {
  formulation: Formulation
  existingFormulation?: Formulation
  articleId?: string
  user: User
  role: PermissionRole
  initNewFormulation: (u: User, cfg: Partial<Formulation>) => void
  createOrUpdateFormulationComplete: (f: Formulation) => Promise<number>
  updateArticle: (a: any, v: any) => Promise<void>
  loadArticlesData: (role: PermissionRole, othersQueries?: string[]) => Promise<void>
  history: any
}
interface States {
  sending: boolean
}

class EditFormulation extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sending: false,
    };
  }

  componentDidMount() {
    let cfg = {};
    if (this.props.existingFormulation) {
      cfg = {
        id: this.props.existingFormulation.id,
        revisionDate: new Date(),
        revisionVersion: this.props.existingFormulation.revisionVersion! + 1,
        name: this.props.existingFormulation.name,
      };
    }
    this.props.initNewFormulation(this.props.user, cfg);
  }

  async create() {
    const {
      history, 
      formulation, 
      createOrUpdateFormulationComplete, 
      loadArticlesData, 
      updateArticle, 
      role,
      articleId,
    } = this.props;

    const formErrors = validateFormulation(formulation);
    if (formErrors.length > 0) {
      showFormErrors(formErrors);
      return;
    }

    this.setState({
      sending: true,
    });
    try {
      const formulationId = await createOrUpdateFormulationComplete(formulation);
      if (articleId) {
        // save it to the article
        await updateArticle(articleId, {
          ownFormula: ["on"],
          formulation: formulationId
        });
        await loadArticlesData(role);
        history.push(`/article/${articleId}`);
      } else {
        await loadArticlesData(role);
        history.push(`/formulation/${formulationId}`);
      }
    } catch (err) {
      console.error(err);
      alert('Error: '+JSON.stringify(err));
    }

    this.setState({
      sending: false,
    });
  }

  render() {
    const {
      formulation
    } = this.props;
    const {
      sending,
    } = this.state;

    const FormulationDataSheetAny = FormulationDataSheet as any;

    return (
      <>
        <div className="container mt-2">
          <h4>Formulation {formulation.name}</h4>
          <FormulationDataSheetAny
            formulation={formulation}
          />
          <button 
            className="btn btn-primary mt-2" 
            disabled={sending}
            onClick={() => this.create()}>Create formulation</button>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ articleCreator, articles, user, formulations } : any, ownProps: any) => {
  const existingFormulation : Formulation | undefined = ownProps.updateExisting && formulations.formulations.filter((f: Formulation) => f.id === ownProps.match.params.id)[0];

  return {
    articleId: ownProps.match.params.articleId,
    formulation: articleCreator.formulation,
    user: user.user,
    role: user.activeRole,
    existingFormulation,
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      initNewFormulation, createOrUpdateFormulationComplete, loadArticlesData, updateArticle
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFormulation as any)

export default withRouter(pageRedux);
