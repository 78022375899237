import $ from 'jquery';
import { cloneDeep, range, set } from 'lodash';
import { REMOTE_API_HOST, authHeader } from '../lib/rpc';
import { Formulation, ProductionProtocol } from '../types';
import client from '../helper/apollo-client';
import gql from 'graphql-tag';


export const INIT_NEW_PRODUCTION_PROTOCOL = 'productionProtocols/INIT_NEW_PRODUCTION_PROTOCOL';
export const UPDATE_EDITING_PRODUCTION_PROTOCOL = 'productionProtocols/UPDATE_EDITING_PRODUCTION_PROTOCOL';
export const LOAD_PRODPROTS_SUCCESS = 'productionProtocols/LOAD_PRODPROTS_SUCCESS';


type Props = {
  productionProtocols: ProductionProtocol[]
  editingProductionProtocol: ProductionProtocol
}

const initialState : Props = {
  productionProtocols: [],
  editingProductionProtocol: {
    formulation: {
      author: {
        blocked: false,
        email: '',
        id: 0,
        role: {
          name: 'sales'
        },
        username: ''
      },
      formulationqualityspecifications: [],
      inprocessspecs: [],
      name: '',
      rawmaterialentries: [],
    },
    formulaRevision: 0,
    data: {
      header: {
        docDate: '',
        docNo: '',
        formulationRevDate: '',
        formulationRevNo: '',
        productionArea: '',
        revNo: '',
        title: '',
      },
      packing: {
      },
      steps: [],
      batchSize: 0,
    },
    id: 0,
    code: '',
  },
}

export default (state = initialState, action: any) => {
  switch (action.type) {

      case LOAD_PRODPROTS_SUCCESS: {
        return {
          ...state,
          productionProtocols: action.payload.prodprots
        }
      }

      case INIT_NEW_PRODUCTION_PROTOCOL: {
        const { formulation, batchSize, code } = action.payload;
        const editingProductionProtocol: ProductionProtocol = {
          id: -1,
          formulation: formulation,
          formulaRevision: 0,
          data: {
            header: {
              docDate: '02.05.2019',
              docNo: 'F.DW.PD-1.1',
              formulationRevDate: '',
              formulationRevNo: '',
              productionArea: '',
              revNo: '01',
              title: '',
            },
            packing: {
            },
            steps: range(20).map(i => ({
              no: i+1,
            })),
            batchSize,
          },
          code,
        };
        return {
          ...state,
          editingProductionProtocol
        }
      }

    case UPDATE_EDITING_PRODUCTION_PROTOCOL: {
      const updated = cloneDeep(state.editingProductionProtocol);
      Object.keys(action.payload).forEach(key => {
        if (key === '__add_lines') {
          updated.data!.steps.push(...range(5).map(i => ({
            no: updated.data!.steps.length + i + 1,
          })));
        } else {
          set(updated, key, action.payload[key]);
        }
      });
      return {
        ...state,
        editingProductionProtocol: updated
      }
    }

    default:
      return state
  }
}
export const initNewProductionProtocol = function(formulation: Formulation, batchSize: number, code: string) {
  return {
    type: INIT_NEW_PRODUCTION_PROTOCOL,
    payload: {
      formulation, batchSize, code
    },
  }
}

export const updateEditingProductionProtocol = function(field: string, value: any) {
  return {
    type: UPDATE_EDITING_PRODUCTION_PROTOCOL,
    payload: {[field]: value}
  };
}

export const createProductionProtocol = function(pp: ProductionProtocol) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: `${REMOTE_API_HOST}/prodprot`,
        headers: authHeader(),
        data: JSON.stringify({
          pp
        }),
        dataType: 'json',
        contentType: 'application/json',
        success: () => {
          resolve();
        },
        error: (e) => {
          reject(e);
        }
      });
    });
  }
}

export const loadProductionProtocols = function() {
  return (dispatch: any) => {
    return client.get().query({
      query: gql`
        query IndexQuery {
          prodprots(sort: "code:desc") {
            id
            code
            formulation {
              id
              name
            }
            formulaRevision
            doc {
              id
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
      .then(({data}: any) => {
        dispatch({
          type: LOAD_PRODPROTS_SUCCESS,
          payload: data
        })
      });
  }
}

