import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";

import { hasPermission } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import FormulationDataSheet from '../../components/organisms/FormulationDataSheet';
import { Formulation, HistoryOption, PermissionRole } from '../../../types';
import { grantFormulationAccess } from '../../../redux/user';
import { Formik } from 'formik';
import HistorySelectionDialog from '../../components/organisms/HistorySelectionDialog';
import { deleteFormulaComplete } from '../../../redux/formulations';
import { loadArticlesData } from '../../../redux/articles';

interface Props {
  formulation: Formulation
  hasFormulationAccess: boolean
  grantFormulationAccess: () => void
  deleteFormulaComplete: (f: Formulation) => void
  loadArticlesData: (role: PermissionRole, othersQueries: string[]) => {}
  oldRevisions: Formulation[]
  usedBy: {id: number}[]
  role: PermissionRole
  history: any
}
interface States {
  dialogHistoryIsOpen: boolean
}

class FormulationPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dialogHistoryIsOpen: false,
    };
  }

  onSubmitCode({ code } : {code: string}) {
    if (code === '73154') {
      this.props.grantFormulationAccess();
    } else {
      alert("Wrong code.");
    }
  }

  selectOldRevision(e: Event) {
    e.preventDefault();
    this.setState({
      dialogHistoryIsOpen: true,
    });
  }

  openOldRevision(e: HistoryOption) {
    this.setState({
      dialogHistoryIsOpen: false,
    });
    this.props.history.push(`/formulation/${e.id}`);
  }

  async remove(e: Event) {
    e.preventDefault();

    const {
      usedBy,
      formulation,
      deleteFormulaComplete,
      loadArticlesData,
      role,
      history,
    } = this.props

    if (usedBy.length > 0) {
      alert(
        `This formulation is used by ${usedBy.length} article(s) and so it can not be removed.`
      )
      return
    }
    if (confirm('Are you sure to remove this formulation?')) {
      await deleteFormulaComplete(formulation!);
      await loadArticlesData(role, ['formulas']);
      history.push(`/rawmats`)
    }
  }

  render() {
    const {
      formulation, hasFormulationAccess, oldRevisions
    } = this.props;
    const {
      dialogHistoryIsOpen,
    } = this.state;

    const FormulationDataSheetAny = FormulationDataSheet as any;

    if (!hasFormulationAccess) {
      return (
        <>
          <div className="container mt-2">
            <h6>Enter formulation access code</h6>
              <Formik
                initialValues={{
                  code: '',
                }}
                onSubmit={v => this.onSubmitCode(v)}>
                {formik => (
                  <form onSubmit={formik.handleSubmit}>
                    <input 
                      type="password" 
                      className="form-control" 
                      placeholder="Access Code" 
                      name="code"
                      onChange={formik.handleChange}
                      value={formik.values.code}
                    />
                    <button type="submit" className="btn btn-primary">OK</button>
                  </form>
                )}
            </Formik>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="container mt-2">
          <h4>
            Formulation {formulation.name}
            <small className="ml-2">
              <Link to={`/formulation/${formulation.id}/update`}>[ Create new revision ]</Link>
            </small>
            <small className="ml-2">
              <a href="#" onClick={e => this.selectOldRevision(e as any)}>[ Old revisions ]</a>
            </small>
            {formulation.historyFor === 0 && (
              <small className="ml-2">
                <a href="#" onClick={e => this.remove(e as any)} className="text-danger">[ Remove ]</a>
              </small>
            )}
          </h4>
          <FormulationDataSheetAny
            readOnly={true}
            formulation={formulation}
          />
        </div>

        <HistorySelectionDialog 
          isOpen={dialogHistoryIsOpen} 
          historyOptions={oldRevisions as HistoryOption[]}
          onDismiss={() => this.setState({dialogHistoryIsOpen: false}) as any}
          onSelect={e => this.openOldRevision(e) as any}
        />
      </>
    )
  }
}

const mapStateToProps = ({formulations, articles, user} : any, ownProps: any) => {
  const formulation = formulations.formulations.filter((a: Formulation) => a.id === ownProps.match.params.id)[0];
  const usedBy : {id: number}[] = [];

  let original = formulation;
  if (formulation && formulation.historyFor !== 0) {
    original = formulations.formulations.filter((a: Formulation) => a.id === formulation.historyFor!.toString())[0];
  }

  let oldRevisions = formulations.formulations.filter((a: Formulation) => a.historyFor!.toString() === original.id);
  oldRevisions = [...oldRevisions, original].sort((a, b) => b.revisionVersion - a.revisionVersion);

  usedBy.push(...articles.articles.filter((a: any) => (a.formulation && a.formulation.id === formulation.id)));

  return {
    formulation,
    hasFormulationAccess: user.hasFormulationAccess,
    oldRevisions,
    usedBy,
    role: user.activeRole,
  };
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      grantFormulationAccess,  deleteFormulaComplete, loadArticlesData
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormulationPage)

export default withRouter(pageRedux);
