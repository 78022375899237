import React from 'react';
import { Select, ItemRenderer, ItemPredicate } from "@blueprintjs/select";

import { AdinoProduct, RawMaterial, RawMaterialOption } from '../../../types'
import { MenuItem, Button } from '@blueprintjs/core';
import { highlightTextForQuery } from '../../../helper/highlight-text';

interface Props {
  items: RawMaterialOption[], 
  selectedItem?: RawMaterialOption,
  onItemSelect: (v: RawMaterialOption) => void,
  allowNone?: boolean
  defaultText?: string
}

const TRawMaterialSelect = Select.ofType<RawMaterialOption>();

const renderMaterials = (entry: RawMaterialOption, { handleClick, modifiers }: any) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      key={`${entry.type}-${entry.id}`}
      label={entry.group}
      onClick={handleClick}
      text={entry.code}
    />
  )
}

const filterMaterials = (query: any, entry: RawMaterialOption) => {
  return entry.code.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
          entry.group.toLowerCase().indexOf(query.toLowerCase()) >= 0
}

export default function RawMaterialSelect({items, selectedItem, onItemSelect, allowNone, defaultText}: Props) {
  let allItems = [...items];
  if (allowNone) {
    allItems = [{
      id: -1,
      code: '-',
      group: '-',
      type: 'rawmaterial',
    }, ...items];
  }
  const selectedTxt = selectedItem ? selectedItem.code : (defaultText || '(nothing selected)');
  return (
    <TRawMaterialSelect
      className="bp3-fill"
      items={allItems}
      itemPredicate={filterMaterials}
      itemRenderer={renderMaterials}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={(e: RawMaterialOption) => onItemSelect(e)}>
      <Button className="bp3-fill" text={selectedTxt} rightIcon="double-caret-vertical" />
    </TRawMaterialSelect>
  );
}
