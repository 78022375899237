import gql from 'graphql-tag';

import $ from 'jquery';

import client from '../helper/apollo-client';

import { REMOTE_API_HOST, authHeader } from '../lib/rpc';
import { Document, User } from '../types';

export const CREATE_DOCUMENT_SUCCESS = 'documents/CREATE_DOCUMENT_SUCCESS';

const initialState = {
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return state
  }
}

export const shareDocument = function(documentId: number, toName: string, to: string, onSuccess: Function) {
  return (dispatch: any) => {
    $.ajax({
      type: 'POST',
      url: `${REMOTE_API_HOST}/docs/${documentId}/share`,
      headers: authHeader(),
      data: JSON.stringify({
        to,
        toName
      }),
      contentType: 'application/json',
      complete: () => {
        // TODO: later, for loading UI
        //dispatch({
        //  type: LOAD_ARTICLES_DATA_SUCCESS,
        //  payload: data
        //});
        onSuccess();
      }
    })
  }
}

export const createDocument = function(doc: Document) {
  const data = {
    ...doc,
    file: doc.file!.id,
    name: doc.file!.name,
    adinoproduct: doc.adinoproduct && doc.adinoproduct.id,
    mandatory: undefined,
    id: undefined,
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: createDocInput!) {
          createDoc(input : $input) {
            doc {id}
          }
        }`,
        variables: {
          input: {
            data
          }
        }
    })
      .then(({data}: any) => {
        dispatch({
          type: CREATE_DOCUMENT_SUCCESS,
          payload: data
        })
      });
  }
}

export const updateDocument = function(doc: Document) {
  const data = {
    ...doc,
    file: doc.file!.id,
    name: doc.file!.name,
    adinoproduct: doc.adinoproduct && doc.adinoproduct.id,
    mandatory: undefined,
    id: undefined,
  };
  return async (dispatch: any) => {
    await client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: updateDocInput!) {
          updateDoc(input : $input) {
            doc {id}
          }
        }`,
        variables: {
          input: {
            where: {id: doc.id},
            data
          }
        }
    });

    dispatch({
      type: CREATE_DOCUMENT_SUCCESS,
    });

    // ask if accessed users should be notified
    const result = await $.ajax({
      type: 'GET',
      url: `${REMOTE_API_HOST}/activities/accessedUsersForDoc/${doc.id}`,
      headers: authHeader(),
      contentType: 'application/json',
    });

    let confirmTxt = 'Do you want to inform users about the new revision, that downloaded this document in the past 12 months?\n\n';
    result.forEach((r: User) => {
      confirmTxt += `${r.username} \t\t ${r.email}\n`;
    });
    if (confirm(confirmTxt)) {
      try {
        await $.ajax({
          type: 'POST',
          url: `${REMOTE_API_HOST}/activities/notifyAccessedUsersForDoc`,
          headers: authHeader(),
          data: JSON.stringify({
            docId: doc.id,
          }),
          contentType: 'application/json',
        });
        alert('Notification sent.');
      } catch (e) {
        alert('Error while sending mail. ' + JSON.stringify(e));
      }
    }
  }
}

export const deleteDocument = function(id: number) {
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: deleteDocInput!) {
          deleteDoc(input : $input) {
            doc {id}
          }
        }`,
        variables: {
          input: {
            where: {id},
          }
        }
    });
  }
}

