import { assign, filter, indexOf } from 'lodash';

import { Document, RawMaterialEntry, FormulationQualitySpecification, Formulation, ArticleCreatorFormValues, AdinoProduct, Vendor, User } from "../types"
import client from '../helper/apollo-client';
import gql from 'graphql-tag';
import { createDocument } from './documents';

export const INIT_NEW_ARTICLE = 'articleCreator/INIT_NEW_ARTICLE';
export const INIT_NEW_FORMULATION = 'articleCreator/INIT_NEW_FORMULATION';
export const ADD_DOCUMENT = 'articleCreator/ADD_DOCUMENT';
export const REMOVE_DOCUMENT = 'articleCreator/REMOVE_DOCUMENT';
export const UPDATE_DOCUMENT = 'articleCreator/UPDATE_DOCUMENT';
export const REMOVE_RAW_MATERIAL_ENTRY = 'articleCreator/REMOVE_RAW_MATERIAL_ENTRY';
export const ADD_OR_UPDATE_RAW_MATERIAL_ENTRY = 'articleCreator/ADD_OR_UPDATE_RAW_MATERIAL_ENTRY';
export const ADD_NEW_QUALITY_SPEC = 'articleCreator/ADD_NEW_QUALITY_SPEC';
export const REMOVE_QUALITY_SPEC = 'articleCreator/REMOVE_QUALITY_SPEC';
export const UPDATE_QUALITY_SPEC = 'articleCreator/UPDATE_QUALITY_SPEC';
export const UPDATE_EDITING_FORMULATION = 'articleCreator/UPDATE_EDITING_FORMULATION';
export const CREATE_ARTICLE_REQUEST = 'articleCreator/CREATE_ARTICLE_REQUEST';
export const CREATE_ARTICLE_FINISH = 'articleCreator/CREATE_ARTICLE_FINISH';

const initialState = {
  article: null,
  documents: [],
  formulation: {
    name: '',
    author: {
      id: 0,
      username: '',
      email: '',
      role: {
        name: 'customer'
      },
    },
    revisionDate: null,
    revisionVersion: null,
    rawmaterialentries: [],
    formulationqualityspecifications: [],
    inprocessspecs: [],
  },
  creatingArticle: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {

    case INIT_NEW_ARTICLE: 
      return {
        ...state,
        documents: [],
        // re-activate later
        // documents: [{
        //   id: -1,
        //   mandatory: true,
        //   type: 'tds',
        //   requiredRole: 'customer',
        //   language: 'english'
        // }, {
        //   id: -2,
        //   mandatory: true,
        //   type: 'msds',
        //   requiredRole: 'customer',
        //   language: 'english'
        // }]
      }

    case INIT_NEW_FORMULATION:
      return {
        ...state,
        formulation: {
          author: {
            ...action.payload.author
          },
          revisionDate: null,
          revisionVersion: null,
          rawmaterialentries: [],
          formulationqualityspecifications: [],
          inprocessspecs: [],
          name: '',
          ...action.payload.cfg,
        }
      }

    case ADD_DOCUMENT: 
      return {
        ...state,
        documents: [
          ...state.documents,
          action.payload
        ]
      }

    case REMOVE_DOCUMENT: 
      return {
        ...state,
        documents: state.documents.filter((d: Document) => d.id !== action.payload.id)
      }

    case UPDATE_DOCUMENT:
      const newDocs = [
        ...state.documents
      ];
      const toUpdate: Document = newDocs.filter((d: Document) => d.id === action.payload.old.id)[0];
      assign(toUpdate, action.payload.new);
      if (toUpdate.type === 'tds' || toUpdate.type === 'msds') {
        toUpdate.requiredRole = 'customer';
      }
      return {
        ...state,
        documents: newDocs
      }

    case REMOVE_RAW_MATERIAL_ENTRY: {
      const entry = action.payload;
      const newRawMaterials = state.formulation.rawmaterialentries.filter((e: RawMaterialEntry) => e !== entry);

      return {
        ...state,
        formulation: {
          ...state.formulation,
          rawmaterialentries: newRawMaterials
        }
      };
    }

    case ADD_OR_UPDATE_RAW_MATERIAL_ENTRY: {
      const entry = action.payload;
      let entries;
      if (!entry) {
        // in case of update, we just want to redraw
        // because we already changed the object directly
        entries = state.formulation.rawmaterialentries;
      } else {
        entries = state.formulation.rawmaterialentries.concat(entry);
      }
      return {
        ...state,
        formulation: {
          ...state.formulation,
          rawmaterialentries: entries
        },
      };
    }

    case ADD_NEW_QUALITY_SPEC:
      const specsField = action.payload.inProcess ? 'inprocessspecs' : 'formulationqualityspecifications';
      return {
        ...state,
        formulation: {
          ...state.formulation,
          [specsField]: [
            ...state.formulation[specsField], {
              method: '',
              note: '',
              parameter: '',
              valueMax: '',
              valueMin: '',
              valueUnit: '',
            }
          ]
        }
      }

    case REMOVE_QUALITY_SPEC: {
      const specsField = action.payload.inProcess ? 'inprocessspecs' : 'formulationqualityspecifications';
      const indexes = action.payload.indexes as number[];
      const newSpecs = filter(
        state.formulation[specsField], 
        (_: any, idx: number) => indexOf(indexes, idx) === -1
      );
  
      return {
        ...state,
        formulation: {
          ...state.formulation,
          [specsField]: newSpecs,
        }
      };
    }

    case UPDATE_QUALITY_SPEC: {
      const specsField = action.payload.inProcess ? 'inprocessspecs' : 'formulationqualityspecifications';
      const idx = action.payload.idx as number;
      const data = action.payload.data as FormulationQualitySpecification;

      const specs: FormulationQualitySpecification[] = [...state.formulation[specsField]];
      specs[idx] = data;

      return {
        ...state,
        formulation: {
          ...state.formulation,
          [specsField]: specs,
        }
      };
    }

    case UPDATE_EDITING_FORMULATION: {
      return {
        ...state,
        formulation: {
          ...state.formulation,
          ...action.payload,
        }
      }
    }

    case CREATE_ARTICLE_REQUEST:
      return {
        ...state,
        creatingArticle: true,
      }

    case CREATE_ARTICLE_FINISH:
      return {
        ...state,
        creatingArticle: false,
      }

    default:
      return state
  }
}

export const initNewArticle = function() {
  return (dispatch: any) => {
    dispatch({
      type: INIT_NEW_ARTICLE
    });
  }
}

export const addDocument = function(doc: Document) {
  return (dispatch: any) => {
    dispatch({
      type: ADD_DOCUMENT,
      payload: doc
    })
  }
}

export const removeDocument = function(doc: Document) {
  return (dispatch: any) => {
    dispatch({
      type: REMOVE_DOCUMENT,
      payload: doc
    })
  }
}

export const updateDocument = function(doc: Document, newDoc: Document) {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_DOCUMENT,
      payload: {
        old: doc,
        new: newDoc
      }
    })
  }
}

export const removeRawMaterialEntryEditing = function(entry: RawMaterialEntry) {
  return (dispatch: any) => {
    dispatch({
      type: REMOVE_RAW_MATERIAL_ENTRY,
      payload: entry
    })
  }
}

export const addOrUpdateRawMaterialEntry = function(entry: RawMaterialEntry | null) {
  return (dispatch: any) => {
    dispatch({
      type: ADD_OR_UPDATE_RAW_MATERIAL_ENTRY,
      payload: entry
    })
  }
}

export const addNewQualitySpec = function(inProcess: boolean) {
  return (dispatch: any) => {
    dispatch({
      type: ADD_NEW_QUALITY_SPEC,
      payload: {inProcess}
    })
  }
}

export const removeQualitySpecs = function(inProcess: boolean, indexes: number[]) {
  return (dispatch: any) => {
    dispatch({
      type: REMOVE_QUALITY_SPEC,
      payload: {inProcess, indexes}
    })
  }
}

export const updateQualitySpec = function(inProcess: boolean, idx: number, data: FormulationQualitySpecification) {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_QUALITY_SPEC,
      payload: {
        inProcess, idx, data
      }
    })
  }
}

export const updateEditingFormulation = function(field: string, value: any) {
  return {
    type: UPDATE_EDITING_FORMULATION,
    payload: {[field]: value}
  };
}

export const createAdinoProduct = function(product: AdinoProduct) {
  const data = {
    ...product,
    id: undefined,
    applicationcategories: product.applicationcategories.map(i => i.id),
    productdocuments: undefined, // ignore documents here
    productgroup: product.productgroup.id,
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: createAprodInput!) {
          createAprod(input : $input) {
            aprod {id}
          }
        }`,
        variables: {
          input: {
            data
          }
        }
    });
  }
}

export const createVendor = function(vendor: Vendor) {
  const data = {
    ...vendor,
    id: undefined
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: createVendInput!) {
          createVend(input : $input) {
            vend {id}
          }
        }`,
        variables: {
          input: {
            data
          }
        }
    });
  }
}

export const createFormulation = function(formulation: Formulation) {
  const data = {
    ...formulation,
    rawmaterialentries: formulation.rawmaterialentries.map(e => e.id),
    formulationqualityspecifications: formulation.formulationqualityspecifications.map(e => e.id),
    inprocessspecs: formulation.inprocessspecs.map(e => e.id),
    author: formulation.author.id,
    id: undefined
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: createFormulaInput!) {
          createFormula(input : $input) {
            formula {id}
          }
        }`,
        variables: {
          input: {
            data
          }
        }
    });
  }
}

export const updateFormulation = function(formulation: Formulation) {
  const data = {
    ...formulation,
    rawmaterialentries: formulation.rawmaterialentries.map(e => e.id),
    formulationqualityspecifications: formulation.formulationqualityspecifications.map(e => e.id),
    inprocessspecs: formulation.inprocessspecs.map(e => e.id),
    author: formulation.author.id,
    id: undefined
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: updateFormulaInput!) {
          updateFormula(input : $input) {
            formula {id}
          }
        }`,
        variables: {
          input: {
            where: {id: formulation.id},
            data
          }
        }
    });
  }
}

export const initNewFormulation = function(author: User, cfg: Partial<Formulation>) {
  return {
    type: INIT_NEW_FORMULATION,
    payload: {
      author, 
      cfg,
    },
  }
}

export const createRawMaterialEntry = function(entry: RawMaterialEntry) {
  const data = {
    ...entry,
    id: undefined,
    rawmaterial: entry.rawmaterial && entry.rawmaterial.id,
    rawmaterialmix: entry.rawmaterialmix && entry.rawmaterialmix.id,
    __typename: undefined,
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: createRawmatentInput!) {
          createRawmatent(input : $input) {
            rawmatent {id}
          }
        }`,
        variables: {
          input: {
            data
          }
        }
    });
  }
}

export const createFormulationQualitySpecification = function(entry: FormulationQualitySpecification) {
  const data = {
    ...entry,
    id: undefined,
  };
  return (dispatch: any) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: createFqualspecInput!) {
          createFqualspec(input : $input) {
            fqualspec {id}
          }
        }`,
        variables: {
          input: {
            data
          }
        }
    });
  }
}

/**
 * with all dependencies
 */
export const createOrUpdateFormulationComplete = function(formulation: Formulation) {
  return async function(dispatch: any) {
    // raw material entries
    await Promise.all(formulation.rawmaterialentries.map(async function(entry, idx) {
      entry.ordering = idx;
      const { data } = await dispatch(createRawMaterialEntry(entry));
      entry.id = data.createRawmatent.rawmatent.id;
    }));

    // formulation quality specs
    await Promise.all(formulation.formulationqualityspecifications.map(async function(entry, idx) {
      entry.ordering = idx;
      const { data } = await dispatch(createFormulationQualitySpecification(entry));
      entry.id = data.createFqualspec.fqualspec.id;
    }));

    // formulation quality specs in-process
    await Promise.all(formulation.inprocessspecs.map(async function(entry, idx) {
      entry.ordering = idx;
      const { data } = await dispatch(createFormulationQualitySpecification(entry));
      entry.id = data.createFqualspec.fqualspec.id;
    }));

    if (formulation.id) {
      await dispatch(updateFormulation(formulation));
      return formulation.id;
    } else {
      const { data } = await dispatch(createFormulation(formulation));
      const formulationId = data.createFormula.formula.id;
      return formulationId;
    }
  }
}

export const createArticle = function(formValues: ArticleCreatorFormValues, documents: Document[], formulation: Formulation) {
  return async function(dispatch: any) {
    dispatch({
      type: CREATE_ARTICLE_REQUEST
    });

    try {
      // product
      let productId: number;
      if (formValues.createNewProduct) {
        const { data } = await dispatch(createAdinoProduct({
          name: formValues.productName,
          description: formValues.productDescription,
          productgroup: formValues.productGroup,
          remark: formValues.productRemark,
          txtApplications: formValues.productTxtApplications,
          txtCharacteristics: formValues.productTxtCharacteristics,
          applicationcategories: formValues.applicationCategories,
          productdocuments: [] // comes later
        }));
        productId = data.createAprod.aprod.id;
      } else {
        productId = formValues.adinoProduct!.id!;
      }

      // formulation sheet
      let formulationId: number | undefined;
      if (formValues.ownFormula && formValues.ownFormula[0] === "on") {
        formulationId = await dispatch(createOrUpdateFormulationComplete(formulation));
      }

      // vendor
      let vendorId;
      if (formValues.vendor.id === -1) {
        const { data } = await dispatch(createVendor(formValues.vendor));
        vendorId = data.createVend.vend.id;
      } else {
        vendorId = formValues.vendor.id;
      }

      // article
      await client.get().mutate({
        mutation: gql`
          mutation MutationQuery($input: createArticleInput!) {
            createArticle(input : $input) {
              article {id}
            }
          }`,
          variables: {
            input: {
              data: {
                code: formValues.articleCode,
                vendorCode: formValues.vendorCode,
                label: formValues.labelFileId,
                image: formValues.imageFileId,
                packaging: formValues.packaging,
                vendor: vendorId,
                ownFormula: formValues.ownFormula && formValues.ownFormula[0] === "on",
                adinoproduct: productId,
                formulation: formulationId,
                barcodeNumber: formValues.barcodeNumber,
                requiredRole: 
                  (formValues.internalOnly && formValues.internalOnly[0] === "on")
                  ? 'administration'
                  : 'customer',
                trialProduct: formValues.articleTrialProduct,
                remark: formValues.articleRemark,
                cost: formValues.articleCost,
                costUnit: formValues.articleCostUnit,
                costUpdatedDate: formValues.articleCostUpdatedDate,
              }
            }
          }
      });

      // documents
      if (formValues.createNewProduct) {
        await Promise.all(documents.map(doc => dispatch(createDocument({
          ...doc,
          adinoproduct: {
            id: productId,
            name: ''
          }
        }))));
      }
    } catch (e) {
      dispatch({
        type: CREATE_ARTICLE_FINISH
      });
      throw e;
    }

    dispatch({
      type: CREATE_ARTICLE_FINISH
    });
  }
}

export function updateArticle(articleId: number, formValues: any) {
  return async function(dispatch: any) {
    // vendor
    let vendorId = undefined;
    if (formValues.vendor) {
      if (formValues.vendor.id === -1) {
        const { data } = await dispatch(createVendor(formValues.vendor));
        vendorId = data.createVend.vend.id;
      } else {
        vendorId = formValues.vendor.id;
      }
    }

    const ownFormula = formValues.ownFormula && formValues.ownFormula[0] === "on";
    if (!ownFormula) {
      formValues.formulation = null; //unset
    }

    // article
    await client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: updateArticleInput!) {
          updateArticle(input : $input) {
            article {id}
          }
        }`,
        variables: {
          input: {
            where: {id: articleId},
            data: {
              code: formValues.articleCode,
              vendorCode: formValues.vendorCode,
              label: formValues.labelFileId,
              image: formValues.imageFileId,
              packaging: formValues.packaging,
              vendor: vendorId,
              ownFormula,
              barcodeNumber: formValues.barcodeNumber,
              requiredRole: 
                (formValues.internalOnly && formValues.internalOnly[0] === "on")
                  ? 'administration'
                  : 'customer',
              formulation: formValues.formulation,
              trialProduct: formValues.articleTrialProduct,
              remark: formValues.articleRemark,
              cost: formValues.articleCost,
              costUnit: formValues.articleCostUnit,
              costUpdatedDate: formValues.articleCostUpdatedDate,
          }
          }
        }
    });
  }
}

export function updateProduct(productId: number, formValues: any) {
  return async function(dispatch: any) {
    await client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: updateAprodInput!) {
          updateAprod(input : $input) {
            aprod {id}
          }
        }`,
        variables: {
          input: {
            where: {id: productId},
            data: {
              name: formValues.productName,
              description: formValues.productDescription,
              remark: formValues.productRemark,
              txtApplications: formValues.productTxtApplications,
              txtCharacteristics: formValues.productTxtCharacteristics,
              applicationcategories: formValues.applicationCategories.map((i: any) => i.id),
              productgroup: formValues.productGroup.id,
            }
          }
        }
    });
  }
}

