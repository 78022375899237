import React, {useCallback} from 'react'
import Dropzone, { IFileWithMeta, IUploadParams } from 'react-dropzone-uploader'
import { GlobalSearchResult } from '../../../types';
import { Link } from 'react-router-dom';
import { upperCase } from 'lodash';

interface Props {
  results: GlobalSearchResult[]
}

function Item({item}: {item: GlobalSearchResult}) {
  return (
    <Link to={item.link} className="list-group-item list-group-item-action">
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">{item.title}</h5>
        <small className="text-muted">{upperCase(item.type)}</small>
      </div>
      <p className="mb-1">{item.subtitle}</p>
    </Link>
  );
}

export default function GlobalSearchResults({ results }: Props) {
  return (
    <div className="list-group">
      {results.map(r => <Item key={`gsr-${r.link}`} item={r} />)}
    </div>
  )
}
