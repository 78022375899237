export const DocumentTypes: {[key: string]: string} = {
  tds: 'TDS',
  msds: 'MSDS',
  marketing: 'Marketing material',
  external_report: 'External report',
  internal_report: 'Internal report',
  other: 'Other',
  production_protocol: 'Production protocol',
  certificate: 'Certificate',
};
