import ApolloClient from 'apollo-boost';
import { REMOTE_API_HOST, authHeader } from '../lib/rpc';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

let client = null;

export default {
  get() {
    if (client == null) {
      client = new ApolloClient({
        uri:  REMOTE_API_HOST + '/graphql',
        defaultOptions: defaultOptions,
        headers: authHeader(),
      });
    }
    return client;
  },
  clear() {
    client = null;
  }
};
