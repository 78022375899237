import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import $ from 'jquery';
import { capitalize, split, escape, trim } from 'lodash';

import SendDocumentDialog from '../../components/molecules/SendDocumentDialog'
import { shareDocument, createDocument, updateDocument, deleteDocument } from '../../../redux/documents';
import { deleteArticle, loadArticlesData, loadDocuments } from '../../../redux/articles';
import { updateArticle } from '../../../redux/articleCreator';
import { hasPermission, hasRoleOrMore, requiredRoleForFields } from '../../../lib/auth/permission';
import { DocumentTypes } from '../../../lib/content/statics'
import DocumentDialog from '../../components/organisms/DocumentDialog'
import { REMOTE_API_HOST } from '../../../lib/rpc'
import DocumentsTable from '../../components/organisms/DocumentsTable'
import SimpleSelectionDialog from '../../components/molecules/SimpleSelectionDialog'
import ArticleSearch from '../../components/organisms/ArticleSearch'
import { setAjaxLoading } from '../../../redux/user'

class ArticlePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDocument: null,
    };
  }

  getFilteredDocuments() {
    const {
      article, role
    } = this.props;

    return article.adinoproduct.productdocuments
      .filter(pd => hasRoleOrMore(role, pd.requiredRole));
  }

  chooseFormulation(e) {
    e.preventDefault();
    this.setState({
      dialogChooseFormulationOpen: true,
    });
  }

  formatSimpleList(txt) {
    if (trim(txt).length === 0) return '';
    return (
      <ul>
        {split(txt, '\n').map(p => <li>{escape(p)}</li>)}
      </ul>
    );
  }

  async onFormulationSelected(f) {
    const {
      history,
      article,
      updateArticle,
      loadArticlesData,
      role,
      setAjaxLoading,
    } = this.props;

    if (!f || f.id === -1) {
      this.setState({
        dialogChooseFormulationOpen: false,
      });
      history.push(`/formulation-creator/${article.id}`);
    } else {
      setAjaxLoading(true);

      await updateArticle(article.id, {
        ownFormula: ["on"],
        formulation: f.id
      });
      await loadArticlesData(role);

      setAjaxLoading(false);

      this.setState({
        dialogChooseFormulationOpen: false,
      });
    }
  }

  async onDeleteArticle() {
    const {
      deleteArticle,
      loadArticlesData,
      role,
      article,
      history,
    } = this.props;

    if (window.confirm("Are you sure to delete this Article? Note: Documents are attached to the Product and therefore not deleted.")) {
      console.log("ja");
      await deleteArticle(article);
      await loadArticlesData(role);
      history.push(`/`);
    }
  }

  async onDeleteDocument(e, doc) {
    e.preventDefault();
    if (window.confirm('Are you sure to remove this document?')) {
      await this.props.deleteDocument(doc.id);

      // reload all data
      await Promise.all([
        this.props.loadArticlesData(this.props.role),
        this.props.loadDocuments()
      ]);
    }
  }

  render() {
    const {
      article,
      showArticleDetails,
      canAddDocuments,
      canDeleteDocuments,
      role,
      showRevision,
      canEditArticle,
      canDeleteArticle,
      canEditProduct,
      showVisibleTo,
      formulationOptions,
      createNewFormulas,
    } = this.props;
    const {
      dialogChooseFormulationOpen,
    } = this.state;

    const articleFormulation = article && article.formulation;
    const internalOnly = article && hasRoleOrMore(article.requiredRole, 'administration');
    const imageUrl = article && article.image && `${REMOTE_API_HOST}/articles/${article.id}/image`;

    return (
      <>
        <div className="container-fluid mt-4">
          {article && (
            <div className="row">
              {/* only xs */}
              <div className="col-sm-2 d-block d-sm-none text-center mb-4">
              {imageUrl && <img src={imageUrl} style={{maxHeight: '6rem'}} />}
              </div>
              {/* > xs */}
              <div className="col-sm-2 d-none d-sm-block" style={{paddingRight: '2rem'}}>
                {imageUrl && <img src={imageUrl} className="img-fluid" />}
              </div>
              <div className="col-sm-10 col-md-7">
                <h1 className="article-title">
                  ADiNO&reg; {article.adinoproduct.name}
                  {internalOnly && <span className="badge badge-warning ml-2">internal only</span>}
                </h1>
                {hasRoleOrMore(role, requiredRoleForFields['adinoproduct.description']) && (
                  <h4>{article.adinoproduct.description}</h4>
                )}

                <hr />

                <DocumentsTable
                  id="article-page"
                  entries={this.getFilteredDocuments()}
                  editMode={false}
                  canAddDocuments={canAddDocuments}
                  showRevision={showRevision}
                  showVisibleTo={showVisibleTo}
                  adinoProduct={article.adinoproduct}
                  onDeleteDocument={(e, d) => this.onDeleteDocument(e, d)}
                  canDeleteDocuments={canDeleteDocuments}
                />

                <table className="table table-borderless article-page-table color-lightblack mt-4">
                  <tbody>
                    {hasRoleOrMore(role, 'administration') && (
                      <tr>
                        <td colSpan={2}>
                          <h5>
                            Article information
                            {canEditArticle && (
                              <small className="ml-2">
                                <Link to={`/article/${article.id}/update`}>[ Edit ]</Link>
                              </small>
                            )}
                            {canDeleteArticle && (
                              <small className="ml-2">
                                <a className="text-danger" href={`#`} onClick={() => this.onDeleteArticle()}>[ Delete ]</a>
                              </small>
                            )}
                          </h5>
                        </td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.code']) && (
                      <tr>
                        <th scope="row">Article Code</th>
                        <td>{article.code}</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.vendor']) && (
                      <tr>
                        <th scope="row">Vendor</th>
                        <td>{article.vendor && article.vendor.name}</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.vendorCode']) && (
                      <tr>
                        <th scope="row">Vendor Code</th>
                        <td>{article.vendorCode}</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.packaging']) && (
                      <tr>
                        <th scope="row">Packaging</th>
                        <td>{article.packaging}</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.barcodeNumber']) && (
                      <tr>
                        <th scope="row">Barcode number</th>
                        <td>{article.barcodeNumber}</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.ownFormulation']) && (
                      <tr>
                        <th scope="row">Formula</th>
                        <td>
                          {article.ownFormula 
                          && (
                            <div>
                              <i className="material-icons" style={{verticalAlign: 'middle', marginRight: 4}}>check</i>
                              Own formula
                            </div>
                          )}
                          {articleFormulation && (
                            <div>
                              <Link to={`/formulation/${articleFormulation.id}`}>Open formulation</Link>
                            </div>
                          ) || (
                            <>
                              {createNewFormulas && (
                                <a href="#" onClick={e => this.chooseFormulation(e)}>Create or choose formulation</a>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.label']) && (
                      <tr>
                        <th scope="row">Label</th>
                        <td>
                          {article.label && (
                            <a target="_blank" href={`${REMOTE_API_HOST}/articles/${article.id}/label`}>Download label</a>
                          ) || (
                            `(no label available)`
                          )}
                        </td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.image']) && (
                      <tr>
                        <th scope="row">Image</th>
                        <td>
                          {article.image && (
                            <a target="_blank" href={imageUrl}>Download image</a>
                          ) || (
                            `(no image available)`
                          )}
                        </td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.requiredRole']) && (
                      <tr>
                        <th scope="row">Minimal required role</th>
                        <td>{article.requiredRole} ({internalOnly ? 'Internal only' : 'External'})</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.remark']) && (
                      <tr>
                        <th scope="row">Article Remark</th>
                        <td>{article.remark}</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.trialProduct']) && (
                      <tr>
                        <th scope="row">Trial Product</th>
                        <td>{article.trialProduct}</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['article.cost']) && (
                      <tr>
                        <th scope="row">Cost</th>
                        <td>{article.cost} {article.costUnit} {article.costUpdatedDate && ('(' + article.costUpdatedDate + ')')}</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {hasRoleOrMore(role, 'administration') && (
                  <h5 className="color-lightblack">
                    Product information
                    {canEditProduct && (
                      <small className="ml-2">
                        <Link to={`/product/${article.adinoproduct.id}/update`}>[ Edit ]</Link>
                      </small>
                    )}
                  </h5>
                )}

                <table className="table table-borderless article-page-table color-lightblack">
                  <tbody>
                    {hasRoleOrMore(role, 'administration') && (
                      <>
                        {hasRoleOrMore(role, requiredRoleForFields['adinoproduct.name']) && (
                          <tr>
                            <th scope="row">Product Name</th>
                            <td>{article.adinoproduct.name}</td>
                          </tr>
                        )}
                        {hasRoleOrMore(role, requiredRoleForFields['adinoproduct.description']) && (
                          <tr>
                            <th scope="row">Description</th>
                            <td>{article.adinoproduct.description}</td>
                          </tr>
                        )}
                      </>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['adinoproduct.txtApplications']) && 
                      article.adinoproduct.txtApplications &&
                    (
                      <tr>
                        <th scope="row">Applications</th>
                        <td>{this.formatSimpleList(article.adinoproduct.txtApplications)}</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['adinoproduct.txtCharacteristics']) && 
                      article.adinoproduct.txtCharacteristics &&
                    (
                      <tr>
                        <th scope="row">Characteristics</th>
                        <td>{this.formatSimpleList(article.adinoproduct.txtCharacteristics)}</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['adinoproduct.productgroup']) && (
                      <tr>
                        <th scope="row">Product Group</th>
                        <td>{article.adinoproduct.productgroup && article.adinoproduct.productgroup.name}</td>
                      </tr>
                    )}
                    {hasRoleOrMore(role, requiredRoleForFields['adinoproduct.remark']) && (
                      <tr>
                        <th scope="row">Product Remark</th>
                        <td>{article.adinoproduct.remark}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-3 d-sm-none d-md-block">
                <ArticleSearch isOnHome={false} />
              </div>
            </div>
          )}
        </div>

        <SimpleSelectionDialog 
          isOpen={dialogChooseFormulationOpen} 
          options={formulationOptions}
          onDismiss={() => this.setState({dialogChooseFormulationOpen: false})}
          onSelect={e => this.onFormulationSelected(e)}
        />
      </>
    )
  }
}

ArticlePage.propTypes = {
  match: PropTypes.object.isRequired,
  article: PropTypes.object,
  role: PropTypes.string.isRequired,
  showRevision: PropTypes.bool.isRequired,
  showArticleDetails: PropTypes.bool.isRequired,
  canAddDocuments: PropTypes.bool.isRequired,
  canDeleteDocuments: PropTypes.bool.isRequired,
  canEditArticle: PropTypes.bool.isRequired,
  canDeleteArticle: PropTypes.bool.isRequired,
  canEditProduct: PropTypes.bool.isRequired,
  formulations: PropTypes.array.isRequired,
  createDocument: PropTypes.func.isRequired,
  updateDocument: PropTypes.func.isRequired,
  loadArticlesData: PropTypes.func.isRequired,
  updateArticle: PropTypes.func.isRequired,
  deleteArticle: PropTypes.func.isRequired,
}

const mapStateToProps = ({ articles, user, formulations }, ownProps) => {
  const article = articles.articles.filter(a => a.id === ownProps.match.params.id)[0];

  return {
    article,
    role: user.activeRole,
    showRevision: hasRoleOrMore(user.activeRole, 'sales'),
    showArticleDetails: hasPermission(user.activeRole, 'read_article_details'),
    canAddDocuments: hasPermission(user.activeRole, 'add_product_documents'),
    canDeleteDocuments: hasPermission(user.activeRole, 'delete_documents'),
    canEditArticle: hasPermission(user.activeRole, 'edit_article'),
    canDeleteArticle: hasPermission(user.activeRole, 'delete_article'),
    canEditProduct: hasPermission(user.activeRole, 'edit_product'),
    createNewFormulas: hasPermission(user.activeRole, 'create_new_formulas'),
    showVisibleTo: hasPermission(user.activeRole, 'show_visible_to'),
    formulations: formulations.formulations,
    formulationOptions: [
      {
        id: -1,
        title: '(create new formulation)',
      },
      ...formulations.formulations.map(f => ({
        id: f.id,
        title: f.name,
      }))
    ],
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  shareDocument,
  createDocument,
  loadArticlesData,
  updateDocument,
  updateArticle,
  deleteArticle,
  deleteDocument,
  loadDocuments,
  setAjaxLoading,
}, dispatch)

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticlePage)

export default withRouter(pageRedux)
