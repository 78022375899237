import { AgGridReact } from 'ag-grid-react';
import React, { PureComponent } from 'react';
import { RawMaterial } from '../../../types';

export interface Props {
  entries: RawMaterial[]
  onClick: (e: RawMaterial) => void
}

interface States {
  columnDefs: any[]
}

class RawmatsGrid extends PureComponent<Props, States> {
  gridApi: any

  constructor(props: any) {
    super(props);

    this.state = {
      columnDefs: [],
    };
  }

  getColumnDef() {
    return [{
        headerName: "Group", 
        field: "group", 
        resizable: true,
        filter: true
      }, {
        headerName: "Code", 
        field: "code", 
        resizable: true,
        filter: true
      }, {
        headerName: "Description", 
        field: "description", 
        resizable: true,    
        filter: true,
      }, {
        headerName: "Company", 
        field: "company", 
        resizable: true,    
        filter: true,
      }, {
        headerName: "Distributor", 
        field: "distributor", 
        resizable: true,    
        filter: true,
      }];
  }

  onGridReady(grid: any) {
    this.gridApi = grid;
    this.gridApi.sizeColumnsToFit();
  }

  render() {
    return (
      <div 
        className="ag-theme-balham"
        style={{ 
        height: '500px' }} 
      >
        <AgGridReact
          onRowClicked={ ({data}) => this.props.onClick(data) }
          onGridReady={ params => this.onGridReady(params.api) }
          floatingFilter={true}
          columnDefs={this.getColumnDef()}
          rowData={this.props.entries}>
        </AgGridReact>
      </div>
    );
  }
}

export default RawmatsGrid;
