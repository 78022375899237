import React, { PureComponent } from 'react'

import { Button, MenuItem, Dialog, Classes, NumericInput } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { SimpleSelectionOption } from '../../../types';
import moment from 'moment';

export interface Props {
  isOpen: boolean,
  options: SimpleSelectionOption[],
  selectText?: string
  onDismiss: () => {},
  onSelect: (e: SimpleSelectionOption) => {},
}

interface States {
}

class SimpleSelectionDialog extends PureComponent<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      isOpen,
      options,
      onDismiss,
      onSelect,
      selectText,
    } = this.props;

    const {
    } = this.state;
    
    const filterItem = (query: any, entry: SimpleSelectionOption) => {
      return entry.title.indexOf(query.toLowerCase()) >= 0
    }

    const renderItem = (entry: SimpleSelectionOption, { handleClick, modifiers }: any) => {
      if (!modifiers.matchesPredicate) {
        return null
      }
      return (
        <MenuItem
          active={modifiers.active}
          key={`${entry.id}`}
          onClick={handleClick}
          text={entry.title}
        />
      )
    }

    return (
      <Dialog isOpen={isOpen}>
        <div className={Classes.DIALOG_BODY}>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">{selectText || 'Select option'}</label>
            <div className="col-sm-8">
              <Select
                className="bp3-fill"
                items={options}
                itemPredicate={filterItem}
                itemRenderer={renderItem}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={(e: SimpleSelectionOption) => onSelect(e)}>
                <Button className="bp3-fill" text={'(nothing selected)'} rightIcon="double-caret-vertical" />
              </Select>
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => onDismiss()}>Close</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default SimpleSelectionDialog;
