import React from 'react'

interface Props {
  id: string
  onSubmit: () => void
  loading?: boolean
  title: string
  body: JSX.Element
}

export default function SimpleConfirmDialog({id, onSubmit, title, body, loading}: Props) {
  return (
    <div className="modal fade" id={id} tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {body}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" disabled={loading}>Close</button>
            <button id={`${id}-submit`} type="button" className="btn btn-primary" onClick={() => onSubmit()} disabled={loading}>OK</button>
          </div>
        </div>
      </div>
    </div>
  )
}
