import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useFormik } from 'formik'
import FormulationDataSheet from '../../components/organisms/FormulationDataSheet'

const Step4 = ({ formik, onBack, formulation, creatingArticle }) => {
  return (
    <>
      <h5>Formulation Sheet</h5>
      <FormulationDataSheet formulation={formulation} />
      <button className="btn btn-outline-secondary" onClick={() => onBack()}>
        Back
      </button>
      <button type="submit" className="btn btn-primary" disabled={creatingArticle}>
        Create article
      </button>
    </>
  )
}

Step4.propTypes = {
  formik: PropTypes.object.isRequired,
  onBack: PropTypes.func,
  creatingArticle: PropTypes.bool.isRequired,
}

const mapStateToProps = ({articleCreator}) => ({
  formulation: articleCreator.formulation,
  creatingArticle: articleCreator.creatingArticle,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step4)

export default withRouter(pageRedux)
