import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { capitalize } from 'lodash'

import { AgGridReact } from 'ag-grid-react';

import { hasPermission } from '../../../lib/auth/permission';
import { Document, User, Activity } from '../../../types';
import { DocumentTypes } from '../../../lib/content/statics';
import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';

export interface Props {
  entries: Activity[]
  onClick: (e: Activity) => void
}

interface States {
  columnDefs: any[]
}

class ActivitiesGrid extends PureComponent<Props, States> {
  gridApi: any

  constructor(props: any) {
    super(props);

    this.state = {
      columnDefs: [],
    };
  }

  getColumnDef() {
    return [{
        headerName: "User", 
        field: "user.email", 
        resizable: true,
        filter: true
      }, {
        headerName: "Action", 
        field: "action", 
        resizable: true,    
        filter: true,
      }, {
        headerName: "Date", 
        field: "actionDate", 
        resizable: true,    
        filter: true,
        valueFormatter: (params: ValueFormatterParams) => params.value && moment(params.value).format('YYYY-MM-DD h:mm')
      }, {
        headerName: "Entity type", 
        field: "entityType", 
        resizable: true,    
        filter: true,
      }, {
        headerName: "Entity id", 
        field: "entityId", 
        resizable: true,    
        filter: true,
      }, {
        headerName: "Params", 
        field: "params", 
        resizable: true,    
        filter: true,
        valueFormatter: (params: ValueFormatterParams) => params.value && JSON.stringify(params.value),
      }];
  }

  onGridReady(grid: any) {
    this.gridApi = grid;
    this.gridApi.sizeColumnsToFit();
  }

  render() {
    return (
      <div 
        className="ag-theme-balham"
        style={{ 
        height: '500px' }} 
      >
        <AgGridReact
          onRowClicked={ ({data}) => this.props.onClick(data) }
          onGridReady={ params => this.onGridReady(params.api) }
          floatingFilter={true}
          columnDefs={this.getColumnDef()}
          rowData={this.props.entries}>
        </AgGridReact>
      </div>
    );
  }
}

export default ActivitiesGrid;
