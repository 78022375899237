import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy, capitalize } from "lodash";
import moment from 'moment';

import $ from 'jquery';

import { hasPermission, hasRoleOrMore } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Document, PermissionRole } from '../../../types';
import { REMOTE_API_HOST } from '../../../lib/rpc';
import { DocumentTypes } from '../../../lib/content/statics';
import SendDocumentDialog from '../../components/molecules/SendDocumentDialog';
import DocumentDialog from '../../components/organisms/DocumentDialog';
import { shareDocument, updateDocument, deleteDocument } from '../../../redux/documents';
import { loadArticlesData, loadDocuments } from "../../../redux/articles";
import utils from '../../../lib/content/utils';
import { setAjaxLoading } from '../../../redux/user';

interface Props {
  entry: Document | null
  role: PermissionRole
  canUpdateDocuments: boolean
  shareDocument: (documentId: number, toName: string, to: string, onSuccess: Function) => {}
  loadArticlesData: (role: PermissionRole, othersQueries?: string[]) => {}
  loadDocuments: () => {}
  updateDocument: (doc: Document) => {}
  deleteDocument: (docId: number) => {}
  history: any
  showVisibleTo: boolean
  canDeleteDocuments: boolean
  setAjaxLoading: (p: boolean) => void
}
interface States {
  dialogEntryIsOpen: boolean
  editingEntry?: Document
}

class DocumentDetailsPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dialogEntryIsOpen: false
    };
  }

  openSendDocumentDialog(e: Event) {
    e.preventDefault();

    $('#dialog-send-document-submit').prop('disabled', false);
    ($('#dialog-send-document') as any).modal();
  }

  onSubmitShareDocument(name: string, mail: string) {
    if (!name || !mail) {
      alert('Information missing.');
      return;
    }

    this.props.shareDocument(
      this.props.entry!.id!,
      name,
      mail,
      () => {
        alert('Mail with link was sent.');
        ($('#dialog-send-document') as any).modal('hide');
      }
    );
  }

  async createOrUpdateDocument(doc: Document) {
    this.props.setAjaxLoading(true);

    await this.props.updateDocument(doc);

    // reload all data
    await Promise.all([
      this.props.loadArticlesData(this.props.role),
      this.props.loadDocuments()
    ])
    .then(() => this.props.setAjaxLoading(false))
    .catch(e => {
      this.props.setAjaxLoading(false);
      console.error(e);
    });

    this.setState({
      dialogEntryIsOpen: false,
    });
  }

  openUpdateDocument(e: Event) {
    e.preventDefault();

    const { entry } = this.props;

    this.setState({
      editingEntry: {
        ...entry!,
        file: undefined,
        mandatory: true,
        requiredRole: entry!.requiredRole,
        revisionVersion: entry!.revisionVersion! + 1,
        revisionDate: new Date(),
      },
      dialogEntryIsOpen: true
    });
  }

  async onDeleteDocument(e: Event) {
    e.preventDefault();
    if (confirm('Are you sure to remove this document?')) {
      await this.props.deleteDocument(this.props.entry!.id!);

      // reload all data
      await Promise.all([
        this.props.loadArticlesData(this.props.role),
        this.props.loadDocuments()
      ]);
      this.props.history.goBack();
    }
  }

  render() {
    const {
      entry,
      canUpdateDocuments,
      canDeleteDocuments,
      showVisibleTo,
    } = this.props;
    const {
      dialogEntryIsOpen,
      editingEntry,
    } = this.state;

    return (
      <>
        <div className="container mt-2">
          <h4>
            Document
          </h4>

          {entry && (
            <>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th scope="row">Name</th>
                    <td>
                      <h6>
                        <a target="_blank" href={`${REMOTE_API_HOST}/docs/${entry.id}/download`}>{DocumentTypes[entry.type]}</a>
                        &nbsp;<span className="badge badge-secondary">{capitalize(entry.language)}</span></h6>
                        {(entry.type !== 'msds' && entry.type !== 'tds') && (
                          <small>{entry.name}</small>
                        )}
                    </td>
                  </tr>
                  {entry.adinoproduct && (
                    <tr>
                      <th scope="row">For product</th>
                      <td>
                        {entry.adinoproduct.name}
                      </td>
                    </tr>
                  )}
                  {showVisibleTo && (
                    <tr>
                      <th scope="row">Visible for</th>
                      <td>
                        {capitalize(entry.requiredRole)}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Revision</th>
                    <td>
                      {entry.revisionDate && moment(entry.revisionDate).format('YYYY-MM-DD')} (
                      {entry.revisionVersion})
                    </td>
                    </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>
                      <a
                        href="#"
                        onClick={(e: any) => this.openSendDocumentDialog(e)}>
                        <i className="material-icons" style={{verticalAlign: 'middle', marginRight: 4}}>email</i>
                        Send link
                      </a>
                      {canUpdateDocuments && (
                        <>
                          <br/>
                          <a
                            href="#"
                            onClick={(e: any) => this.openUpdateDocument(e)}>
                            <i className="material-icons" style={{verticalAlign: 'middle', marginRight: 4}}>cloud_upload</i>
                            Upload new revision
                          </a>
                        </>
                      )}
                      {canDeleteDocuments && (
                        <>
                          <br/>
                          <a
                            href="#"
                            onClick={(e: any) => this.onDeleteDocument(e)}
                            className="text-danger">
                            <i className="material-icons" style={{verticalAlign: 'middle', marginRight: 4}}>remove_circle</i>
                            Delete document
                          </a>
                      </>
                    )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <SendDocumentDialog 
                id="dialog-send-document" 
                documentName={utils.docs.getName(entry)} 
                onSubmit={(n: string, m: string) => this.onSubmitShareDocument(n, m)}
              />
              <DocumentDialog
                isOpen={dialogEntryIsOpen} 
                onDismiss={() => this.setState({dialogEntryIsOpen: false}) as any}
                onSave={async (d: Document) => {
                  await this.createOrUpdateDocument(d);
                }}
                editingEntry={editingEntry}
              />
            </>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = ({user, articles} : any, ownProps: any) => {
  return {
    showRevision: user.user && hasRoleOrMore(user.activeRole, 'sales'),
    canUpdateDocuments: user.user && hasPermission(user.activeRole, 'add_product_documents'),
    canDeleteDocuments: user.user && hasPermission(user.activeRole, 'delete_documents'),
    entry: articles.documents.filter((d: Document) => d.id === ownProps.match.params.id)[0],
    showVisibleTo: user.user && hasPermission(user.activeRole, 'show_visible_to'),
    role: user.activeRole,
  };
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      updateDocument, shareDocument, loadArticlesData, loadDocuments, deleteDocument,
      setAjaxLoading
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentDetailsPage)

export default withRouter(pageRedux);
