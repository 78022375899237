import React, { PureComponent } from 'react'

import { Button, MenuItem, Dialog, Classes, NumericInput } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { RawMaterialOption, RawMaterialEntry } from '../../../types';
import RawMaterialSelect from '../molecules/RawMaterialSelect';

export interface Props {
  isOpen: boolean,
  rawMaterialOptions: RawMaterialOption[],
  onDismiss: () => {},
  onSave: (entry: RawMaterialEntry) => {},
  editingEntry?: RawMaterialEntry,
}

interface States {
  rawMaterial?: RawMaterialOption,
  quantityPercentage: number,
  note: string,
}

class FormulationEntryDialog extends PureComponent<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      quantityPercentage: 0,
      note: '',
    };
  }

  save() {
    // validate fields
    const formErrors = [];

    if (!this.state.rawMaterial) {
      formErrors.push({
        field: 'Raw material',
        message: 'is missing'
      });
    }
    if (!this.state.quantityPercentage) {
      formErrors.push({
        field: 'Quantity',
        message: 'is missing'
      });
    }

    if (formErrors.length > 0) {
      // TODO show nice errors
      alert("missing values");
      return;
    }

    if (!this.state.rawMaterial) return;

    if (this.state.rawMaterial.type === 'rawmaterial') {
      this.props.onSave({
        rawmaterial: {
          id: this.state.rawMaterial.id,
          code: this.state.rawMaterial.code,
          group: this.state.rawMaterial.group,
        },
        ppm: Math.round(this.state.quantityPercentage * 10000),
        note: this.state.note,
      });
    } else {
      this.props.onSave({
        rawmaterialmix: {
          id: this.state.rawMaterial.id,
          code: this.state.rawMaterial.code,
          group: this.state.rawMaterial.group,
          rawmaterialentries: [],
        },
        ppm: Math.round(this.state.quantityPercentage * 10000),
        note: this.state.note,
      });
    }

    // reset
    this.setState({
      quantityPercentage: 0,
      rawMaterial: undefined,
      note: '',
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { editingEntry } = this.props;
    if (prevProps.editingEntry !== editingEntry) {
      if (editingEntry) {
        if (editingEntry.rawmaterial) {
          this.setState({
            quantityPercentage: editingEntry.ppm / 10000,
            rawMaterial: {
              id: editingEntry.rawmaterial.id!,
              code: editingEntry.rawmaterial.code,
              group: editingEntry.rawmaterial.group,
              type: 'rawmaterial'
            },
            note: editingEntry.note || '',
          });
        } else if (editingEntry.rawmaterialmix) {
          this.setState({
            quantityPercentage: editingEntry.ppm / 10000,
            rawMaterial: {
              id: editingEntry.rawmaterialmix.id,
              code: editingEntry.rawmaterialmix.code,
              group: editingEntry.rawmaterialmix.group,
              type: 'rawmaterialmix'
            },
            note: editingEntry.note || '',
          });
        }
      } else {
        this.setState({
          quantityPercentage: 0,
          rawMaterial: undefined,
          note: '',
        });
      }
    }
  }

  render() {
    const {
      isOpen,
      rawMaterialOptions,
      onDismiss,
    } = this.props;

    const {
      rawMaterial,
      quantityPercentage,
      note,
    } = this.state;
    
    return (
      <Dialog isOpen={isOpen}>
        <div className={Classes.DIALOG_BODY}>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Raw Material</label>
            <div className="col-sm-8">
              <RawMaterialSelect
                items={rawMaterialOptions}
                selectedItem={rawMaterial}
                onItemSelect={(e: RawMaterialOption) => this.setState({rawMaterial: e})}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Quantity (%)</label>
            <div className="col-sm-8">
              <NumericInput
                className="bp3-fill"
                allowNumericCharactersOnly={true}
                max={100}
                min={0}
                value={quantityPercentage}
                onValueChange={v => this.setState({quantityPercentage: v})}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Note</label>
            <div className="col-sm-8">
              <input 
                type="text" 
                className="bp3-input" 
                value={note}
                onChange={e => this.setState({note: e.target.value})}
              />
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => onDismiss()}>Close</Button>
            <Button className="bp3-intent-primary" onClick={() => this.save()}>Save</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default FormulationEntryDialog;
