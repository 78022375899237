import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { capitalize } from 'lodash'

import { AgGridReact } from 'ag-grid-react';

import { hasPermission } from '../../../lib/auth/permission';
import { Document, User } from '../../../types';
import { DocumentTypes } from '../../../lib/content/statics';
import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';

export interface Props {
  entries: User[]
  onClick: (user: User) => void
}

interface States {
  columnDefs: any[]
}

class UsersGrid extends PureComponent<Props, States> {
  gridApi: any

  constructor(props: any) {
    super(props);

    this.state = {
      columnDefs: [],
    };
  }

  getColumnDef() {
    return [{
        headerName: "Full name", 
        field: "username", 
        resizable: true,    
        filter: true
      }, {
        headerName: "E-Mail", 
        field: "email", 
        resizable: true,    
        filter: true,
      }, {
        headerName: "Role", 
        field: "role.name", 
        resizable: true,    
        filter: true,
        valueFormatter: (params: ValueFormatterParams) => capitalize(params.value)
      }, {
        headerName: "Blocked", 
        field: "blocked", 
        resizable: true,    
        filter: true,
      }];
  }

  onGridReady(grid: any) {
    this.gridApi = grid;
    this.gridApi.sizeColumnsToFit();
  }

  render() {
    return (
      <div 
        className="ag-theme-balham"
        style={{ 
        height: '500px' }} 
      >
        <AgGridReact
          onRowClicked={ ({data}) => this.props.onClick(data) }
          onGridReady={ params => this.onGridReady(params.api) }
          floatingFilter={true}
          columnDefs={this.getColumnDef()}
          rowData={this.props.entries}>
        </AgGridReact>
      </div>
    );
  }
}

export default UsersGrid;
