import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";

import { hasPermission } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Document, AdinoProduct, User, Activity } from '../../../types';
import { loadActivities } from '../../../redux/activities';
import UsersGrid from '../../components/molecules/UsersGrid';
import ActivitiesGrid from '../../components/molecules/ActivitiesGrid';

interface Props {
  activities: Activity[]
  history: any
  loadActivities: () => void
}
interface States {
}

class ActivitiesPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.props.loadActivities();
  }

  render() {
    const {
      activities,
    } = this.props;
    const {
    } = this.state;

    return (
      <>
        <div className="container-fluid">
          <h4 className="mt-2">
            Activities
          </h4>
          <ActivitiesGrid
            entries={activities}
            onClick={() => false}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({activities} : any, ownProps: any) => {
  return {
    activities: activities.activities,
  };
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadActivities
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesPage)

export default withRouter(pageRedux);
