import React from 'react';
import { MultiSelect, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import { indexOf } from 'lodash';

import { ApplicationCategory } from '../../../types'
import { MenuItem, Button } from '@blueprintjs/core';
import { highlightTextForQuery } from '../../../helper/highlight-text';

interface Props {
  items: ApplicationCategory[], 
  selectedItems: ApplicationCategory[],
  onItemSelect: (v: ApplicationCategory) => void,
}

const TApplicationCategorySelect = MultiSelect.ofType<ApplicationCategory>();

const renderApplicationCategory = (
  applicationCategory: ApplicationCategory, 
  { handleClick, modifiers, query }: any, 
  selectedItems: ApplicationCategory[]) => {
  if (!modifiers.matchesPredicate) {
      return null;
  }
  const text = `${applicationCategory.name}`;
  return (
      <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          icon={indexOf(selectedItems, applicationCategory) > -1 ? "tick" : "blank"}
          key={applicationCategory.id}
          onClick={handleClick}
          text={highlightTextForQuery(text, query)}
      />
  );
};

const filterApplicationCategory: ItemPredicate<ApplicationCategory> = (query, applicationCategory, _index, exactMatch) => {
  const normalizedTitle = applicationCategory.name.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
      return normalizedTitle === normalizedQuery;
  } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
  }
};

const handleRemoveTag = function(_tag: any, index: any, selectedItems: any, onItemSelect: any) {
  onItemSelect(selectedItems[index]);
}

export default function ApplicationCategorySelect({items, selectedItems, onItemSelect}: Props) {
  return (
    <TApplicationCategorySelect
      className="bp3-fill"
      itemRenderer={(a, b) => renderApplicationCategory(a, b, selectedItems)}
      itemPredicate={filterApplicationCategory}
      items={items}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={onItemSelect}
      selectedItems={selectedItems}
      tagRenderer={c => c.name}
      tagInputProps={{ onRemove: (a, b) => handleRemoveTag(a, b, selectedItems, onItemSelect) }}
    />
  );
}
