import {
  upperCase
} from 'lodash';

import {
  Document
} from '../../types';

const docs = {
  getName: (d: Document) => {
    if (!d) return '';

    if (d.type === 'tds' || d.type === 'msds') {
      return `${upperCase(d.type)} for ${d.adinoproduct!.name}`;
    }
    return d.name;
  },
};

export default {
  docs,
};
