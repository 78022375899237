import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useFormik } from 'formik'
import DocumentsTable from '../../components/organisms/DocumentsTable'
import { Document } from '../../../types'
import { addDocument } from '../../../redux/articleCreator';
import { updateDocument } from '../../../redux/articleCreator';
import { removeDocument } from '../../../redux/articleCreator';

interface Props {
  formik: any,
  onBack: () => {},
  documents: Document[],
  addDocument: (e: Document) => {},
  updateDocument: (old: Document, newDoc: Document) => {},
  removeDocument: (e: Document) => {},
  creatingArticle: boolean
}

const Step3 = ({ formik, onBack, documents, addDocument, updateDocument, removeDocument, creatingArticle }: Props) => {
  return (
    <>
      <DocumentsTable
        id="article-cr-s3"
        editModeAddEntry={(e: Document) => addDocument(e)}
        editModeUpdateEntry={(old, newDoc) => updateDocument(old, newDoc)}
        editModeRemoveEntry={doc => removeDocument(doc)}
        entries={documents}
        editMode={true}
        canAddDocuments={true}
        showRevision={true}
        showVisibleTo={true}
        onDeleteDocument={() => {}}
        canDeleteDocuments={false}
      />
      <button className="btn btn-outline-secondary" onClick={() => onBack()}>
        Back
      </button>
      <button type="submit" className="btn btn-primary" disabled={creatingArticle}>
        {(formik.values.ownFormula && formik.values.ownFormula[0] === "on") && (
          <>Next step</>
        ) || (
          <>Create article</>
        )}
      </button>
    </>
  )
}

const mapStateToProps = ({ articleCreator }: any) => ({
  documents: articleCreator.documents,
  creatingArticle: articleCreator.creatingArticle,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  addDocument, updateDocument, removeDocument
}, dispatch)

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step3 as any)

export default withRouter(pageRedux)
