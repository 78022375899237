import React from 'react';

import { lowerCase, startsWith, endsWith, words, get, capitalize, unionBy, uniqBy } from 'lodash';

import { GlobalSearchResult, Document, PermissionRole } from '../../types';
import { DocumentTypes } from '../content/statics';
import { hasRoleOrMore, requiredRoleForFields } from '../auth/permission';

interface Path {
  path: string
  weight: number
  subPath?: string
  fieldName: string
  requiredRole: PermissionRole
}

function scoreMatch(content: string, query: string): number {
  if (content.length < 2) return 0;

  let points = 0;

  const contentLower = lowerCase(content);

  // whole content + each word
  const fragments = [
    contentLower,
    ...words(contentLower)
  ];
  fragments.forEach((cont, idx) => {
    let p = 0;

    const starts = startsWith(cont, query);
    const ends = endsWith(cont, query);
    const middle = cont.indexOf(query) > 0 && !ends;
    const exact = cont == query;
  
    if (middle) {
      p = 20;
    }
    if (ends) {
      p = 50;
    }
    if (starts) {
      p = 80;
    }
    if (exact) {
      p = 150;
    }

    points += p * (idx > 0 ? 0.8 : 1.0);
  });

  return points;
}

export default function filterGlobalSearch(query: string, articlesAll: any[], documentsAll: Document[], currentRole: PermissionRole): GlobalSearchResult[] {
  if (query.length < 2) {
    return [];
  }

  let articles = articlesAll;

  if (!hasRoleOrMore(currentRole, 'administration')) {
    // filter duplicate articles
    articles = uniqBy(articles, 'code');
  }

  // don't show TDS + MSDS in search result
  let documents = documentsAll.filter(d => (d.type !== 'msds' && d.type !== 'tds'));

  const paths: Path[] = [
    {
      path: 'code',
      weight: 2.0,
      fieldName: 'Article code',
      requiredRole: requiredRoleForFields['article.code'],
    }, {
      path: 'adinoproduct.name',
      weight: 2.0,
      fieldName: 'Product name',
      requiredRole: requiredRoleForFields['adinoproduct.name'],
    }, {
      path: 'adinoproduct.description',
      weight: 1.5,
      fieldName: 'Description',
      requiredRole: requiredRoleForFields['adinoproduct.description'],
    }, {
      path: 'trialProduct',
      weight: 1.0,
      fieldName: 'Trial product',
      requiredRole: requiredRoleForFields['article.trialProduct'],
    }, {
      path: 'barcodeNumber',
      weight: 0.4,
      fieldName: 'Barcode',
      requiredRole: requiredRoleForFields['article.barcodeNumber'],
    }, {
      path: 'adinoproduct.applicationcategories',
      subPath: 'name',
      weight: 0.5,
      fieldName: 'Application category',
      requiredRole: requiredRoleForFields['adinoproduct.applicationcategories'],
    }, {
      path: 'adinoproduct.productgroup.name',
      weight: 1.0,
      fieldName: 'Product group',
      requiredRole: requiredRoleForFields['adinoproduct.productgroup'],
    }, {
      path: 'adinoproduct.remark',
      weight: 0.8,
      fieldName: 'Remark (Product)',
      requiredRole: requiredRoleForFields['adinoproduct.remark'],
    }, {
      path: 'remark',
      weight: 0.8,
      fieldName: 'Remark (Article)',
      requiredRole: requiredRoleForFields['article.remark'],
    }, {
      path: 'vendor.name',
      weight: 1.0,
      fieldName: 'Vendor',
      requiredRole: requiredRoleForFields['article.vendor'],
    }, {
      path: 'vendorCode',
      weight: 0.8,
      fieldName: 'Vendor code',
      requiredRole: requiredRoleForFields['article.vendorCode'],
    }, {
      path: 'packaging',
      weight: 0.7,
      fieldName: 'Packaging',
      requiredRole: requiredRoleForFields['article.packaging'],
    }
  ].filter(p => hasRoleOrMore(currentRole, p.requiredRole));

  const pathsDocuments: Path[] = [{
    path: 'name',
    weight: 1.5,
    fieldName: 'Document',
    requiredRole: 'customer' as PermissionRole,
  }, {
    path: 'language',
    weight: 0.3,
    fieldName: 'Document language',
    requiredRole: 'customer' as PermissionRole,
  }, {
    path: 'type',
    weight: 2.0,
    fieldName: 'Document type',
    requiredRole: 'customer' as PermissionRole,
  }, {
    path: 'adinoproduct.name',
    weight: 2.0,
    fieldName: 'Product',
    requiredRole: 'customer' as PermissionRole,
  }].filter(p => hasRoleOrMore(currentRole, p.requiredRole));

  const searchResults: GlobalSearchResult[] = [];

  searchResults.push(...getSearchResults(articles, paths, query, 'article', currentRole));
  searchResults.push(...getSearchResults(documents, pathsDocuments, query, 'document', currentRole));

  return searchResults.sort((a, b) => b.points - a.points);
}

function getSearchResults(objects: any[], paths: Path[], query: string, resultType: 'article' | 'document', currentRole: PermissionRole) {
  return objects.map((article: any) => {
    let points = 0;
    let bestPaths: {
      points: number,
      path: Path,
      value: string,
    }[] = [];

    const queryLower = lowerCase(query);

    // whole content + each word
    const fragments = [
      queryLower,
      ...words(queryLower)
    ];
    fragments.forEach((qu, idx) => {

      paths.forEach((path) => {
        let values: string[];
        if (path.subPath) {
          values = (get(article, path.path) || []).map((v: any) => get(v, path.subPath!) || '');
        } else {
          values = [
            get(article, path.path) || ''
          ];
        }

        values.forEach(value => {
          const p = scoreMatch(
            value,
            qu
          ) * path.weight;
  
          points += p * (idx > 0 ? 0.8 : 1.0);
  
          bestPaths.push({
            points: p,
            path,
            value
          });
        });
      });
    });  

    if (resultType === 'article') {
      const subtitle: JSX.Element = <>
        <span>
          Article Code: {article.code} | Packaging: {article.packaging}
        </span>
        <div className="mt-2">
          {article.adinoproduct.description}
        </div>
      </>;
  
      const articleTitle = article.adinoproduct.name;

      let title = articleTitle;
      if (hasRoleOrMore(article.requiredRole, 'administration')) {
        title = (
          <>
            {title}
            <span className="badge badge-warning ml-2">internal only</span>
          </>
        );
      }

      return {
        type: 'article',
        title,
        subtitle: subtitle,
        link: `/article/${article.id}`,
        points
      };
    } else {
      const subtitle = 
        <>
          <span>{DocumentTypes[article.type]}</span>
          &nbsp;<span className="badge badge-secondary">{capitalize(article.language)}</span>
          {article.adinoproduct && (
            <>
            &nbsp;|&nbsp;
            {article.adinoproduct.name}
            </>
          )}
        </>;
      return {
        type: 'document',
        title: article.name,
        subtitle: subtitle,
        link: `/docs/${article.id}`,
        points
      };
    }
  })
    .filter(r => r.points > 0);
}
