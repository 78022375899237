import { ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { Formulation } from '../../../types';

export interface Props {
  entries: Formulation[]
  onClick: (doc: Formulation) => void
}

interface States {
  columnDefs: any[]
}

class FormulationsGrid extends PureComponent<Props, States> {
  gridApi: any

  constructor(props: any) {
    super(props);

    this.state = {
      columnDefs: [],
    };
  }

  getColumnDef() {
    return [{
        headerName: "Name", 
        field: "name", 
        resizable: true,    
        filter: true
      }, {
        headerName: "Revision date",
        field: "revisionDate",
        resizable: true,    
        filter: true,
        valueFormatter: (params: ValueFormatterParams) => params.value && moment(params.value).format('YYYY-MM-DD')
      }, {
        headerName: "Revision",
        field: "revisionVersion",
        resizable: true,    
        filter: true,
      }];
  }

  onGridReady(grid: any) {
    this.gridApi = grid;
    this.gridApi.sizeColumnsToFit();
  }

  render() {
    return (
      <div 
        className="ag-theme-balham"
        style={{ 
        height: '500px' }} 
      >
        <AgGridReact
          onRowClicked={ ({data}) => this.props.onClick(data) }
          onGridReady={ params => this.onGridReady(params.api) }
          floatingFilter={true}
          columnDefs={this.getColumnDef()}
          rowData={this.props.entries}>
        </AgGridReact>
      </div>
    );
  }
}

export default FormulationsGrid;
