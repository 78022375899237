import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";

import { hasPermission, hasRoleOrMore, requiredRoleForFields } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Formik } from 'formik'
import { Formulation, User, PermissionRole } from '../../../types';
import { updateArticle } from '../../../redux/articleCreator';
import Step1 from '../article-creator/step1';
import { validateArticle } from '../../../lib/validate';
import showFormErrors from '../../../helper/show-form-errors';
import { loadArticlesData } from '../../../redux/articles';
import { setAjaxLoading } from '../../../redux/user';

interface Props {
  article: any
  role: PermissionRole
  history: any
  updateArticle: (id: number, values: any) => Promise<void>
  loadArticlesData: (role: PermissionRole, othersQueries?: string[]) => Promise<void>
  setAjaxLoading: (p: boolean) => void
}
interface States {
  sending: boolean
}

class EditArticle extends Component<Props, States> {
  private formik: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      sending: false,
    };
  }

  componentDidMount() {
    let values: any = {
      articleCode: this.props.article.code,
      packaging: this.props.article.packaging,
      vendor: this.props.article.vendor,
      vendorCode: this.props.article.vendorCode,
      ownFormula: this.props.article.ownFormula ? ['on'] : false,
      labelFileName: this.props.article.label && this.props.article.label.name,
      labelFileId: this.props.article.label && this.props.article.label.id,
      imageFileName: this.props.article.image && this.props.article.image.name,
      imageFileId: this.props.article.image && this.props.article.image.id,
      barcodeNumber: this.props.article.barcodeNumber,
      articleTrialProduct:  this.props.article.trialProduct,
      articleRemark:  this.props.article.remark,
      internalOnly: hasRoleOrMore(this.props.article.requiredRole, 'administration') ? ['on'] : false, 
    };
    if (hasRoleOrMore(this.props.role, requiredRoleForFields['article.cost'])) {
      values = {
        ...values,
        articleCost: this.props.article.cost, 
        articleCostUnit: this.props.article.costUnit, 
        articleCostUpdatedDate: this.props.article.costUpdatedDate, 
      };
    }
    this.formik && this.formik.setValues(values);
  }

  async submit(values: any) {
    const {
      history, article, updateArticle, loadArticlesData, role
    } = this.props;

    const formErrors = validateArticle(values);
    if (formErrors.length > 0) {
      showFormErrors(formErrors);
      return;
    }

    this.props.setAjaxLoading(true);

    this.setState({
      sending: true,
    });
    try {
      await updateArticle(article.id, values);
      await loadArticlesData(role);
      this.props.setAjaxLoading(false);
      history.push(`/article/${article.id}`);
    } catch (err) {
      console.error(err);
      alert('Error: '+JSON.stringify(err));
      this.props.setAjaxLoading(false);
    }

    this.setState({
      sending: false,
    });
  }

  render() {
    const {
      article
    } = this.props;
    const {
      sending
    } = this.state;

    const Step1any = Step1 as any;

    return (
      <>
        <div className="container-fluid">
          <Link to={`/article/${article.id}`}>
            <small className="">&lt;&lt; back</small>
          </Link>
        </div>
        <div className="container mt-2">
          <h4>Edit {article.code}</h4>

          <Formik
              initialValues={{}}
              onSubmit={v => this.submit(v)}>
              {formik => {
                this.formik = formik;
                return (
                  <form onSubmit={formik.handleSubmit}>

                    <Step1any
                      updateExisting={true}
                      disableSubmit={sending}
                      formik={formik} />

                  </form>
                )
              }}
          </Formik>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ articleCreator, articles, user, formulations } : any, ownProps: any) => {
  return {
    article: 
      (articles.articles.filter((a: any) => a.id === ownProps.match.params.id)[0]),
    role: user.activeRole,
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      updateArticle, loadArticlesData, setAjaxLoading
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditArticle as any)

export default withRouter(pageRedux);
