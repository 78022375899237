import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";

import { hasPermission } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Document, AdinoProduct, PermissionRole } from '../../../types';
import DocumentsGrid from '../../components/molecules/DocumentsGrid';
import DocumentDialog from '../../components/organisms/DocumentDialog';
import { createDocument } from '../../../redux/documents';
import { loadArticlesData, loadDocuments } from "../../../redux/articles";
import { setAjaxLoading } from '../../../redux/user';

interface Props {
  documents: Document[]
  canAddDocuments: boolean
  createDocument: (doc: Document) => {}
  loadArticlesData: (role: PermissionRole, othersQueries?: string[]) => {}
  loadDocuments: () => {}
  history: any
  adinoproducts: AdinoProduct[]
  showVisibleTo: boolean
  role: PermissionRole
  setAjaxLoading: (p: boolean) => void
}
interface States {
  dialogEntryIsOpen: boolean
  editingEntry?: Document
}

class DocumentsPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dialogEntryIsOpen: false,
    };
  }

  openNewEntry = () => {
    this.setState({
      dialogEntryIsOpen: true,
      editingEntry: undefined,
    });
  }

  async createOrUpdateDocument(doc: Document) {
    this.props.setAjaxLoading(true);

    await this.props.createDocument(doc);

    // reload all data
    Promise.all([
      this.props.loadArticlesData(this.props.role),
      this.props.loadDocuments()
    ])
    .then(() => this.props.setAjaxLoading(false))
    .catch(e => {
      this.props.setAjaxLoading(false);
      console.error(e);
    });

    this.setState({
      dialogEntryIsOpen: false,
    });
  }

  render() {
    const {
      documents,
      canAddDocuments,
      adinoproducts,
      showVisibleTo,
    } = this.props;
    const {
      dialogEntryIsOpen,
      editingEntry,
    } = this.state;

    return (
      <>
        <div className="container-fluid">
          <Link to={`/`}>
            <small className="">&lt;&lt; back</small>
          </Link>
          <h4 className="mt-2">
            Documents
            {canAddDocuments && (
              <small className="ml-2">
                <a href="#" onClick={() => this.openNewEntry()}>
                  <i className="material-icons mr-1" style={{verticalAlign: 'middle'}}>add_circle</i>
                  Add document
                </a>
              </small>
            )}
          </h4>
          <DocumentsGrid
            entries={documents}
            onClick={data => {
              this.props.history.push('/docs/' + data.id);
            }}
            showVisibleTo={showVisibleTo}
          />
          <DocumentDialog
            isOpen={dialogEntryIsOpen} 
            onDismiss={() => this.setState({dialogEntryIsOpen: false}) as any}
            onSave={async (e) => {
              await this.createOrUpdateDocument(e);
            }}
            editingEntry={editingEntry}
            adinoproducts={adinoproducts}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({user, articles} : any, ownProps: any) => {
  return {
    documents: articles.documents,
    adinoproducts: articles.adinoproducts,
    canAddDocuments: user.user && hasPermission(user.activeRole, 'add_product_documents'),
    showVisibleTo: user.user && hasPermission(user.activeRole, 'show_visible_to'),
    role: user.activeRole,
  };
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      createDocument, loadArticlesData, loadDocuments, setAjaxLoading
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsPage)

export default withRouter(pageRedux);
