import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";

import { hasPermission } from '../../../lib/auth/permission';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Formik } from 'formik'
import { Formulation, User, AdinoProduct, PermissionRole } from '../../../types';
import { updateProduct } from '../../../redux/articleCreator';
import Step2 from '../article-creator/step2';
import { validateProduct } from '../../../lib/validate';
import showFormErrors from '../../../helper/show-form-errors';
import { loadArticlesData } from '../../../redux/articles';
import { setAjaxLoading } from '../../../redux/user';

interface Props {
  history: any
  product: AdinoProduct
  role: PermissionRole
  updateProduct: (id: number, values: any) => Promise<void>
  loadArticlesData: (role: PermissionRole, othersQueries?: string[]) => Promise<void>
  setAjaxLoading: (p: boolean) => void
}
interface States {
  sending: boolean
}

class EditProduct extends Component<Props, States> {
  private formik: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      sending: false,
    };
  }

  componentDidMount() {
    this.formik && this.formik.setValues({
      createNewProduct: true, 
      productName: this.props.product.name,
      productDescription: this.props.product.description,
      productTxtApplications: this.props.product.txtApplications,
      productTxtCharacteristics: this.props.product.txtCharacteristics,
      productRemark:  this.props.product.remark,
      productGroup:  this.props.product.productgroup,
      applicationCategories:  this.props.product.applicationcategories,
    });
  }

  async submit(values: any) {
    const {
      history, product, updateProduct, loadArticlesData, role, setAjaxLoading
    } = this.props;

    const formErrors = validateProduct(values);
    if (formErrors.length > 0) {
      showFormErrors(formErrors);
      return;
    }

    this.setState({
      sending: true,
    });
    try {
      setAjaxLoading(true);
      await updateProduct(product.id!, values);
      await loadArticlesData(role);
      setAjaxLoading(false);
      history.push(`/`);
    } catch (err) {
      setAjaxLoading(false);
      console.error(err);
      alert('Error: '+JSON.stringify(err));
    }

    this.setState({
      sending: false,
    });
  }

  render() {
    const {
      product
    } = this.props;
    const {
      sending
    } = this.state;

    const Step2any = Step2 as any;

    return (
      <>
        <div className="container-fluid">
          <Link to={`/`}>
            <small className="">&lt;&lt; back</small>
          </Link>
        </div>
        <div className="container mt-2">
          <h4>Edit {product.name}</h4>

          <Formik
              initialValues={{}}
              onSubmit={v => this.submit(v)}>
              {formik => {
                this.formik = formik;
                return (
                  <form onSubmit={formik.handleSubmit}>

                    <Step2any
                      updateExisting={true}
                      disableSubmit={sending}
                      formik={formik} />

                  </form>
                )
              }}
          </Formik>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ user, articles } : any, ownProps: any) => {
  return {
    product: 
      (articles.adinoproducts.filter((a: any) => a.id === ownProps.match.params.id)[0]),
    role: user.activeRole,
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      updateProduct, loadArticlesData, setAjaxLoading
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProduct as any)

export default withRouter(pageRedux);
