export const REMOTE_HOST = process.env.REACT_APP_HOST;
export const REMOTE_API_HOST = process.env.REACT_APP_API_HOST;

export function authHeader() {
  // return authorization header with jwt token
  let jwt = localStorage.getItem('jwt');

  if (jwt) {
      return { 'Authorization': 'Bearer ' + jwt };
  } else {
      return {};
  }
}
