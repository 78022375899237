import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy, capitalize } from "lodash";
import moment from 'moment';

import { withRouter } from 'react-router';
import { updateUser, loadUsers, changeMyPassword } from '../../../redux/user';
import { Formik } from 'formik';

interface Props {
  username: string
  history: any
  changeMyPassword: (pass: string) => Promise<void>
}
interface States {
  loading?: boolean
}

class ChangePwPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  async onSubmit(values: any) {
    if (values.pass.length < 6) {
      alert("Password needs to be at least 6 characters long.");
      return;
    }
    if (values.pass !== values.pass2) {
      alert("Please repeat your new password correctly.");
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      await this.props.changeMyPassword(values.pass);
      alert("Password changed.");
    } catch (err) {
      alert('Something went wrong. ' + JSON.stringify(err));
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const {
      username,
    } = this.props;
    const {
      loading,
    } = this.state;

    return (
      <>
        <div className="container mt-2">
          <h4>
            Change password
          </h4>

          {username && (
            <>
              <Formik
                initialValues={{
                  pass: '',
                  pass2: ''
                }}
                onSubmit={v => this.onSubmit(v)}>
                {formik => (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                      <label>Your user</label>
                      <input 
                        type="text"
                        disabled={true} 
                        className="form-control" 
                        value={username}
                      />
                    </div>
                    <div className="form-group">
                      <label>New password</label>
                      <input 
                        type="password" 
                        className="form-control" 
                        placeholder="Password" 
                        name="pass"
                        onChange={formik.handleChange}
                        value={formik.values.pass}
                      />
                    </div>
                    <div className="form-group">
                      <label>Repeat new password</label>
                      <input 
                        type="password" 
                        className="form-control" 
                        placeholder="Password" 
                        name="pass2"
                        onChange={formik.handleChange}
                        value={formik.values.pass2}
                      />
                    </div>
                    <button disabled={loading} type="submit" className="btn btn-primary">Change password</button>
                  </form>
                )}
            </Formik>
          </>
        )}
        </div>
      </>
    )
  }
}

const mapStateToProps = ({user} : any, ownProps: any) => {
  return {
    username: user.user && user.user.username,
  };
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      changeMyPassword
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePwPage as any)

export default withRouter(pageRedux);
