import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, uniqBy } from "lodash";

import ArticlesGrid from "../../components/organisms/ArticlesGrid"
import { doGlobalSearch, setFilter } from "../../../redux/articles";
import { loadUserData } from "../../../redux/user";
import { hasPermission } from '../../../lib/auth/permission';
import { ApplicationCategory, GlobalSearchResult, PermissionRole } from '../../../types';
import { Redirect } from 'react-router';
import { authHeader } from '../../../lib/rpc';
import GlobalSearchResults from '../../components/molecules/GlobalSearchResults';
import ArticleSearch from '../../components/organisms/ArticleSearch';

interface Props {
  loadUserData: () => void;
  showArticleDetails: boolean;
  applicationcategories: ApplicationCategory[];
  articles: any[];
  loggedIn: boolean;
  searchResults: GlobalSearchResult[];
  doGlobalSearch: (s: string, role: PermissionRole) => void;
  globalSearchQuery: string;
  role: PermissionRole;
  setFilter: (filterApplicationcategory: string | undefined, showAllArticles: boolean) => void;
  filterApplicationcategory?: string;
  showAllArticles: boolean;
}
interface States {
}

class HomePage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  toggleApplicationcategory(t?: string) {
    if (this.props.globalSearchQuery) {
      this.props.doGlobalSearch('', 'customer');
    }

    if (this.props.filterApplicationcategory === t) {
      t = '';
    }
    this.props.setFilter(
      t, !t
    );
  }

  filterData(data: any[]) {
    let fdata = data;
    if (!this.props.showArticleDetails) {
      // filter duplicate articles
      fdata = uniqBy(fdata, 'code');
    }
    if (this.props.filterApplicationcategory) {
      return fdata.filter(d => includes(
        d.adinoproduct.applicationcategories.map((a: ApplicationCategory) => a.name), 
        this.props.filterApplicationcategory))
    } else {
      return fdata;
    }
  }

  render() {
    if (!authHeader().Authorization) {
      return <Redirect to='/login' />
    }

    const {
      applicationcategories,
      articles,
      searchResults,
      doGlobalSearch,
      globalSearchQuery,
      role,
      filterApplicationcategory, 
      showAllArticles,
      setFilter,
    } = this.props;

    const ArticlesGridAny: any = ArticlesGrid;
    const ArticleSearchAny: any = ArticleSearch;

    const showArticlesGrid = showAllArticles || filterApplicationcategory;
    const showGlobalSearchResults = !showArticlesGrid && globalSearchQuery.length >= 2;
    
    return (
      <>
        <div className="container-fluid">
          <div className="row mt-4 mb-4">
            <div className="col-9">
              <p className="lead">Welcome to the Adino Product Database - Your portal to detailed information and literature on our product range</p>
              <p>Visit the Adino webpage <a href="http://adinoklebstoffe.de/" target="_blank">here</a></p>
            </div>
            <div className="col-3">
              <ArticleSearchAny isOnHome={true} />
            </div>
          </div>
        </div>

        <div className="container-fluid mt-5">
          {showArticlesGrid && (
            <ArticlesGridAny
              rowData={this.filterData(articles)} />
          )}
          {showGlobalSearchResults && (
            <>
              <h6>Search results</h6>
              <GlobalSearchResults
                results={searchResults} />
              </>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ articles, user } : any) => ({
  articles: articles.articles,
  applicationcategories: articles.applicationcategories,
  showArticleDetails: user.user && hasPermission(user.activeRole, 'read_article_details'),
  loggedIn: user.user !== null,
  searchResults: articles.globalSearchResults,
  globalSearchQuery: articles.globalSearchQuery,
  role: user.activeRole,
  filterApplicationcategory: articles.filterApplicationcategory,
  showAllArticles: articles.showAllArticles,
})

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadUserData,
      doGlobalSearch,
      setFilter,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage)
