import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { AgGridReact } from 'ag-grid-react';

import { hasPermission, hasRoleOrMore, requiredRoleForFields } from '../../../lib/auth/permission';

class ArticlesGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [],
      rowData: []
    };
  }

  getColumnDef() {
    const {
      role,
      showArticleDetails
    } = this.props;

    const columns = {
      'adinoproduct.name' : {
        headerName: "Product", 
        field: "adinoproduct.name", 
        resizable: true,
        filter: true
      },
      'code' : {
        headerName: "Article", 
        field: "code", 
        resizable: true,    
        filter: true,
        cellRenderer: (params) => {
          if (params.data.requiredRole && hasRoleOrMore(params.data.requiredRole, 'administration')) {
            return `${params.getValue()} (internal only)`;
          }
          return params.getValue();
        }
      },
      'trialProduct' : {
        headerName: "Trial Product", 
        field: "trialProduct", 
        resizable: true,    
        filter: true,
        hide: !showArticleDetails
      },
      'packaging' : {
        headerName: "Packaging", 
        field: "packaging", 
        resizable: true,    
        filter: true,
      },
      'vendor.name' : {
        headerName: "Vendor", 
        field: "vendor.name", 
        resizable: true,    
        filter: true,
        hide: !showArticleDetails
      },
      'vendorCode' : {
        headerName: "Vendor Code",
        field: "vendorCode",
        resizable: true,    
        filter: true,
        hide: !showArticleDetails
      },
      'adinoproduct.productgroup.name' : {
        headerName: "Product Group",
        field: "adinoproduct.productgroup.name",
        resizable: true,    
        filter: true,
        hide: !showArticleDetails
      },
      'adinoproduct.description' : {
        headerName: "Description", 
        field: "adinoproduct.description",
        resizable: true,
        filter: true
      },
      'remark' : {
        headerName: "Remark (Article)", 
        field: "remark", 
        resizable: true,    
        filter: true,
        hide: !showArticleDetails
      },
      'cost' : {
        headerName: "Cost", 
        field: "cost", 
        resizable: true,    
        filter: true,
        width: 120,
        hide: !hasRoleOrMore(role, requiredRoleForFields['article.cost']),
      },
      'costUnit' : {
        headerName: "Cost Unit",
        field: "costUnit",
        resizable: true,
        filter: true,
        hide: !hasRoleOrMore(role, requiredRoleForFields['article.cost']),
      },
      'costUpdatedDate' : {
        headerName: "Cost Updated",
        field: "costUpdatedDate",
        resizable: true,
        filter: true,
        hide: !hasRoleOrMore(role, requiredRoleForFields['article.cost']),
      },
      'adinoproduct.remark' : {
        headerName: "Remark (Product)", 
        field: "adinoproduct.remark", 
        resizable: true,    
        filter: true,
        hide: !showArticleDetails
      }
    };

    if (showArticleDetails) {
      return [
        columns['adinoproduct.name'],
        columns['code'],
        columns['trialProduct'],
        columns['vendor.name'],
        columns['vendorCode'],
        columns['adinoproduct.productgroup.name'],
        columns['adinoproduct.description'],
        columns['cost'],
        columns['costUnit'],
        columns['costUpdatedDate'],
        columns['remark'],
        columns['adinoproduct.remark'],
      ];
    } else {
      return [
        columns['adinoproduct.name'],
        columns['adinoproduct.description'],
        columns['packaging'],
        columns['code'],
      ];
    }
  }

  onGridReady(grid) {
    this.gridApi = grid;
    this.gridApi.sizeColumnsToFit();
  }

  openArticleDetails(data) {
    this.props.history.push('/article/' + data.id);
  }

  export(e) {
    e.preventDefault();
    this.gridApi.exportDataAsCsv({
      columnSeparator: ';'
    });
  }

  getRowStyle({node}) {
    if (node.data.requiredRole && hasRoleOrMore(node.data.requiredRole, 'administration')) {
      return {
        backgroundColor: '#ffc107',
      };
    }
  }

  render() {
    return (
      <div 
        className="ag-theme-balham"
        style={{ 
        height: '500px' }} 
      >
        <AgGridReact
          onRowClicked={ ({data}) => this.openArticleDetails(data) }
          onGridReady={ params => this.onGridReady(params.api) }
          floatingFilter={true}
          columnDefs={this.getColumnDef()}
          rowData={this.props.rowData}
          getRowStyle={params => this.getRowStyle(params)}
        >
        </AgGridReact>
        <button 
          onClick={e => this.export(e)} 
          className="btn btn-secondary btn-sm mt-2 mb-2"
        >
          <i className="material-icons mr-2" style={{verticalAlign: 'top'}}>cloud_download</i>
          Export for Excel
        </button>
      </div>
    );
  }
}

ArticlesGrid.propTypes = {
  rowData: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  showArticleDetails: PropTypes.bool.isRequired,
  role: PropTypes.string,
}


const mapStateToProps = ({ user }) => ({
  showArticleDetails: hasPermission(user.activeRole, 'read_article_details'),
  role: user.activeRole,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticlesGrid)

export default withRouter(pageRedux);
