import {toastr} from 'react-redux-toastr';

import { logout, setInactive, SET_INACTIVE } from "./user";

function autoLogoutMiddlware(storeAPI: any) {
    return function wrapDispatch(next: any) {
        return function handleAction(action: any) {
            // Do anything here: pass the action onwards with next(action),
            // or restart the pipeline with storeAPI.dispatch(action)
            // Can also use storeAPI.getState() here

            if (storeAPI.getState().user.user && 
            !storeAPI.getState().user.isInactive && 
            action.type !== SET_INACTIVE) {
                const now = new Date().getTime();
                if (isInactive(now)) {
                    return next(setInactive());
                }
                localStorage.setItem('lastActivity', "" + now);
            }

            return next(action)
        }
    }
}

function isInactive(now: number) {
    const lastActivity = parseInt(localStorage.getItem("lastActivity") || "" + now, 10);
    return (now - lastActivity) > 10 * 60 * 1000;
}

export { isInactive }

export default autoLogoutMiddlware;
