import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { includes, find } from 'lodash'
import { Formik } from 'formik'

import { hasPermission } from '../../../lib/auth/permission'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import FormulationDataSheet from '../../components/organisms/FormulationDataSheet'
import { Formulation, PermissionRole } from '../../../types'
import { login, forgotPassword } from '../../../redux/user'
import { loadArticlesData, loadDocuments } from "../../../redux/articles";

interface Props {
  loadArticlesData: (role: PermissionRole, othersQueries?: string[]) => Promise<any>
  loadDocuments: () => Promise<any>
  history: any
  role: PermissionRole
  code: string
}

interface States {
  loading: boolean
}

class QrCodePage extends Component<Props, States> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  async onProduct(e: Event) {
    e.preventDefault();

    const { code, history, role } = this.props;
    this.setState({
      loading: true,
    });

    const data = await this.props.loadArticlesData(role)
    const article = find(data.articles, a => a.barcodeNumber === code);

    this.setState({
      loading: false,
    });

    if (article) {
      history.push(`/article/${article.id}`);
    } else {
      alert("Unknown article");
    }
  }

  render() {
    const {
      loading
    } = this.state

    return (
      <>
        <div className="landing-background">
          <div className="row">
            <div className="d-lg-none">
              <img src="/adino-bogen.png" className="img-fluid" />
            </div>
            <div className="col-md-12 d-lg-block qr-logo-large-container" style={{display:'none'}}>
              <img src="/adino-bogen.png" className="qr-logo-large" />
            </div>
            <div className="col-sm-6 col-md-4 mb-2">
              <a href="http://adinoklebstoffe.de">
                <div className="qr-link">
                  <i className="cursor material-icons">keyboard_arrow_right</i>
                  <h5>Find out more about ADINO</h5>
                </div>
              </a>
            </div>
            <div className="col-sm-6 col-md-4">
              <a href="#" onClick={(e) => this.onProduct(e as any)}>
                <div className={`qr-link qr-link-secondary ${loading && 'disabled'}`}>
                  <i className="cursor material-icons">keyboard_arrow_right</i>
                  <h5>Open product information</h5>
                </div>
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ user }: any, ownProps: any) => ({
  code: ownProps.match.params.code,
  role: user.activeRole,
})

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      login,
      forgotPassword,
      loadArticlesData,
      loadDocuments,
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(QrCodePage as any)

export default withRouter(pageRedux)
