import { AgGridReact } from 'ag-grid-react';
import React, { PureComponent } from 'react';
import { RawMaterial, PermissionRole, ApplicationCategory } from '../../../types';

import './ArticleSearch.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadUserData } from '../../../redux/user';
import { doGlobalSearch, setFilter } from '../../../redux/articles';
import { withRouter } from 'react-router';

export interface Props {
  globalSearchQuery: string
  doGlobalSearch: (s: string, role: PermissionRole, onlyUpdateField?: boolean) => void;
  setFilter: (filterApplicationcategory: string | undefined, showAllArticles: boolean) => void;
  role: PermissionRole;
  showAllArticles: boolean;
  applicationcategories: ApplicationCategory[];
  filterApplicationcategory?: string;
  history: any;
  isOnHome: boolean;
}

interface States {
}

class ArticleSearch extends PureComponent<Props, States> {

  constructor(props: any) {
    super(props);

    this.state = {
    };
  }

  toggleApplicationcategory(t?: string) {
    if (this.props.globalSearchQuery) {
      this.props.doGlobalSearch('', 'customer');
    }

    if (this.props.filterApplicationcategory === t) {
      t = '';
    }
    this.props.setFilter(
      t, !t
    );
    if (!this.props.isOnHome) {
      this.props.history.push(`/`);
    }
  }

  submit(e: Event) {
    e.preventDefault();
    if (!this.props.isOnHome) {
      this.props.doGlobalSearch(this.props.globalSearchQuery, this.props.role);
      this.props.history.push(`/`);
    }
  }

  render() {
    const {
      doGlobalSearch,
      setFilter,
      role,
      globalSearchQuery,
      showAllArticles,
      filterApplicationcategory,
      applicationcategories,
      history,
      isOnHome,
    } = this.props;

    return (
      <div className="article-search-box">
        <div className="card">
          <div className="card-header">
            Search Products
          </div>
          <div className="card-body">
            <div className="global-search-field-container">
              <div className="input-group mb-3">
                <form onSubmit={(e: any) => this.submit(e)}>
                  <input 
                    className="form-control form-control-sm" 
                    type="text" 
                    placeholder="Quick Search" 
                    onChange={e => {
                      doGlobalSearch(e.target.value, role, !isOnHome);
                      setFilter(undefined, false);
                    }} 
                    value={globalSearchQuery}
                  />
                </form>
              </div>
              <i className="material-icons">search</i>
            </div>
            <a 
              href="#"
              onClick={(e: any) => {
                e.preventDefault();
                setFilter(undefined, true);
                doGlobalSearch('', 'customer');
                if (!isOnHome) {
                  history.push(`/`);
                }
              }}
              className={"filter-link " + (showAllArticles && isOnHome ? 'active' : '')}
            >
              &gt; Show Complete Product List
            </a>

            <hr className="dashes" />

            <div className="dropdown">
              <a 
                href="#"
                onClick={(e: any) => {
                  e.preventDefault();
                }}
                className={"filter-link " + (filterApplicationcategory && isOnHome ? 'active' : '')}
                type="button" id="dropdownApplicationCategory" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              >
              &gt; Filter by Category
              <span style={{float:'right'}}>+</span>
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownApplicationCategory">
                <a className="dropdown-item" href="#" onClick={() => this.toggleApplicationcategory()}>(all)</a>
                {applicationcategories.map(document => (
                  document.name === filterApplicationcategory && (
                    <a key={document.id} className="dropdown-item" href="#" onClick={() => this.toggleApplicationcategory(document.name)}><strong>{document.name}</strong></a>
                  ) || (
                    <a key={document.id} className="dropdown-item" href="#" onClick={() => this.toggleApplicationcategory(document.name)}>{document.name}</a>
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ articles, user } : any) => ({
  applicationcategories: articles.applicationcategories,
  globalSearchQuery: articles.globalSearchQuery,
  role: user.activeRole,
  filterApplicationcategory: articles.filterApplicationcategory,
  showAllArticles: articles.showAllArticles,
})

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loadUserData,
      doGlobalSearch,
      setFilter,
    },
    dispatch
  )

const pageRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleSearch)

export default withRouter(pageRedux as any);
