import React from 'react';
import { Select, ItemRenderer, ItemPredicate } from "@blueprintjs/select";

import { ProductGroup } from '../../../types'
import { MenuItem, Button } from '@blueprintjs/core';
import { highlightTextForQuery } from '../../../helper/highlight-text';

import './LoadingOverlay.css';

interface Props {
}
export default function LoadingOverlay({}: Props) {
  return (
    <div className="loading-overlay">
        <img src="/ajax-loader.gif" style={{marginRight: 8}} />
        <>Loading ...</>
    </div>
  );
}
