import gql from 'graphql-tag';

import { includes } from 'lodash';

import client from '../helper/apollo-client';
import filterGlobalSearch from '../lib/search';
import { hasRoleOrMore, hasPermission } from '../lib/auth/permission';

export const LOAD_ARTICLES_DATA_REQUESTED = 'articles/LOAD_ARTICLES_DATA_REQUESTED';
export const LOAD_ARTICLES_DATA_SUCCESS = 'articles/LOAD_ARTICLES_DATA_SUCCESS';
export const LOAD_DOCUMENTS_SUCCESS = 'documents/LOAD_DOCUMENTS_SUCCESS';
export const DO_GLOBAL_SEARCH = 'articles/DO_GLOBAL_SEARCH';
export const SET_FILTER = 'articles/SET_FILTER';

export const GQL_QUERY_DOCUMENT =
  `id
  name
  language
  requiredRole
  revisionDate
  revisionVersion
  type
  file {
    name
  }
  historyFor
  adinoproduct { 
    id 
    name
  }`;

export const GQL_QUERY_PRODUCT =
  `id
  name
  description
  applicationcategories {
    id
    name
  }
  productgroup {
    id
    name
  }
  remark
  txtApplications
  txtCharacteristics
  productdocuments(where: {historyFor: 0}, sort: "name") {
    ${GQL_QUERY_DOCUMENT}
  }`;

const initialState = {
  articles: [],
  applicationcategories: [],
  rawmaterials: [],
  rawmaterialmixes: [],
  vendors: [],
  adinoproducts: [],
  productgroups: [],
  globalSearchQuery: '',
  globalSearchResults: [],
  documents: [],
  showAllArticles: false,
  filterApplicationcategory: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case SET_FILTER:
      return {
        ...state,
        filterApplicationcategory: action.payload[0],
        showAllArticles: action.payload[1],
      }

    case LOAD_ARTICLES_DATA_SUCCESS:
      const newState = {...state};
      if (action.payload.articles) {
        newState.articles = action.payload.articles;
      }
      if (action.payload.appcats) {
        newState.applicationcategories = action.payload.appcats;
      }
      if (action.payload.rawmats) {
        newState.rawmaterials = action.payload.rawmats;
      }
      if (action.payload.rawmatmixes) {
        newState.rawmaterialmixes = action.payload.rawmatmixes;
      }
      if (action.payload.vends) {
        newState.vendors = action.payload.vends;
      }
      if (action.payload.aprods) {
        newState.adinoproducts = action.payload.aprods;
      }
      if (action.payload.prodgrs) {
        newState.productgroups = action.payload.prodgrs;
      }
    
      return newState;

    case LOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload.docs
      }
  
    case DO_GLOBAL_SEARCH:
      const update = {
        globalSearchQuery: action.payload.query,
      };
      if (!action.payload.onlyUpdateField) {
        update.globalSearchResults = filterGlobalSearch(action.payload.query, state.articles, state.documents, action.payload.role);
      }
      return {
        ...state,
        ...update
      }

    default:
      return state
  }
}

export const loadArticlesData = function(currentRole, otherEntities = [
  'formulas', 
  'appcats', 
  'rawmatents', 
  'rawmats', 
  'rawmatmixes',
  'vends',
  'aprods',
  'prodgrs',
]) {
  if (!hasRoleOrMore(currentRole, 'management')) {
    otherEntities = otherEntities.filter(e => !includes([
      'formulas',
      'rawmatents', 
      'rawmats', 
      'rawmatmixes', 
    ], e))
  }
  if (!hasRoleOrMore(currentRole, 'administration')) {
    otherEntities = otherEntities.filter(e => !includes([
      'vends', 
      'aprods', 
      'prodgrs',
    ], e))
  }
  const otherEntitiesQueries = [];
  if (includes(otherEntities, 'formulas')) {
    otherEntitiesQueries.push(`formulas(sort: "name:asc") {
      id
      name
      revisionVersion
      revisionDate
      historyFor
      recordNo
      author {
        username
      }
      rawmaterialentries {
        id
      }
      formulationqualityspecifications(sort: "ordering:asc") {
        id
        parameter
        method
        valueMin
        valueMax
        valueUnit
        note
      }
      inprocessspecs(sort: "ordering:asc") {
        id
        parameter
        method
        valueMin
        valueMax
        valueUnit
        note
      }
    }`);
  }
  if (includes(otherEntities, 'appcats')) {
    otherEntitiesQueries.push(`appcats {
      id
      name
    }`);
  }
  if (includes(otherEntities, 'rawmatents')) {
    otherEntitiesQueries.push(`rawmatents(sort: "ordering:asc") {
      id
      ppm
      rawmaterial {
        id
      }
      rawmaterialmix {
        id
      }
      note
    }`);
  }
  if (includes(otherEntities, 'rawmats')) {
    otherEntitiesQueries.push(`rawmats {
      id
      code
      description
      company
      distributor
      group
    }`);
  }
  if (includes(otherEntities, 'rawmatmixes')) {
    otherEntitiesQueries.push(`rawmatmixes {
      id
      code
      description
      group
      remark
      rawmaterialentries {
        id
      }
    }`);
  }
  if (includes(otherEntities, 'vends')) {
    otherEntitiesQueries.push(`vends {
      id
      name
    }`);
  }
  if (includes(otherEntities, 'aprods')) {
    otherEntitiesQueries.push(`aprods {
      ${GQL_QUERY_PRODUCT}
    }`);
  }
  if (includes(otherEntities, 'prodgrs')) {
    otherEntitiesQueries.push(`prodgrs {
      id
      name
    }`);
  }

  return (dispatch) => {
    return client.get().query({
      query: gql`
        query IndexQuery {
          articles(sort: "code:asc") {
            id
            code
            adinoproduct {
              ${GQL_QUERY_PRODUCT}
            }
            vendor {
              id
              name
            }
            vendorCode
            packaging
            ownFormula
            formulation {
              id
            }
            label {
              name
            }
            image {
              name
            }
            barcodeNumber
            requiredRole
            trialProduct
            remark
            cost
            costUnit
            costUpdatedDate
          }
          ${otherEntitiesQueries.join('\n')}
        }
      `,
      fetchPolicy: 'no-cache',
    })
      .then(({data}) => {
        dispatch({
          type: LOAD_ARTICLES_DATA_SUCCESS,
          payload: data
        })
        return data;
      })
      .catch(error => console.error(error));
  }
}

export const setFilter = (applicationcategory, showAllArticles) => {
  return {
    type: SET_FILTER,
    payload: [applicationcategory, showAllArticles],
  }
}

export const doGlobalSearch = (query, role, onlyUpdateField) => {
  return {
    type: DO_GLOBAL_SEARCH,
    payload: {
      query, role, onlyUpdateField
    },
  }
}

export const loadDocuments = function() {
  return (dispatch) => {
    return client.get().query({
      query: gql`
        query IndexQuery {
          docs(where: {historyFor: 0}, sort: "name") {
            ${GQL_QUERY_DOCUMENT}
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
      .then(({data}) => {
        dispatch({
          type: LOAD_DOCUMENTS_SUCCESS,
          payload: data
        })
      });
  }
}

export const deleteArticle = function(article) {
  return (dispatch) => {
    return client.get().mutate({
      mutation: gql`
        mutation MutationQuery($input: deleteArticleInput!) {
          deleteArticle(input : $input) {
            article {id}
          }
        }`,
        variables: {
          input: {
            where: {id: article.id},
          }
        }
    });
  }
}
