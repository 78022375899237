import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { capitalize } from 'lodash'

import { AgGridReact } from 'ag-grid-react';

import { hasPermission } from '../../../lib/auth/permission';
import { Document } from '../../../types';
import { DocumentTypes } from '../../../lib/content/statics';
import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';

export interface Props {
  entries: Document[]
  onClick: (doc: Document) => void
  showVisibleTo: boolean
}

interface States {
  columnDefs: any[]
}

class DocumentsGrid extends PureComponent<Props, States> {
  gridApi: any

  constructor(props: any) {
    super(props);

    this.state = {
      columnDefs: [],
    };
  }

  getColumnDef() {
    const { showVisibleTo } = this.props;
    return [{
        headerName: "Filename", 
        field: "name", 
        resizable: true,    
        filter: true
      }, {
        headerName: "Type", 
        field: "type", 
        resizable: true,    
        filter: true,
        valueFormatter: (params: ValueFormatterParams) => DocumentTypes[params.value]
      }, {
        headerName: "Language", 
        field: "language", 
        resizable: true,    
        filter: true,
        valueFormatter: (params: ValueFormatterParams) => capitalize(params.value)
      }, {
        headerName: "Product", 
        field: "adinoproduct.name", 
        resizable: true,    
        filter: true,
      }, {
        headerName: "Revision date",
        field: "revisionDate",
        resizable: true,    
        filter: true,
        valueFormatter: (params: ValueFormatterParams) => params.value && moment(params.value).format('YYYY-MM-DD')
      }, {
        headerName: "Revision",
        field: "revisionVersion",
        resizable: true,    
        filter: true,
      }, {
        headerName: "Visible for", 
        field: "requiredRole",
        resizable: true,
        filter: true,
        valueFormatter: (params: ValueFormatterParams) => capitalize(params.value),
        hide: !showVisibleTo
      }];
  }

  onGridReady(grid: any) {
    this.gridApi = grid;
    this.gridApi.sizeColumnsToFit();
  }

  render() {
    return (
      <div 
        className="ag-theme-balham"
        style={{ 
        height: '500px' }} 
      >
        <AgGridReact
          onRowClicked={ ({data}) => this.props.onClick(data) }
          onGridReady={ params => this.onGridReady(params.api) }
          floatingFilter={true}
          columnDefs={this.getColumnDef()}
          rowData={this.props.entries}>
        </AgGridReact>
      </div>
    );
  }
}

export default DocumentsGrid;
